import React, { useCallback , useRef} from "react";
import PropTypes from "prop-types";

import Caliber from "./Caliber/Caliber";
import AttachmentIcon from "./Caliber/assets/attachment-icon.svg";

import "./detailed-report.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import {
  ASSESSMENT_TYPE_FAST_TRACK,
  ASSESSMENT_TYPE_BASIC,
} from "../../../common/constants/assessmentStatuses";

import ContentTable from "./ContentTable";

export const DetailedReport = ({
  standardCategories,
  assessmentType,
  isShowAuditResult,
}) => {

  const mainContentRef = useRef(null);

  // const getAttachmentsTotal = useCallback(
  //   ({ calibers = [] }) =>
  //     calibers
  //       .map(
  //         ({ attachments = [] }) =>
  //           attachments.filter((attachment) => attachment.file_name != "")
  //             .length
  //       )
  //       .reduce((a, b) => a + b, 0),
  //   []
  // );
  return (
    <div className="continer" ref={mainContentRef}>
    <section className="continer-child detailed-report">
      {standardCategories &&
        standardCategories.map((standardCategory, standardCategoryIndex) => (
          <div key={standardCategory.id} className="standard-category" id={`standard-category-${standardCategoryIndex}`}>
            <header className="standard-category-header">
              <Text
                textType="h7"
                isBold
                className="standard-category-header__name"
              >
                {standardCategory.name}
              </Text>
            </header>

            <main className="standard-category-main">
              {standardCategory &&
                standardCategory.caliber_categories.map(
                  (caliberCategory, caliberCategoryIndex) => (
                    <div key={caliberCategory.id} className="caliber-category" id={`caliber-category-${standardCategoryIndex}-${caliberCategoryIndex}`}>
                      <div className="caliber-category-header">
                        <Text
                          textType="h7"
                          isBold
                          className="caliber-category-header__name"
                        >
                          نوع المعيار: {caliberCategory.name} 
                        </Text>
                        {/* {assessmentType === ASSESSMENT_TYPE_FAST_TRACK && (
                          <div>
                            <span>{getAttachmentsTotal(caliberCategory)}</span>
                            <img
                              className="caliber-category-header__attachment-icon"
                              src={AttachmentIcon}
                            />
                          </div>
                        )} */}
                      </div>
                      <Text
                        textType="p4"
                        isBold
                        className="caliber-category__title"
                      >
                        شرح المعيار:
                      </Text>
                      <Text textType="p4" className="caliber-category__desc">
                        {caliberCategory.description}
                      </Text>

                      {assessmentType === ASSESSMENT_TYPE_BASIC &&
                      isShowAuditResult
                        ? caliberCategory &&
                          caliberCategory.labels.map((label, caliberIndex) => (
                            <Caliber
                              assessmentType={assessmentType}
                              key={label.calibers[0].id}
                              caliberCategoryIndex={caliberCategoryIndex}
                              caliberIndex={caliberIndex}
                              standardCategoryIndex={standardCategoryIndex}
                              caliber={label.calibers[0]}
                              isShowAuditResult={isShowAuditResult}
                            />
                          ))
                        : caliberCategory.calibers.map(
                            (caliber, caliberIndex) => (
                              <Caliber
                                assessmentType={assessmentType}
                                key={caliber.id}
                                caliberCategoryIndex={caliberCategoryIndex}
                                caliberIndex={caliberIndex}
                                standardCategoryIndex={standardCategoryIndex}
                                caliber={caliber}
                                isShowAuditResult={isShowAuditResult}
                              />
                            )
                          )}
                    </div>
                  )
                )}
            </main>
          </div>
        ))}
    </section>

    <div className="continer-child content-table">
      {standardCategories &&
       <ContentTable  standardCategories={standardCategories} mainContentRef={mainContentRef}/>
       }
         
       </div>
    </div>
  );
};

DetailedReport.propTypes = {
  standardCategories: PropTypes.array.isRequired,
  assessmentType: PropTypes.string.isRequired,
  isShowAuditResult: PropTypes.bool.isRequired,
};

export default DetailedReport;
