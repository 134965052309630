import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";
import "./headerNotification.scss";
import { format } from "date-fns";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as NotificationActions from "../../../actions/notificationActions";
import Button from "../../../components/Button/Button";
import Loader from "../../../components/Loader/Loader";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";

const styles = (theme) => ({
  menuItemRoot: {
    width: "100%",
    justifyContent: "right",
    minHeight: "90px",
  },
});
const HeaderNotification = (props) => {
  const {
    notificationsList,
    onFetchToolBarNotifications,
    notificationsListFetching,
    establishmentProfile,
    history,
    classes,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const UPDATE_INTERVAL = 60000;

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth() + 1;

  const limit = 5;
  const formatedTime = (time) => {
    const notificationDay = time.getDate();
    const notificationMonth = time.getMonth() + 1;
    const formatedDate = format(time, "dd-MM-yyyy");
    const daysDifference = currentDay - notificationDay;
    if (notificationMonth === currentMonth) {
      switch (daysDifference) {
        case 0:
          return "اليوم";
        case 1:
          return " قبل يوم";
        case 2:
          return " قبل يومين";
        case 3:
          return " قبل 3 أيام";
        case 4:
          return " قبل 4 أيام";
        case 5:
          return " قبل 5 أيام";
        default:
          return formatedDate;
      }
    } else return formatedDate;
  };

  useEffect(() => {
    establishmentProfile.id &&
      onFetchToolBarNotifications(establishmentProfile.id, 0, limit);
  }, [establishmentProfile]);

  useEffect(() => {
    const interval = setInterval(() => {
      onFetchToolBarNotifications(establishmentProfile.id, 0, limit);
    }, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const redirect = () => {
    history.push("/notifications");
  };
  const unReadMessages = notificationsList.filter(
    (message) => !message.is_read
  );

  return (
    <div>
      <div onClick={handleClick} style={{ width: "21px", paddingLeft: "3rem" }}>
        {!notificationsListFetching && unReadMessages.length > 0 ? (
          <img src={"img/ic-notification.svg"} style={{ cursor: "pointer" }} />
        ) : (
          <img
            src={"img/ic-no-notification.svg"}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ marginTop: "10px" }}
        MenuListProps={{
          sx: {
            width: 466,
            "& .MuiMenuItem-root": {
              whiteSpace: "normal",
            },
          },
        }}
      >
        <div className="header-notification-text">
          <div className="header-notification-text__header">
            <Text
              Tag="div"
              isBold
              textType="h7"
              className="header-notification-text__header-title"
            >
              التنبيهات
            </Text>
            <div className="header-notification-text__header-button">
              <Button
                secondary
                text={"قراءة الكل"}
                onClick={() => redirect()}
              />
            </div>
          </div>
          {notificationsListFetching && (
            <div className="loader-wrapper">
              <Loader />
            </div>
          )}
          {!notificationsListFetching &&
            (notificationsList.length > 0 ? (
              notificationsList.map((notification, id) => (
                <div key={id} className="header-notification-menu-item-wrapper">
                  <MenuItem
                    onClick={handleClose}
                    classes={{
                      root: classes.menuItemRoot,
                    }}
                  >
                    <div className="header-notification-text__description-wrapper">
                      <div className="header-notification-text__top">
                        <Text
                          Tag="div"
                          isBold={!notification.is_read}
                          textType="p5"
                          className="header-notification-text__username"
                        >
                          {notification.description}
                        </Text>
                        <Text
                          Tag="div"
                          isBold={!notification.is_read}
                          textType="p6"
                          className="header-notification-text__time "
                        >
                          {formatedTime(new Date(notification.created_at))}
                        </Text>
                      </div>

                      <Text
                        Tag="div"
                        isBold={!notification.is_read}
                        textType="p6"
                        className="header-notification-text__establishment-info "
                      >
                        {notification.establishment_information}
                      </Text>
                    </div>
                  </MenuItem>
                </div>
              ))
            ) : (
              <Text
                Tag="div"
                textType="p4"
                className="header-notification-text__header"
              >
                لا يوجد تنبيهات حاليا
              </Text>
            ))}
        </div>
      </Menu>
    </div>
  );
};

HeaderNotification.propTypes = {
  establishmentInfo: PropTypes.string,
  description: PropTypes.string,
  time: PropTypes.instanceOf(Date),
  notificationsList: PropTypes.array,
  notificationsListFetching: PropTypes.bool,
  establishmentProfile: PropTypes.object.isRequired,
  onFetchToolBarNotifications: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
HeaderNotification.defaultProps = {
  establishmentInfo: "اسم المستخدم",
  description: "اسم المنشأة",
  time: new Date(),
};

const mapStateToProps = (state) => ({
  notificationsList: state.notifications.list,
  notificationsListFetching: state.notifications.listFetching,
  establishmentProfile: state.establishment.establishmentProfile,
});

const mapDispatchToProps = {
  onFetchToolBarNotifications: NotificationActions.fetchToolBarNotifications,
};
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(HeaderNotification);
