import React from "react";

export const CheckIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      fill="#148287"
      d="M7.667 16h-.06a1 1 0 0 1-.715-.366l-3.684-4.515a1 1 0 0 1 1.549-1.264l3 3.672L18.684 3.008 8.222 10a1 1 0 0 1-1.28-.142L3.222 5.95 1.724 7.523a1 1 0 0 1-1.414.034 1 1 0 0 1-.034-1.413L2.5 3.81a1 1 0 0 1 1.448 0l3.863 4.058 11.523-7.7a1 1 0 0 1 1.28.142l1.11 1.167a1 1 0 0 1-.031 1.41L8.36 15.72a1 1 0 0 1-.693.28Z"
      data-name="Rectangle 12"
    />
  </svg>
);
