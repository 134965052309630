import React, { useState } from "react";

import PropTypes from "prop-types";
import "./button.scss";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

const Button = (props) => {
  const {
    secondary,
    primary,
    disabled,
    text,
    onClick,
    icon,
    iconHover,
    style,
    bigButton,
    isBold,
    id,
    customStyle,
    buttonIconCustomStyle,
    smallButton,
  } = props;
  const [onHover, setOnHover] = useState(false);
  const defaultButton = primary || secondary ? false : true;
  return (
    <button
      id={id}
      onMouseOver={() => setOnHover(true)}
      onMouseOut={() => setOnHover(false)}
      disabled={disabled}
      className={`app-button  ${bigButton ? "app-button__big" : ""} ${
        primary ? "app-button__white" : "app-button__orang"
      } ${defaultButton ? "app-button__orange" : ""} ${
        secondary ? "app-button__no-border " : ""
      }${
        disabled
          ? secondary
            ? "app-button__disabled app-button__secondary-disabled "
            : "app-button__disabled"
          : ""
      }${smallButton ? "app-button__small" : ""}  ${customStyle}`}
      style={style}
      onClick={onClick}
    >
      {icon && (
        <img
          src={onHover ? iconHover : icon}
          className={`button-icon ${buttonIconCustomStyle}`}
          alt="button icon"
        />
      )}
      <Text
        textType={bigButton ? "h6" : "h9"}
        Tag="span"
        isBold={isBold}
        className="button-text"
      >
        {text}
      </Text>
    </button>
  );
};
Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  secondary: PropTypes.bool,
  primary: PropTypes.bool,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconHover: PropTypes.string,
  style: PropTypes.object,
  bigButton: PropTypes.bool,
  isBold: PropTypes.bool,
  id: PropTypes.string,
  customStyle: PropTypes.string,
  buttonIconCustomStyle: PropTypes.string,
  smallButton: PropTypes.bool,
};
Button.defaultProps = {
  disabled: false,
  secondary: false,
  bigButton: false,
  isBold: true,
  customStyle: "",
  primary: false,
  smallButton: false,
};

export default Button;
