import React from "react";
import PropTypes from "prop-types";

import "./textWithIconAndDescription.css";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
export class TextWithIconAndDescription extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      iconHeight,
      description,
      icon,
      title,
      isBold,
      isGreenTitle,
      textType,
    } = this.props;

    return (
      <div className="text-with-icon-and-description-wrapper">
        <div className="text-with-icon-and-description-content">
          {icon && (
            <img
              src={icon}
              style={{ height: iconHeight, marginLeft: "12px" }}
            />
          )}
          <Text
            Tag="span"
            textType={textType}
            isBold={isBold}
            className={`text-with-icon-and-description-title 
                ${
                  isGreenTitle
                    ? "text-with-icon-and-description-green-title "
                    : ""
                }`}
          >
            {title}
          </Text>
        </div>
        {description && (
          <Text
            Tag="div"
            isBold={isBold}
            textType={textType}
            className={"text-style"}
            className={`${
              isBold
                ? "text-style  text-with-icon-and-description-bold-description "
                : "text-style"
            }`}
          >
            {description}
          </Text>
        )}
      </div>
    );
  }
}
TextWithIconAndDescription.propTypes = {
  iconHeight: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  isBold: PropTypes.bool,
  isGreenTitle: PropTypes.bool,
  textType: PropTypes.string,
};
TextWithIconAndDescription.defaultProps = {
  isBold: false,
  isGreenTitle: false,
  textType: "p3",
};
export default TextWithIconAndDescription;
