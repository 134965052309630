import React from "react";
import { AddonsWithAgreements } from "./self-components/components/addons/addons-with-agreements";
import {
  useActiveStep,
  useIsViewAgreement,
} from "./self-components/self-assessment-form-context/self-assessment-form-context";
import { StepContainer } from "./self-components/components/step-container";

export default function SelfFormChildern({ history }) {
  return (
    <div
      className="flex flex-col w-full  h-full"
      style={{
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
      }}
    >
      <StepContainer history={history} />
    </div>
  );
}
