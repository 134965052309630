import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import "./FastTrackCard.scss";
const FastTrackCard = (props) => {
    const { title, subtitle, content, description, image, width, height } = props;
    return (
        <div>
            <Card
                style={{
                    top: 1000,
                    left: 132,
                    width: 428,
                    height: 551,
                    border: 1,
                    borderStyle: "solid",
                    borderColor: "#00424D33",
                    opacity: 1,
                    borderRadius: 10,
                }}
            >
                <CardContent>
                    <Text
                        Tag="div"
                        textType="h7"
                        isBold
                        className="assessment-title"
                        style={{ textAlign: "center", marginTop: 58 }}
                    >
                        {title}
                    </Text>
                    <Text
                        Tag="div"
                        textType="p5"
                        className="self-assessment-item"
                        style={{ paddingRight: 10, paddingLeft: 10 }}
                    >
                        <>
                            {description?.map((des, index) => {
                                return (
                                    <>
                                        <p
                                            style={{
                                                color: "#0E2A37",
                                                fontSize: 18,
                                                marginTop: index === 0 ? 0 : 26,
                                                marginBottom: 7,
                                            }}
                                        >
                                            {subtitle[index]}
                                        </p>
                                        <p style={{ marginBottom: 0 }}>{description[index]}</p>
                                    </>
                                );
                            })}
                        </>
                    </Text>
                    <Text
                        Tag="div"
                        textType="p5"
                        className="self-assessment-table__item"
                        style={{ paddingRight: 10, paddingLeft: 10 }}
                    >
                        {content?.map((item) => {
                            return (
                                <p>
                                    <span className="icon-triangle">
                                        {" "}
                                        &#9666;{" "}
                                    </span>
                                    {item}
                                </p>
                            );
                        })}
                    </Text>
                    <div className="fast-track-image">
                        <img src={image} className="image" />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};
export default FastTrackCard;