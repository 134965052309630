import React from "react";
import PropTypes from "prop-types";

import {
  STATUS_CHECKOUT,
  STATUS_SUBMITTED,
  STATUS_READY,
  STATUS_IN_PROGRESS,
  STATUS_COMPLETE,
  STATUS_REGISTER,
} from "../../common/constants/trainingStatuses";
import "./establishmentTrainingCourseDetails.css";
import TextWithIconAndDescription from "./TextWithIconAndDescription";
import { convertRawContentToHtml } from "../common/RichTextComponent";
import Button from "../Button/Button";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

export const descArray = [
  {
    title: "محاور  الدورة",
    description: "1.جولة تعريفية بمنصة التقييم الذاتي ",
  },
  {
    title: "أهداف  الدورة",
    description: "1.جولة تعريفية بمنصة التقييم الذاتي ",
  },
  {
    title: "الفئة المستهدفة من  الدورة",
    description: "1.جولة تعريفية بمنصة التقييم الذاتي ",
  },
];

class EstablishmentTrainingCourseDetails extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { trainingStatus, trainingSubject } = this.props;
    const regex = /\n/gi;
    const isStatusRegister = trainingStatus === STATUS_REGISTER;
    const buttonTitle =
      trainingStatus === STATUS_SUBMITTED || trainingStatus === STATUS_CHECKOUT
        ? "أكمل التسجيل"
        : "أرغب بالتسجيل";

    const hidRegisterButton =
      trainingStatus === STATUS_READY ||
      trainingStatus === STATUS_IN_PROGRESS ||
      trainingStatus === STATUS_COMPLETE;

    const description = isStatusRegister
      ? trainingSubject.description
      : trainingSubject.subject_desc;

    const details = isStatusRegister
      ? trainingSubject.details
      : trainingSubject.subject_details;

    const amount = isStatusRegister
      ? trainingSubject.amount
      : trainingSubject.subject_amount;

    const vat = isStatusRegister
      ? trainingSubject.vat
      : trainingSubject.subject_vat;

    const benefits = isStatusRegister
      ? trainingSubject.benefits
      : trainingSubject.subject_benefits;

    const totalHours = isStatusRegister
      ? trainingSubject.total_hours
      : trainingSubject.subject_total_hours;

    return (
      <div className="establishment-training-course-details-wrapper ">
        <div style={{ flex: 2 }}>
          <div className="training-course-details-white-box">
            <Text
              Tag="div"
              textType="p4"
              isBold
              className="training-course-details-title "
            >
              نبذة عن الدورة
            </Text>
            <Text
              Tag="div"
              textType="p5"
              className="training-course-details-text"
            >
              {description ? description : ""}
            </Text>
          </div>
          <Text Tag="span" textType="p5">
            <div
              className="training-course-details-white-box"
              style={{ marginTop: "40px" }}
              dangerouslySetInnerHTML={{
                __html: convertRawContentToHtml(
                  details && details.replace(regex, "<br/>")
                ),
              }}
            />
          </Text>
        </div>
        <div className="training-course-details-bill-wrapper">
          <div className="training-course-details-white-box">
            <TextWithIconAndDescription
              description={`${totalHours} ساعة`}
              icon="img/clock-2.svg"
              iconHeight="25px"
              title="عدد الساعات"
              textType="p5"
            />
            <div className="training-course-details-top-margin ">
              <TextWithIconAndDescription
                description={`${Number(
                  Math.round(amount + amount * vat + "e" + 2) + "e-" + 2
                )} ريال`}
                icon="img/money_bill.svg"
                iconHeight="19px"
                title="سعر الدورة"
                textType="p5"
              />
            </div>
            <div className="training-course-details-top-margin ">
              <TextWithIconAndDescription
                icon="img/certificate.svg"
                iconHeight="27px"
                title={`${benefits}`}
                textType="p5"
              />
            </div>

            {!hidRegisterButton && (
              <div className="light-horizontal-line training-course-details-top-margin"></div>
            )}
            {!hidRegisterButton && (
              <Button
                onClick={() => {
                  this.props.onRegisterClick();
                }}
                text={buttonTitle}
                id="training-details-register-button"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

EstablishmentTrainingCourseDetails.propTypes = {
  trainingStatus: PropTypes.string,
  trainingSubject: PropTypes.object,
};
EstablishmentTrainingCourseDetails.defaultProps = {};

export default EstablishmentTrainingCourseDetails;
