import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as EstablishmentAssessmentActions from "../../../actions/establishmentAssessmentActions";

import {
  ASSESSMENT_TYPE_BASIC,
  ASSESSMENT_TYPES,
  AUDIT_STATUS_UNDERAUDITING,
  getStatusColor,
  getStatusName,
  RE_SUBMIT_STATUS,
  STATUS_ACTIVE,
  STATUS_EMPTY,
  STATUS_ENABLED,
  STATUS_PARTIALLY_FILLED,
  STATUS_PARTIALLY_FILLED_API_VALUE,
  STATUS_SUBMITTED,
} from "../../../common/constants/assessmentStatuses";

import "./self-assessment-list.scss";

import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import Certificate from "../../../components/Certificate/Certificate";
import DropdownAction from "../../../components/DropdownActions/DropdownAction";
import Pagination from "./Pagination";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import {
  ADDITIONAL_ASSESSMENT,
  DETAILEDREPORT,
} from "../../../common/constants/paymentStatuses";
import * as unifiedActions from "../../../actions/unifiedActions";
import Button from "../../../components/Button/Button";
import { STATUS_IS_BLOCKED } from "../../../common/constants/blockedStatus";
import { saveSelectedDuration } from "../../../actions/durationActions";
import { Popover, Typography } from "@mui/material";
import { ReSubmitPopover } from "./ReSubmitPopOver";

export class SelfAssessmentList extends React.Component {
  constructor() {
    super();
    this.state = {
      start: 0,
      limit: 5,
      currentPage: 1,
      isGracePeriodOpen: false,
      isShowBanner: false,
      bannerType: null,
    };

    this.onPageChange = this.onPageChange.bind(this);
    this.getStatusName = this.getStatusName.bind(this);
    this.getStatusColor = this.getStatusColor.bind(this);

    this.showGracePeriodPopUp = this.showGracePeriodPopUp.bind(this);
    this.onGracePeriodActionClick = this.onGracePeriodActionClick.bind(this);
    this.getBannerButtonText = this.getBannerButtonText.bind(this);
    this.getBannerDescription = this.getBannerDescription.bind(this);
    this.getBannerImage = this.getBannerImage.bind(this);
    this.getButtonTextColor = this.getButtonTextColor.bind(this);
    this.onBannerClick = this.onBannerClick.bind(this);
    this.handleNavToAssessment = this.handleNavToAssessment.bind(this);
    this.redirectToEstablishmentAssessmentPage =
      this.redirectToEstablishmentAssessmentPage.bind(this);
    this.redirectToSelfAssessmentResult =
      this.redirectToSelfAssessmentResult.bind(this);
    this.setBanner = this.setBanner.bind(this);
    this.redirectToSelfAssessmentPaymentAdditionalAssessment =
      this.redirectToSelfAssessmentPaymentAdditionalAssessment.bind(this);
  }

  componentDidMount() {
    const { get, start, limit } = this.state;
    const {
      establishmentProfile,
      loadActiveAssessment,
      fetchGracePeriodStatus,
    } = this.props;
    const {
      assessmentId,
      periodId,
      assessmentVersion,
      profileId,
      isAssessmentSubmitted,
      isBasicAssessment,
    } = this.props.location;
    if (establishmentProfile.id) {
      loadActiveAssessment(establishmentProfile.id, { start, limit }).then(
        (activeAssessment) => {
          this.setBanner(activeAssessment);
        }
      );
    }

    if (isAssessmentSubmitted && isBasicAssessment) {
      fetchGracePeriodStatus(
        profileId,
        periodId,
        assessmentId,
        assessmentVersion
      ).then((val) => {
        val && this.showGracePeriodPopUp();
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { start, limit } = this.state;
    const {
      establishmentProfile,
      loadActiveAssessment,
      fetchGracePeriodStatus,
    } = this.props;
    const {
      assessmentId,
      periodId,
      assessmentVersion,
      profileId,
      isAssessmentSubmitted,
      isBasicAssessment,
    } = this.props.location;
    const { start: prevStart } = prevState;

    if (
      prevProps.establishmentProfile.id !==
        this.props.establishmentProfile.id ||
      start !== prevStart
    ) {
      if (establishmentProfile.id) {
        loadActiveAssessment(establishmentProfile.id, { start, limit }).then(
          (activeAssessment) => {
            this.setBanner(activeAssessment);
          }
        );
      }
      if (isAssessmentSubmitted && isBasicAssessment) {
        fetchGracePeriodStatus(
          profileId,
          periodId,
          assessmentId,
          assessmentVersion
        ).then((val) => {
          val && this.showGracePeriodPopUp();
        });
      }
    }
  }

  setBanner(currentActiveAssessment) {
    const { establishmentProfile } = this.props;
    const isNewAssessmentEmpty =
      currentActiveAssessment.assessment_type === ASSESSMENT_TYPE_BASIC &&
      currentActiveAssessment.status === STATUS_ACTIVE &&
      currentActiveAssessment.state === STATUS_ENABLED &&
      currentActiveAssessment.assessment.status === STATUS_EMPTY;

    const isNewAssessmentPartiallyFilled =
      currentActiveAssessment.assessment_type === ASSESSMENT_TYPE_BASIC &&
      currentActiveAssessment.status === STATUS_ACTIVE &&
      currentActiveAssessment.state === STATUS_ENABLED &&
      currentActiveAssessment.assessment.status ===
        STATUS_PARTIALLY_FILLED_API_VALUE;

    const isAssessmentUnderAuditing =
      currentActiveAssessment.assessment_type === ASSESSMENT_TYPE_BASIC &&
      currentActiveAssessment.status === STATUS_ACTIVE &&
      currentActiveAssessment.state === STATUS_SUBMITTED &&
      currentActiveAssessment.audit_status === AUDIT_STATUS_UNDERAUDITING;

    const isAssessmentComplete =
      currentActiveAssessment.assessment_type === ASSESSMENT_TYPE_BASIC &&
      currentActiveAssessment.status === STATUS_ACTIVE &&
      currentActiveAssessment.state === STATUS_SUBMITTED &&
      currentActiveAssessment.audit_status !== AUDIT_STATUS_UNDERAUDITING;

    const isAssessmentReturned =
      currentActiveAssessment.assessment_type === ASSESSMENT_TYPE_BASIC &&
      currentActiveAssessment.status === STATUS_ACTIVE &&
      currentActiveAssessment.state === STATUS_SUBMITTED &&
      currentActiveAssessment.audit_status === RE_SUBMIT_STATUS;

    const isShowBanner =
      establishmentProfile.is_blocked ||
      isNewAssessmentEmpty ||
      isNewAssessmentPartiallyFilled ||
      isAssessmentUnderAuditing ||
      isAssessmentReturned ||
      isAssessmentComplete;

    let bannerType = null;
    if (isAssessmentReturned) {
      bannerType = RE_SUBMIT_STATUS;
    } else if (establishmentProfile.is_blocked) {
      bannerType = STATUS_IS_BLOCKED;
    } else if (isNewAssessmentEmpty) {
      bannerType = STATUS_EMPTY;
    } else if (isNewAssessmentPartiallyFilled) {
      bannerType = STATUS_PARTIALLY_FILLED;
    } else if (isAssessmentUnderAuditing) {
      bannerType = AUDIT_STATUS_UNDERAUDITING;
    } else if (isAssessmentComplete) {
      bannerType = STATUS_SUBMITTED;
    }
    this.setState({ isShowBanner, bannerType });
  }

  showGracePeriodPopUp() {
    const { isGracePeriodOpen } = this.state;
    this.setState({ isGracePeriodOpen: !isGracePeriodOpen });
  }

  onPageChange(selectedItem) {
    const { limit } = this.state;
    const selectedPage = selectedItem - 1;
    this.setState({
      start: selectedPage * limit,
      currentPage: selectedItem,
    });
  }

  getStatusName(item) {
    // if (item.status === 'Active') {
    //   console.log("**********");
    //   console.log(item.state);
    //   console.log(item.assessment_type);
    //   console.log(item.audit_status);
    //   console.log(item.inspection_assessment_status.String);
    //   console.log(item.status);
    //   console.log(item);
    //   console.log("**********");
    // }

    return getStatusName(
      item.state,
      item.assessment_type,
      item.audit_status,
      item.inspection_assessment_status.String,
      item.status
    );
  }

  getStatusColor(item) {
    return getStatusColor(
      item.state,
      item.assessment_type,
      item.audit_status,
      item.inspection_assessment_status.String,
      item.status
    );
  }

  onGracePeriodActionClick() {
    const withoutResult = true;
    const {
      assessmentId,
      periodId,
      assessmentVersion,
      profileId,
      periodAssessmentId,
    } = this.props.location;

    this.props.history.push(
      `/establishmentAssessmentPayment/${periodId}/${assessmentId}/${profileId}/${assessmentVersion}/${withoutResult}/${DETAILEDREPORT}/${periodAssessmentId}`
    );
  }

  redirectToEstablishmentAssessmentPage() {
    const { history } = this.props;
    const { period_assessment_id, assessment_version, assessment_type, id } =
      this.props.activeAssessment;
    const isBasicAssessment = ASSESSMENT_TYPE_BASIC === assessment_type;
    history.push(
      `/establishmentAssessmentInstruction/${id}/${assessment_version}/true/${period_assessment_id}`
    );
  }

  redirectToSelfAssessmentResult() {
    const { history, establishmentProfile } = this.props;
    const { assessment_version, assessment, id } = this.props.activeAssessment;
    this.props.location;
    history.push(
      `/establishmentAssessmentResult/${id}/${assessment.id}/${establishmentProfile.id}/${assessment_version}`
    );
  }

  redirectToSelfAssessmentPaymentAdditionalAssessment() {
    const withoutResult = true;
    const { history, establishmentProfile, saveSelectedDuration } = this.props;
    const { assessment_version, assessment, id, period_assessment_id } =
      this.props.activeAssessment;
    saveSelectedDuration(this.props.activeAssessment);
    history.push(
      `/establishmentAssessmentPayment/${id}/${assessment.id}/${establishmentProfile.id}/${assessment_version}/${withoutResult}/${ADDITIONAL_ASSESSMENT}/${period_assessment_id}`
    );
  }

  getBannerButtonText() {
    const { bannerType } = this.state;
    switch (bannerType) {
      case STATUS_IS_BLOCKED:
      case STATUS_EMPTY:
        return "بدء التقييم";
      case AUDIT_STATUS_UNDERAUDITING:
        return "النتيجة المبدئية";
      case STATUS_PARTIALLY_FILLED:
        return "اكمل التقييم";
      case STATUS_SUBMITTED:
        return "الحصول على النتائج";
      case RE_SUBMIT_STATUS:
        return "مراجعة النموذج";
    }
  }

  getBannerDescription(durationStartDate = null, durationEndDate = null) {
    const { bannerType } = this.state;
    switch (bannerType) {
      case STATUS_IS_BLOCKED:
        return "المنشأة موقوفة، و لإعادة تفعيلها نرجو منك البدء بالتقييم";
      case STATUS_EMPTY:
        return `بدأت الفترة التقييمية الجديده من تاريخ ${durationStartDate} إلى تاريخ ${durationEndDate} سارع بالتقييم قبل انتهاء الفترة`;
      case AUDIT_STATUS_UNDERAUDITING:
        return "نشكرك على المسارعة في التقييم، طلبك قيد التنفيذ، بامكانك الاطلاع على النتيجة المبدئية وستحصل على النتائج النهائية في حال انتهاء المدققين من عملية التدقيق";
      case STATUS_PARTIALLY_FILLED:
        return "نرجو منك إكمال التقييم";
      case STATUS_SUBMITTED:
        return "ظهرت النتيجة النهائية من قبل المدققين، بامكانك الاطلاع عليها";
      case RE_SUBMIT_STATUS: {
        const expectedEndDate = moment().add("d", 5).format("YYYY/MM/DD");
        return `تم إرجاع طلبك للتعديل، يرجى مراجعة النموذج وتعديل الملاحظات التي تم ذكرها من قبل فريق التدقيق، علما أن صلاحية التعديل تنتهي في ${expectedEndDate}.`;
      }
    }
  }

  getBannerImage() {
    const { bannerType } = this.state;
    switch (bannerType) {
      case STATUS_IS_BLOCKED:
        return "img/red_circle.svg";
      case STATUS_EMPTY:
        return "img/orange_circle.svg";
      case AUDIT_STATUS_UNDERAUDITING:
        return "img/blue_circle.svg";
      case STATUS_PARTIALLY_FILLED:
        return "img/brown_circle.svg";
      case STATUS_SUBMITTED:
        return "img/green_circle.svg";
      case RE_SUBMIT_STATUS:
        return "img/blue_circle.svg";
    }
  }

  getButtonTextColor() {
    const { bannerType } = this.state;
    switch (bannerType) {
      case STATUS_IS_BLOCKED:
        return "#DA3434";
      case STATUS_EMPTY:
        return "#F39525";
      case AUDIT_STATUS_UNDERAUDITING:
        return "#3087EB";
      case STATUS_PARTIALLY_FILLED:
        return "#A7904C";
      case STATUS_SUBMITTED:
        return "#43861B";
      case RE_SUBMIT_STATUS:
        return "#3087EB";
    }
  }

  onBannerClick() {
    const { bannerType } = this.state;
    const { activeAssessment } = this.props;
    switch (bannerType) {
      case STATUS_IS_BLOCKED:
      case STATUS_PARTIALLY_FILLED:
      case STATUS_EMPTY:
        if (activeAssessment.is_free_assessment)
          return this.redirectToEstablishmentAssessmentPage();
        else return this.redirectToSelfAssessmentPaymentAdditionalAssessment();
      case AUDIT_STATUS_UNDERAUDITING:
      case STATUS_SUBMITTED:
        return this.redirectToSelfAssessmentResult();
      case RE_SUBMIT_STATUS: {
        this.handleNavToAssessment(activeAssessment);
        return;
      }
    }
  }

  handleNavToAssessment(assessment) {
    const isBasicAssessment =
      assessment.assessment_type === ASSESSMENT_TYPE_BASIC;
    const { id, assessment_version, period_assessment_id } = assessment;
    this.props.history.push({
      pathname: `/establishmentAssessment/${id}/${assessment_version}/${isBasicAssessment}/${period_assessment_id}`,
      state: { ...assessment, isReturned: true },
    });
  }

  render() {
    const { limit, currentPage, isGracePeriodOpen, isShowBanner, bannerType } =
      this.state;
    const {
      activeAssessment,
      activeAssessments,
      establishmentProfile,
      activeAssessmentsTotal,
      isCertificateVisible,
    } = this.props;
    return (
      <React.Fragment>
        <div className="doNotPrint">
          <PageWrapper>
            {/* <PopUp
              isOpen={isGracePeriodOpen}
              title="اضافة مهلة تصحيحية"
              body="تتيح لك هذة الخدمة الحصول على مهلة تصحيحية من الزيارات التفتيشية التابعة لوزارة الموارد البشرية والتنمية الاجتماعية لمدة شهرين."
              actionButtonText="إضافة مهلة تصحيحية"
              cancelButtonText="إلغاء"
              onClickAction={this.onGracePeriodActionClick}
              onClickCancel={this.showGracePeriodPopUp}
              toggleModal={this.showGracePeriodPopUp}
            /> */}
            <div className="self-assessment-list">
              <div className="self-assessment-list-header">
                <Text
                  Tag="div"
                  isBold
                  textType="h5"
                  className="self-assessment-list-header__text"
                >
                  التقييمات السابقة
                </Text>
              </div>
              <div className="self-assessment-list-content table-x-axis-scroll">
                <div className="self-assessment-table  ">
                  <div className="self-assessment-table__header">
                    <div className="self-assessment-table__row">
                      <Text
                        isBold
                        textType="h9"
                        className="self-assessment-table__item self-assessment-table__item--name"
                      >
                        اسم الفترة التقييمية
                      </Text>
                      <Text
                        isBold
                        textType="h9"
                        className="self-assessment-table__item self-assessment-table__item--type"
                      >
                        نوع الفترة
                      </Text>
                      <Text
                        isBold
                        textType="h9"
                        className="self-assessment-table__item  self-assessment-table__item--center self-assessment-table__item--release"
                      >
                        النسخة
                      </Text>
                      <Text
                        isBold
                        textType="h9"
                        className="self-assessment-table__item self-assessment-table__item--date"
                      >
                        تاريخ بداية الفترة
                      </Text>
                      <Text
                        isBold
                        textType="h9"
                        className="self-assessment-table__item self-assessment-table__item--date"
                      >
                        تاريخ نهاية الفترة
                      </Text>
                      <Text
                        isBold
                        textType="h9"
                        className="self-assessment-table__item  self-assessment-table__item--center self-assessment-table__item--case"
                      >
                        الحالة
                      </Text>
                      <div className="self-assessment-table__item self-assessment-table__item--empty self-assessment-table__item--action" />
                    </div>
                  </div>
                  <div className="self-assessment-table__body">
                    {activeAssessments.map((item, index) => (
                      <div
                        className="self-assessment-table__row"
                        key={`${item.id}-${index}`}
                      >
                        <Text
                          textType="p5"
                          className="self-assessment-table__item"
                        >
                          {item.name}
                        </Text>
                        <Text
                          textType="p5"
                          className="self-assessment-table__item"
                        >
                          {ASSESSMENT_TYPES[item.assessment_type]}
                        </Text>
                        <Text
                          textType="p5"
                          className="self-assessment-table__item self-assessment-table__item--center"
                        >
                          {item.assessment_version}
                        </Text>
                        <Text
                          textType="p5"
                          className="self-assessment-table__item"
                        >
                          {moment(item.start_date.String).format("YYYY/MM/DD")}
                        </Text>
                        <Text
                          textType="p5"
                          className="self-assessment-table__item"
                        >
                          {moment(item.end_date.String).format("YYYY/MM/DD")}
                        </Text>
                        <div className="self-assessment-table__item self-assessment-table__item--center">
                          <Text
                            textType="p5"
                            className={`self-assessment-table__item-status self-assessment-table__item-status--${this.getStatusColor(
                              item
                            )}`}
                            style={{ margin: 0 }}
                          >
                            {this.getStatusName(item)}
                          </Text>

                          {/* <ReSubmitPopover
                              onClick={() => this.handleNavToAssessment(item)}
                              id={item.id}
                              trigger={
                                <Text
                                  textType="p5"
                                  className={`self-assessment-table__item-status self-assessment-table__item-status--${this.getStatusColor(
                                    item
                                  )}`}
                                  style={{ margin: 0 }}
                                >
                                  {this.getStatusName(item)}
                                </Text>
                              }
                            /> */}
                        </div>
                        <div className="self-assessment-table__item">
                          <DropdownAction
                            durationIndex={index}
                            duration={item}
                            profileId={establishmentProfile.id}
                            ignoreActions={[
                              1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15,
                              16, 17,
                            ]}
                            goToReturnedAssessment={() =>
                              this.handleNavToAssessment(item)
                            }
                            isReturnedAssessment={
                              item.audit_status === RE_SUBMIT_STATUS
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div id="react-paginate">
                <Pagination
                  itemsCount={
                    activeAssessmentsTotal ? activeAssessmentsTotal : 0
                  }
                  pagesLimit={limit}
                  currentPage={currentPage}
                  onPageChange={this.onPageChange}
                />
              </div>
            </div>
          </PageWrapper>
        </div>
        {isCertificateVisible && <Certificate />}
      </React.Fragment>
    );
  }
}

SelfAssessmentList.defaultProps = {};

SelfAssessmentList.propTypes = {
  establishmentProfile: PropTypes.object.isRequired,
  activeAssessments: PropTypes.array.isRequired,
  activeAssessmentsTotal: PropTypes.number,
  loadActiveAssessment: PropTypes.func.isRequired,
  resetAssessment: PropTypes.func.isRequired,
  isCertificateVisible: PropTypes.bool.isRequired,
  fetchGracePeriodStatus: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  activeAssessment: PropTypes.object,
  saveSelectedDuration: PropTypes.func,
};

const mapStateToProps = (state) => ({
  activeAssessments: state.establishment.establishmentActiveAssessments,
  activeAssessmentsTotal:
    state.establishment.establishmentActiveAssessmentsTotal,
  establishmentProfile: state.establishment.establishmentProfile,
  isCertificateVisible: state.certificate.isVisible,
  activeAssessment: state.establishment.activeAssessment,
});

const mapDispatchToProps = (dispatch) => ({
  loadActiveAssessment: (id, params) =>
    dispatch(
      EstablishmentAssessmentActions.loadEstablishmentPreviousAssessments(
        id,
        params
      )
    ),
  resetAssessment: () =>
    dispatch(
      EstablishmentAssessmentActions.resetEstablishmentActiveAssessments()
    ),
  fetchGracePeriodStatus: (
    establishmentId,
    periodId,
    assessmentId,
    assessmentVersion
  ) =>
    dispatch(
      unifiedActions.fetchGracePeriodStatus(
        establishmentId,
        periodId,
        assessmentId,
        assessmentVersion
      )
    ),
  saveSelectedDuration: (duration) => dispatch(saveSelectedDuration(duration)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelfAssessmentList);
