import React from "react";
import PropTypes from "prop-types";
import CommentsIcon from "./assets/comments.svg";

import "./caliber-comments.scss";
import { Text } from "../../../../../../shared-components/my-scope/atoms/text/index";

const CaliberAuditComments = (props) => {
  const { comments } = props;
  return (
    <div className="caliber-comments">
      <header className="caliber-comments-header">
        <img className="caliber-comments-header__icon" src={CommentsIcon} />
        <Text isBold textType="p5" className="caliber-comments-header__title">
          الملاحظات بعد التدقيق
        </Text>
      </header>
      <main className="caliber-comments-main">
        {comments.map((comment, index) => (
          <Text
            key={index}
            Tag="div"
            textType="p6"
            className="caliber-comments-main__comment"
          >
            - {comment.audit_user_comment}
          </Text>
        ))}
      </main>
    </div>
  );
};
CaliberAuditComments.propTypes = {
  comments: PropTypes.array.isRequired,
};

export default CaliberAuditComments;
