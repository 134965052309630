import React from "react";
import { SelfAssessmentContext } from "../self-assessment-form-context/index";
import { StepperSide } from "../components/stepper-side/index";
import "./self-assessment-steps.css";

export const SelfAssesmentStepsLayout = ({ children, params }) => {
  return (
    <SelfAssessmentContext params={params}>
      <div
        className="flex bg-white overflow-y-hidden "
        style={{
          borderRadius: "30px",
          height: "865px",
        }}
        id="assessment-inner-context-wrapper"
      >
        <div
          className="flex max-h-[85vh] flex-col  h-full w-full overflow-y-auto "
          style={{
            paddingTop: "62px ",
            paddingBottom: "32px",
            height: "865px",
          }}
        >
          <div
            className="flex  flex-col  h-full w-full no-scrollbar overflow "
            style={{
              // padding: "0 62px 30px 72px",
              paddingBottom: "30px",
              minHeight: "865px",
            }}
          >
            <div
              className="flex-1 w-full h-full "
              style={{ minHeight: "865px" }}
            >
              {children}
            </div>
          </div>
        </div>
        <div
          className=" overflow-y-auto  min-w-fit no-scrollbar"
          style={{ minHeight: "865px" }}
        >
          <StepperSide />
        </div>
      </div>
    </SelfAssessmentContext>
  );
};
