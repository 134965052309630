import React from "react";

import PropTypes from "prop-types";
import "./labeledCheckbox.scss";
import Checkbox from "../Checkbox/Checkbox";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

class LabeledCheckbox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      checked,
      disabled,
      label,
      onChange,
      normalFont,
      isBold,
      textType,
      style,
      id,
      errorText,
    } = this.props;
    return (
      <div>
        <label
          className={`ua-checkbox-label ${normalFont ? "ua-checkbox-label__normal" : null
            }`}
          style={style}
        >
          <Checkbox
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            id={id}
          />
          <Text Tag="span" textType={textType} isBold={isBold}>
            {label}
          </Text>
        </label>
        {errorText && (
          <Text
            className="ua-checkbox-label__error-hint"
            Tag="span"
            textType="p5"
          >
            * {errorText}
          </Text>
        )}
      </div>
    );
  }
}
LabeledCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  data: PropTypes.any,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  normalFont: PropTypes.bool,
  isBold: PropTypes.bool,
  textType: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
  errorText: PropTypes.string,
};
LabeledCheckbox.defaultProps = {
  data: null,
  disabled: false,
  normalFont: false,
  isBold: false,
  textType: "p3",
  errorText: null,
};

export default LabeledCheckbox;
