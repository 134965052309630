import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../Button/Button";
import PropTypes from "prop-types";
import "./popUp.scss";
import { Fragment } from "react";
import { Text } from "../../../../shared-components/my-scope/atoms/text";

class PopUp extends Component {
  render() {
    const {
      onClickAction,
      title,
      body,
      onClickCancel,
      actionButtonText,
      cancelButtonText,
      isOpen,
      toggleModal,
      buttons,
      icon,
      isCustomBody,
      children,
      isGreenHeader,
      contentClass,
      exit,
      closeIcon,
      actionButtonDisabled,
      asChildren,
      childsStyle,
    } = this.props;

    const popupButton = () => {
      return buttons.map((button) => (
        <div
          onClick={() => button.onClick()}
          className={`pop-up-item ${
            button.disabled ? "pop-up-item__disabled" : ""
          }`}
          key={button.title}
          disabled={button.disabled}
        >
          <div
            className={`pop-up-item-title  ${
              button.disabled ? "pop-up-item-title-disabled" : ""
            }`}
          >
            {button.title}
          </div>
          <div
            className={`pop-up-item-text ${
              button.disabled ? "pop-up-item-text-disabled" : ""
            }`}
          >
            {button.subtitle}
          </div>
        </div>
      ));
    };
    return (
      <Modal
        isOpen={isOpen}
        className={"pop-up"}
        toggle={toggleModal}
        backdropClassName="pop-up__backdrop"
        contentClassName={`pop-up__content ${contentClass}`}
      >
        <ModalHeader
          className={`pop-up__header ${
            isGreenHeader ? "pop-up__header-green" : ""
          }`}
          toggle={toggleModal}
        >
          <div className="pop-up__header-row">
            {icon && (
              <div className="pop-up__header-icon">
                <img src={icon} alt="alert icon" />
              </div>
            )}

            <Text
              Tag="div"
              textType="h8"
              isBold
              className="pop-up__header-title"
            >
              {title}
            </Text>

            {exit &&
              (closeIcon ? (
                <div
                  style={{ marginRight: 330, cursor: "pointer" }}
                  onClick={onClickCancel}
                >
                  {closeIcon}
                </div>
              ) : (
                <div className="pop-up__header-exit">
                  <span
                    className="icon"
                    aria-hidden="true"
                    onClick={onClickCancel}
                  >
                    ×
                  </span>
                </div>
              ))}
          </div>
        </ModalHeader>
        <ModalBody>
          {buttons.length > 0 ? (
            <div className="pop-up-item-wrapper">{popupButton()}</div>
          ) : (
            (actionButtonText || cancelButtonText || asChildren) && (
              <Fragment>
                {isCustomBody ? (
                  <div className="pop-up-body-wrapper" style={childsStyle}>
                    {children}
                  </div>
                ) : (
                  <div className="pop-up-body-wrapper">{body}</div>
                )}

                {actionButtonText || cancelButtonText ? (
                  <div className="pop-up-buttons-wrapper">
                    {actionButtonText && (
                      <Button
                        onClick={() => onClickAction()}
                        text={actionButtonText}
                        customStyle={"app-button__medium"}
                        disabled={actionButtonDisabled}
                      />
                    )}

                    {cancelButtonText && (
                      <div className="pop-up-buttons-wrapper__second-button">
                        <Button
                          secondary
                          text={cancelButtonText}
                          onClick={() => onClickCancel()}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </Fragment>
            )
          )}
        </ModalBody>
      </Modal>
    );
  }
}
PopUp.propTypes = {
  onClickAction: PropTypes.func,
  onClickCancel: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  actionButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  buttons: PropTypes.array,
  icon: PropTypes.string,
  isGreenHeader: PropTypes.bool,
  contentClass: PropTypes.string,
  closeIcon: PropTypes.elementType,
};
PopUp.defaultProps = {
  buttons: [],
  actionButtonText: "",
  cancelButtonText: "",
  icon: null,
  isGreenHeader: false,
  contentClass: "",
};
export default PopUp;
