import * as types from "./actionTypes";
import {
  CREATE_TRAINING,
  CREATE_TRAINING_FAILURE,
  CREATE_TRAINING_SUCCESS,
  DELETE_TRAINING,
  DELETE_TRAINING_FAILURE,
  DELETE_TRAINING_SUCCESS,
  FETCH_TRAINING,
  FETCH_TRAINING_FAILURE,
  FETCH_TRAINING_SUCCESS,
  FETCH_TRAININGS,
  FETCH_TRAININGS_FAILURE,
  FETCH_TRAININGS_SUCCESS,
  UPDATE_TRAINING,
  UPDATE_TRAINING_FAILURE,
  UPDATE_TRAINING_SUCCESS,
} from "./actionTypes";
import { ajaxCallError, beginAjaxCall, endAjaxCall } from "./ajaxStatusActions";
import request, { parseError } from "../common/request";
import qs from "query-string";

/**
 * Delete training failure action
 * @param {Object} error
 * @returns {{ type: String, payload: { error } }}
 */
export const deleteTrainingFailure = (error) => ({
  type: DELETE_TRAINING_FAILURE,
  payload: { error },
});

/**
 * Delete training success action
 * @returns {{ type: String }}
 */
export const deleteTrainingSuccess = () => ({
  type: DELETE_TRAINING_SUCCESS,
});

/**
 * Delete training action
 * @param {String} trainingId
 */
export const deleteTraining = (trainingId) => (dispatch) => {
  dispatch({
    type: DELETE_TRAINING,
    payload: { trainingId },
  });

  dispatch(beginAjaxCall());
  return request(`/trainings/${trainingId}`, "DELETE")
    .then((response) => {
      if (!response.ok) {
        return parseError(response);
      }
      return response;
    })
    .then(() => {
      dispatch(deleteTrainingSuccess());
      dispatch(endAjaxCall());
    })
    .catch((error) => {
      dispatch(deleteTrainingFailure(error));
      dispatch(ajaxCallError(error));
      dispatch(endAjaxCall());
    });
};

/**
 * Create training failure action
 * @param {Object} error
 * @returns {{ type: String, payload: { error } }}
 */
export const createTrainingFailure = (error) => ({
  type: CREATE_TRAINING_FAILURE,
  payload: { error },
});

/**
 * Create training success action
 * @param {Object} training list of trainings
 * @returns {{ type: String, payload: { training } }}
 */
export const createTrainingSuccess = (training) => ({
  type: CREATE_TRAINING_SUCCESS,
  payload: { training },
});

/**
 * Create training action
 * @param {Object} training
 * @param uuid establishment_id
 */
export const createTraining =
  (establishment_id, training, trainingData) => (dispatch) => {
    dispatch({
      type: CREATE_TRAINING,
      payload: { training, trainingData, establishment_id },
    });
    const url = `/v1/establishments/${establishment_id}/trainings`;

    dispatch(beginAjaxCall());
    return request(url, "POST", { ...training })
      .then((response) => {
        if (response.status == 424) {
          throw response.status;
        } else if (!response.ok) {
          return response.json().then((text) => {
            throw text.title;
          });
        }
        return response;
      })
      .then((res) => res.json())
      .then(
        (res) => {
          dispatch(endAjaxCall());
          dispatch(createTrainingSuccess(res.training));
          return res;
        },
        (error) => {
          dispatch(ajaxCallError(error));
          dispatch(endAjaxCall());
          dispatch(createTrainingFailure(error));
          throw error;
        }
      );
  };

/**
 * Fetch training failure action
 * @param {Object} error
 * @returns {{ type: String, payload: { error } }}
 */
export const fetchTrainingFailure = (error) => ({
  type: FETCH_TRAINING_FAILURE,
  payload: { error },
});

/**
 * Fetch training success action
 * @param {Object} training
 * @returns {{ type: String, payload: { training } }}
 */
export const fetchTrainingSuccess = (training) => ({
  type: FETCH_TRAINING_SUCCESS,
  payload: { training },
});

/**
 * Fetch training action
 * @param {String} id training id
 */
export const fetchTraining = (establishment_id, id) => (dispatch) => {
  dispatch({
    type: FETCH_TRAINING,
    payload: { establishment_id, id },
  });
  dispatch(beginAjaxCall());
  request(`/v1/establishments/${establishment_id}/trainings/${id}`)
    .then((response) => {
      if (!response.ok) {
        return parseError(response);
      }
      return response.json();
    })
    .then(
      (res) => {
        dispatch(fetchTrainingSuccess(res.trainings));
        dispatch(endAjaxCall());
      },
      (error) => {
        dispatch(fetchTrainingFailure(error));
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
      }
    );
};

/**
 * Fetch trainings failure action
 * @param {Object} error
 * @returns {{ type: String, payload: { error } }}
 */
export const fetchTrainingsFailure = (error) => ({
  type: FETCH_TRAININGS_FAILURE,
  payload: { error },
});

/**
 * Fetch trainings success action
 * @param {Array} trainings list of trainings
 * @returns {{ type: String, payload: { trainings } }}
 */
export const fetchTrainingsSuccess = (trainings, total) => ({
  type: FETCH_TRAININGS_SUCCESS,
  payload: { trainings, total },
});

/**
 * Fetch trainings action
 * @param {Number} start
 * @param {Number} limit
 * @param {String} searchTerm
 */
export const fetchTrainings =
  (establishmentId, start = 0, limit = 60) =>
  (dispatch) => {
    dispatch({
      type: FETCH_TRAININGS,
      payload: { start, limit },
    });
    // loading overlay is causing search field to loose focus
    // dispatch(beginAjaxCall());
    const query = { start, limit };

    const url = `/v1/establishments/${establishmentId}/trainings`;

    request(`${url}?${qs.stringify(query)}`)
      .then((response) => {
        if (!response.ok) {
          return parseError(response);
        }
        return response;
      })
      .then((res) => res.json())
      .then(
        (json) => {
          dispatch(fetchTrainingsSuccess(json.trainings || [], json.count));
        },
        (error) => {
          dispatch(fetchTrainingsFailure(error));
        }
      );
  };

/**
 * Update training failure action
 * @param {Object} error
 * @returns {{ type: String, payload: { error } }}
 */
export const updateTrainingFailure = (error) => ({
  type: UPDATE_TRAINING_FAILURE,
  payload: { error },
});

/**
 * Update training success action
 * @param {Object} training list of trainings
 * @returns {{ type: String, payload: { training } }}
 */
export const updateTrainingSuccess = (training) => ({
  type: UPDATE_TRAINING_SUCCESS,
  payload: { training },
});

/**
 * Update training action
 * @param {Object} training
 * @param {String} trainingId
 */
export const updateTraining =
  (establishment_id, training, id = training.id) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_TRAINING,
      payload: { training, id, establishment_id },
    });

    training.id = id;
    dispatch(beginAjaxCall());
    request(`/v1/establishments/${establishment_id}/trainings`, "PUT", {
      ...training,
    })
      .then((response) => {
        if (!response.ok) {
          return parseError(response);
        }
        return response;
      })
      .then(
        (res) => {
          dispatch(endAjaxCall());
          dispatch(updateTrainingSuccess(res));
        },
        (error) => {
          dispatch(ajaxCallError(error));
          dispatch(endAjaxCall());
          dispatch(updateTrainingFailure(error));
        }
      );
  };

export function loadTrainingsSuccess(trainings) {
  return { type: types.LOAD_TRAININGS_SUCCESS, trainings };
}

export function searchTrainingsSuccess(trainings) {
  return { type: types.SEARCH_TRAININGS_SUCCESS, trainings };
}

export function loadTrainings() {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return request("/trainings")
      .then((res) => res.json())
      .then((trainings) => {
        dispatch(
          loadTrainingsSuccess(
            trainings.trainings !== null ? trainings.trainings : []
          )
        );
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      })
      .then((response) => {});
  };
}

export function searchTrainings(searchTraining) {
  return (dispatch) => {
    dispatch(beginAjaxCall());
    return request(
      "/training_categories/" +
        searchTraining.training_category_id +
        "/trainings"
    )
      .then((res) => res.json())
      .then((trainings) => {
        dispatch(
          loadTrainingsSuccess(
            trainings.trainings !== null ? trainings.trainings : []
          )
        );
        dispatch(endAjaxCall());
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      })
      .then((response) => {});
  };
}

export function saveTraining(training) {
  let tempTraining = Object.assign({}, training);

  const id = tempTraining.id;
  delete tempTraining["id"];

  tempTraining.min_penalty_amount =
    tempTraining.min_penalty_amount !== ""
      ? parseInt(tempTraining.min_penalty_amount)
      : 0;
  tempTraining.max_penalty_amount =
    tempTraining.max_penalty_amount !== ""
      ? parseInt(tempTraining.max_penalty_amount)
      : 0;

  if (id === "") {
    return save(tempTraining);
  } else {
    return update(tempTraining, id);
  }
}

export function update(training, id) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request("/trainings/" + id, "PUT", { training: training })
      .then((res) => {
        dispatch(loadTrainings());
        dispatch(endAjaxCall());
        if (!res.ok) {
          throw res.statusText;
        }
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}

export function save(training) {
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return request("/trainings", "POST", { training: training })
      .then((res) => {
        dispatch(loadTrainings());
        dispatch(endAjaxCall());
        if (!res.ok) {
          throw res.statusText;
        }
      })
      .catch((error) => {
        dispatch(ajaxCallError(error));
        dispatch(endAjaxCall());
        throw error;
      });
  };
}
