import React, { useMemo } from "react";
import PropTypes from "prop-types";

import {
  ANSWER_YES,
  ANSWERS,
} from "../../../../common/constants/caliberAnswers";

import YesIcon from "./assets/thumbs-up.svg";
import NoIcon from "./assets/thumbs-down.svg";
import CheckIcon from "./assets/checkmark.svg";

import "./caliber-answer.scss";
import { Text } from "../../../../../../shared-components/my-scope/atoms/text/index";

export const CaliberAnswer = ({ answer, isAuditor }) => {
  const translatedAnswer = useMemo(() => {
    return ANSWERS[answer];
  }, [answer]);
  const parsedAnswer = useMemo(() => {
    return answer === ANSWER_YES;
  }, [answer]);
  const AnswerIcon = useMemo(() => {
    return parsedAnswer ? YesIcon : NoIcon;
  }, [parsedAnswer]);
  const title = isAuditor ? "تقييم المدقق" : " تقييم المنشأة";
  const label = isAuditor ? "إجابة المدقق" : " إجابة المنشأة";

  return (
    <div className="caliber-answer">
      <header className="caliber-answer-header">
        <img className="caliber-answer-header__icon" src={CheckIcon} />
        <Text textType="p4" isBold className="caliber-answer-header__title">
          {title}
        </Text>
      </header>
      <main className="caliber-answer-main">
        <Text textType="p6" className="caliber-answer-main__desc">
          {label}
        </Text>
        <Text
          isBold
          textType="p6"
          Tag="span"
          className="caliber-answer-main__icon"
        >
          {translatedAnswer}
          <img src={AnswerIcon} />
        </Text>
      </main>
    </div>
  );
};

CaliberAnswer.propTypes = {
  answer: PropTypes.string.isRequired,
  isAuditor: PropTypes.bool,
};
CaliberAnswer.defaultProps = {
  isAuditor: false,
};
export default CaliberAnswer;
