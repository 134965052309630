import { SAVE_SELECTED_TRACK_TYPE } from "../actions/actionTypes";
import initialState from "./initialState";

export default function trackType(state = initialState.trackType, action) {
  switch (action.type) {
    case SAVE_SELECTED_TRACK_TYPE: {
      console.log("entered here");
      return action.payload.trackType;
    }
    default:
      return state;
  }
}
