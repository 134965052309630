import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import "./footer.scss";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import { AccessiblityContainer } from "../AccessibilityContainer";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: window.innerWidth < 720,
    };
  }
  checkMobileView = () => {
    this.setState((state, props) => ({
      isMobileView: window.innerWidth < 720,
    }));
  };

  componentDidMount() {
    window.addEventListener("resize", this.checkMobileView);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobileView);
  }
  render() {
    const rightsText =
      "جميع الحقوق محفوظة لوزارة الموارد البشرية والتنمية الاجتماعية © 2024";

    const MOL_logo = (
      <img
        src={"img/IMS-MHRSD-logo-w.svg"}
        className="logo-size"
        alt="MOL logo"
      />
    );
    const public_page_footer = (
      <a href="http://raqmi.dga.gov.sa/platforms/DigitalStamp/ShowCertificate/6500">
        <img
          src="http://raqmi.dga.gov.sa/platforms/DigitalStamp/GetStampWFile/3672"
          className="digital-stamp-size"
          alt="public page footer"
        />
      </a>
    );
    const phone_icon = (
      <img
        src={"img/phoneIcon.svg"}
        style={{ width: "21px", height: "21px" }}
        alt="phone icon"
      />
    );
    const mail_icon = (
      <img
        src={"img/mailIcon.svg"}
        style={{ width: "21px", height: "21px" }}
        alt="phone icon"
      />
    );
    const footer_icon = (
      <img
        src={"img/footerIcon.svg"}
        style={{ width: "135px", height: "219px" }}
      />
    );

    return (
      <div className="doNotPrint footer-wrapper">
        <span
          style={{
            position: "absolute",
            left: 0,
            top: -3,
          }}
        >
          {footer_icon}
        </span>
        <div className="footer-wrap">
          <div className="footer-contents">
            <div className="footer-nav-wrapper">
              <div className="logo-wrapper">
                {MOL_logo}
                {public_page_footer}
              </div>
              <div className={"flex gap-x-4 mx-4"}>
                <AccessiblityContainer
                  cls={"text-white stroke-white fill-white"}
                  disabledCls={"text-gray-400 stroke-gray-400 fill-gray-400"}
                />
              </div>
              <Link
                className={` stroke `}
                to="/privacyPolicy"
                id="main-page-link"
              >
                <Text
                  Tag="div"
                  textType="h9"
                  isBold
                  className="text-link-wrapper"
                >
                  سياسة الخصوصية
                </Text>
              </Link>
              <Link className={`stroke `} to="/faq" id="main-page-link">
                <Text
                  Tag="div"
                  textType="h9"
                  isBold
                  className="text-link-wrapper"
                >
                  الأسئلة الشائعة
                </Text>
              </Link>
              <Text
                Tag="div"
                textType="h8"
                isBold
                className="text-and-icon-wrapper"
              >
                <span className="text-and-icon-wrapper__text">
                  <span>920033411</span>
                </span>
                <span className="text-and-icon-wrapper__image">
                  {phone_icon}
                </span>
              </Text>
              <Text
                Tag="div"
                textType="h8"
                isBold
                className="text-and-icon-wrapper text-and-icon-wrapper__last-item "
              >
                <span className="text-and-icon-wrapper__text">
                  <span>ims@takamol.com.sa</span>
                </span>
                <span className="text-and-icon-wrapper__image">
                  {mail_icon}
                </span>
              </Text>
            </div>
            <div className="separator-line"></div>
            <div className="footer-bottom-text">
              <Text Tag="div" textType="h9" isBold>
                {rightsText}
              </Text>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
