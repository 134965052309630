import React from "react";

export const AttachmentIcon = (props) => (
  <svg width={24} height={24} {...props}>
    <path
      fill="#92929d"
      d="m1.731 7.8 6.592-6.53a4.408 4.408 0 0 1 6.191 0 4.305 4.305 0 0 1 0 6.13l-6.592 6.528a2.714 2.714 0 0 1-3.766-.043 2.649 2.649 0 0 1 0-3.773L10.7 3.628a1.017 1.017 0 0 1 1.429 0 .993.993 0 0 1 0 1.415l-6.544 6.483a.662.662 0 0 0 0 .943.678.678 0 0 0 .952 0l6.548-6.484a2.318 2.318 0 0 0 0-3.3 2.373 2.373 0 0 0-3.333 0L3.2 9.168a3.974 3.974 0 0 0 0 5.659 4.069 4.069 0 0 0 5.714 0l5.357-5.305a1.017 1.017 0 0 1 1.429 0 .993.993 0 0 1 0 1.415l-5.357 5.305a6.1 6.1 0 0 1-8.572 0A5.961 5.961 0 0 1 1.732 7.8Z"
    />
  </svg>
);
