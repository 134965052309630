import styled from "styled-components";
import { IMS_COLORS } from "../../colors";

export const containerClssList = {
  orange: "bg-ims-orange text-white",
  green: "bg-ims-green text-white",
  agate_gray: "bg-ims-agate_gray text-white",
  light_gray: "bg-ims-light_gray text-ims-gray",
};
export const selectedContainerClss = {
  orange: "bg-ims-orange/10 border-2 border-ims-orange",
  green: "bg-ims-green/10 border-2 border-ims-green",
  agate_gray: "bg-ims-agate_gray/10 border-2 border-gray-400",
  light_gray: "bg-ims-light_gray/10 border-2 border-gray-400",
};
export const selectedTextClss = {
  orange: "text-orange-400 text-lg font-bold",
  green: "text-ims-green text-lg font-bold",
  agate_gray: "text-gray-600 text-lg font-bold",
  light_gray: "text-gray-600 text-lg font-bold",
};
export const textClss = {
  border: {
    orange: "text-orange-400",
    green: "text-green-600",
    agate_gray: "text-ims-gray",
    light_gray: "text-ims-gray",
  },
  solid: {
    orange: "text-white",
    green: "text-white",
    agate_gray: "text-white",
    light_gray: "text-ims-gray",
  },
  text: {
    orange: "text-orange-400 stroke-orange-600",
    green: "text-green-600 stroke-green-600",
    agate_gray: "text-ims-agate_gray stroke-ims-agate_gray",
    light_gray: "text-ims-gray stroke-ims-gray",
  },
};

export const iconClss = {
  border: {
    orange: "fill-orange-600",
    green: "fill-green-600",
    agate_gray: "fill-ims-agate_gray",
    light_gray: "fill-ims-gray",
  },
  solid: {
    orange: "fill-white",
    green: "fill-white",
    agate_gray: "fill-white",
    light_gray: "fill-ims-gray",
  },
  text: {
    orange: "fill-orange-600",
    green: "fill-green-600",
    agate_gray: "fill-ims-agate_gray",
    light_gray: "fill-ims-gray",
  },
};

export const SButton = styled.button`
  ${({ variant, color }) =>
    variant == "border" &&
    `
        color: ${IMS_COLORS[color == "light_gray" ? "gray" : color]};
    `};
  ${({ variant, color }) =>
    variant == "text" &&
    `
        color: ${IMS_COLORS[color == "light_gray" ? "gray" : color]};
        background-color: transparent;
    `};
  :hover {
    opacity: 0.8;
  }
`;
