import ReactDOM from "react-dom";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import DropDownList from "./DropDownList";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import { AccessiblityContainer } from "../AccessibilityContainer";

const { bool, object } = PropTypes;

const menuModel = [
  {
    label: "الرئيسية",
    href: "home",
    isDropDown: false,
    requiredLoginState: null,
    isLogin: false,
  },
  {
    label: "ملف المنشأة",
    href: "establishmentProfile",
    isDropDown: false,
    DropDownItems: [],
    requiredLoginState: true,
    isLogin: false,
  },
  {
    label: "التقييم الذاتي",
    href: "establishmentDurationsPage",
    isDropDown: false,
    DropDownItems: [],
    requiredLoginState: true,
    isLogin: false,
  },
  {
    label: "التدريب",
    href: "establishmentTrainings/trainings",
    isDropDown: false,
    DropDownItems: [],
    requiredLoginState: true,
    isLogin: false,
  },
  {
    label: "عن المنصة",
    href: "about",
    isDropDown: false,
    requiredLoginState: null,
    isLogin: false,
  },
  {
    label: "المسار المميز",
    href: "specialTrack",
    isDropDown: false,
    requiredLoginState: null,
    isLogin: false,
  },

  {
    label: "الدليل التفاعلي",
    href: "interactiveGuide",
    isDropDown: false,
    requiredLoginState: null,
    isLogin: false,
  },

  {
    label: "الأسئلة الشائعة",
    href: "faq",
    isDropDown: false,
    DropDownItems: [],
    requiredLoginState: null,
    isLogin: false,
  },
];

class HeaderNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isDropDownOpen: false,
      size: 0,
    };
  }
  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
    this.handleOpenSideMenu();
  }
  componentDidUpdate() {
    this.handleOpenSideMenu();
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }
  handleOpenSideMenu = () => {
    if (this.state.isOpen) {
      document.body.style.position = "fixed";
      document.body.style.overflowY = "scroll";
      document.getElementById("overlay").style.display = "flex";
    } else {
      document.body.style.position = "static";
      document.body.style.overflowY = "auto";
      document.getElementById("overlay").style.display = "none";
    }
  };
  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
    }
  };
  toggle = () => {
    this.setState((state, props) => ({
      isOpen: !state.isOpen,
    }));
  };
  logIn() {
    window.location =
      process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
    window.location.reload();
  }
  logOut() {
    window.kc.logout();
  }
  render() {
    const { isLoggedIn, match, isExistingEstablishments } = this.props;
    const { isOpen } = this.state;

    const arrow_down = "img/arrow_down.png";
    const arrow_up = "img/arrow_up.png";
    const ImgSrc = this.state.isDropDownOpen ? arrow_down : arrow_up;

    const menuItems = menuModel
      .filter(
        ({ requiredLoginState }) =>
          requiredLoginState === null ||
          requiredLoginState === isExistingEstablishments
      )
      .map(({ label, href, isDropDown, DropDownItems }, i) => {
        if (isDropDown == true) {
          return (
            <div key={label} className={"bg-white"}>
              <NavItem
                className="px-0 flex grow border-4 border-blue-400 crusor whitespace-nowrap leading-relaxed"
                style={{ fontWeight: "bold" }}
              >
                <NavLink
                  onClick={() => {
                    this.setState({
                      isDropDownOpen: !this.state.isDropDownOpen,
                    });
                  }}
                >
                  {label}
                </NavLink>
                <img
                  key={i}
                  onClick={() => {
                    this.setState({
                      isDropDownOpen: !this.state.isDropDownOpen,
                    });
                  }}
                  src={ImgSrc}
                  className="dropDownArrow"
                />
              </NavItem>
              {this.state.isDropDownOpen && (
                <DropDownList items={DropDownItems} key={i} />
              )}
            </div>
          );
        } else {
          return (
            <NavItem
              className="flex grow"
              key={label}
              style={{ justifyContent: "center" }}
            >
              <NavLink
                active={match.url.search(`${href}$`) > -1}
                href={href && `/#/${href}`}
                style={{
                  fontWeight: "bold",
                  fontSize: "var(--base-size)!important",
                }}
              >
                <Text Tag="span" textType="h9">
                  {label}
                </Text>
              </NavLink>
            </NavItem>
          );
        }
      });
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        <div
          className="main-nav-blk "
          style={{
            flex: 3,
            display: "flex",
          }}
        >
          <div className="main-nav-toggle-wrapper">
            <span
              className="fa fa-bars main-nav-toggle"
              onClick={this.toggle}
            ></span>
          </div>
          <Nav
            className={`navbar navbar-inverse main-nav${
              isOpen ? " opened" : ""
            } bg-white`}
            navbar
            style={{
              flex: 1,
            }}
          >
            <div className={"w-full flex  md:items-center gap-x-4 menu-items"}>
              {menuItems}
              <div
                className={
                  "flex md:hidden items-center mt-4 w-full justify-center gap-x-4 border-4"
                }
                style={{ border: "none" }}
              >
                <AccessiblityContainer />
              </div>
            </div>

            {!isLoggedIn && isOpen ? (
              <NavItem
                className="px-3 crusor"
                key="تسجيل الدخول"
                style={{ flex: 1 }}
              >
                <NavLink
                  onClick={this.logIn}
                  style={{ fontWeight: "bold", fontSize: "120%" }}
                >
                  <Text Tag="span" textType="h9">
                    تسجيل الدخول
                  </Text>
                </NavLink>
              </NavItem>
            ) : (
              isOpen && (
                <NavLink
                  onClick={this.logOut}
                  style={{ fontWeight: "bold", fontSize: "120%" }}
                >
                  <Text Tag="span" textType="h9">
                    تسجيل الخروج
                  </Text>
                </NavLink>
              )
            )}
          </Nav>
        </div>
        {!isLoggedIn && (
          <div className={"flex items-center gap-x-4"}>
            <div className={"hidden xl:flex items-center gap-x-4"}>
              <AccessiblityContainer />
            </div>
            <div className="login-btn-wrapper">
              <Button onClick={this.logIn} text="تسجيل الدخول" isBold={false} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

HeaderNav.propTypes = {
  isLoggedIn: bool,
  match: object.isRequired,
  isExistingEstablishments: bool,
};

export default withRouter(HeaderNav);
