import React from "react";
import PropTypes from "prop-types";
import "./fastTrackComponent.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import Button from "../../../components/Button/Button";

const FastTrackComponent = ({ data, onClick, buttonText }) => (
  <div className="flex-1 fast-track-component  fast-track-component-wrapper ">
    <div className="ftc-text-wrapper">
      <div className="ftc-text fast-track-text">
        <Text Tag="div" textType="h3" className="custom-h3-white">
          {data.title}
        </Text>

        <Text Tag="div" textType="p2" className="thread-text-white">
          {data.description}
        </Text>

        {buttonText && (
          <Button
            iconLeft={"img/arrow.svg"}
            text="اطلب الخدمة"
            onClick={onClick}
            bigButton
            customTextSize={"h7"}
            buttonIconCustomStyle="ftc-button-icon"
            customStyle="ftc-button"
          />
        )}
      </div>
    </div>

    <div style={{ flex: 1, paddingLeft: "20px" }}>
      <div>
        <img src={"img/education.svg"} />
      </div>
    </div>
  </div>
);

FastTrackComponent.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
};

export default FastTrackComponent;
