import { useCallback, useEffect, useState } from "react";
import request from "../../../../../common/request";
import {
  RE_SUBMIT_STATUS,
  RE_SUBMIT_STATUS_API_VALUE,
} from "../../../../../common/constants/assessmentStatuses";

export const useAssessmentContextHandler = (establishmentProfileId, params) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [period, setPeriod] = useState({});

  const fetchDurationHandler = useCallback(
    async ({ establishmentProfileId }) => {
      try {
        setLoading(true);
        const res = await request(
          `/v1/establishments/${establishmentProfileId}/durations?get=${
            params.isBasicAssessment == "true"
              ? "current-BasicTrack"
              : "current-FastTrack"
          }`
        ).then((res) => res.json());
        const activeAssessment =
          res?.periods && res.periods.length > 0
            ? res.periods[res.periods.length - 1]
            : null;
        console.log("activeAssessment", activeAssessment);
        const periodId = activeAssessment.id;
        const assessmentVersion = activeAssessment.assessment_version;
        const periodsAssessmentId = activeAssessment.period_assessment_id;
        const isReturned =
          activeAssessment &&
          activeAssessment.audit_status === "ReturnedToConfirm";
        const status = isReturned
          ? activeAssessment.audit_status?.split(" ")?.join("")
          : activeAssessment.assessment?.status?.split(" ")?.join("");
        setPeriod(activeAssessment);
        // /v1/establishments/{establishment_id}/{period_assessment_id}/{period_id}/{assessment_version}?status
        const url = `/v1/establishments/${establishmentProfileId}/${periodsAssessmentId}/${periodId}/${assessmentVersion}?status=${status}`;
        const durationResponse = await request(url).then((res) => res.json());
        setLoading(false);
        console.log("durationResponse", durationResponse);
        const filteredResponse = {
          is_detailed_report_allowed:
            durationResponse?.is_detailed_report_allowed,
          qiwa_calibers: durationResponse?.qiwa_calibers,
          standard_categories: [],
        };
        durationResponse?.standard_categories?.forEach((standardCategory) => {
          console.log("standardCategory", standardCategory);
          if (standardCategory?.caliber_categories?.length > 0) {
            filteredResponse.standard_categories.push(standardCategory);
          }
        });
        console.log("filteredResponse", filteredResponse);
        setData(filteredResponse);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const fetchBasicTrackHandler = useCallback(
    async ({ establishmentProfileId }) => {
      try {
        setLoading(true);
        const res = await request(
          `/v1/establishments/${establishmentProfileId}/current_basic_track_assessment`
        ).then((res) => res.json());
        const periodId = res?.period_id;
        const assessmentVersion = res?.assessment_version;
        const periodsAssessmentId = res?.period_assessment_id;
        const assessmentType = res?.assessment_type;
        const isReturned =
          res?.audit_status === RE_SUBMIT_STATUS ||
          res?.audit_status === RE_SUBMIT_STATUS_API_VALUE;
        res.assessment_status =
          res.assessment_status === "Partially Filled"
            ? "PartiallyFilled"
            : res.assessment_status;
        const status = isReturned ? "ReturnedToConfirm" : res.assessment_status;
        setPeriod({
          id: periodId,
          assessment_version: assessmentVersion,
          period_assessment_id: periodsAssessmentId,
          assessment_type: assessmentType,
          audit_status: res?.audit_status,
          assessment: {
            id: res.assessment_id,
          },
        });
        // /v1/establishments/{establishment_id}/{period_assessment_id}/{period_id}/{assessment_version}?status
        const url = `/v1/establishments/${establishmentProfileId}/${periodsAssessmentId}/${periodId}/${assessmentVersion}?status=${status}`;
        const durationResponse = await request(url).then((res) => res.json());
        setLoading(false);
        const filteredResponse = {
          is_detailed_report_allowed:
            durationResponse?.is_detailed_report_allowed,
          qiwa_calibers: durationResponse?.qiwa_calibers,
          standard_categories: [],
        };
        durationResponse?.standard_categories?.forEach((standardCategory) => {
          if (standardCategory?.caliber_categories?.length > 0) {
            filteredResponse.standard_categories.push(standardCategory);
          }
        });
        setData(filteredResponse);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const fetchFastTrackHandler = useCallback(
    async ({ establishmentProfileId }) => {
      try {
        setLoading(true);
        const res = await request(
          `/v1/establishments/${establishmentProfileId}/current_special_track_assessment`
        ).then((res) => res.json());
        const periodId = res?.period_id;
        const assessmentVersion = res?.assessment_version;
        const periodsAssessmentId = res?.period_assessment_id;
        const assessmentType = res?.type;
        const isReturned =
          res?.audit_status === RE_SUBMIT_STATUS ||
          res?.audit_status === RE_SUBMIT_STATUS_API_VALUE;
        res.assessment_status =
          res.assessment_status === "Partially Filled"
            ? "PartiallyFilled"
            : res.assessment_status;
        const status = isReturned ? "ReturnedToConfirm" : res.assessment_status;
        setPeriod({
          id: periodId,
          assessment_version: assessmentVersion,
          period_assessment_id: periodsAssessmentId,
          assessment_type: assessmentType,
          audit_status: res?.audit_status,
          assessment: {
            id: res.assessment_id,
          },
        });
        // /v1/establishments/{establishment_id}/{period_assessment_id}/{period_id}/{assessment_version}?status
        const url = `/v1/establishments/${establishmentProfileId}/${periodsAssessmentId}/${periodId}/${assessmentVersion}?status=${status}`;
        const durationResponse = await request(url).then((res) => res.json());
        setLoading(false);
        const filteredResponse = {
          is_detailed_report_allowed:
            durationResponse?.is_detailed_report_allowed,
          qiwa_calibers: durationResponse?.qiwa_calibers,
          standard_categories: [],
        };
        durationResponse?.standard_categories?.forEach((standardCategory) => {
          if (standardCategory?.caliber_categories?.length > 0) {
            filteredResponse.standard_categories.push(standardCategory);
          }
        });
        setData(filteredResponse);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    []
  );
  useEffect(() => {
    if (!establishmentProfileId) return;
    if (params?.isBasicAssessment == "true") {
      fetchBasicTrackHandler({ establishmentProfileId, ...params });
    } else {
      fetchFastTrackHandler({ establishmentProfileId, ...params });
    }
  }, [establishmentProfileId, params]);

  return { data, period, loading };
};

export const submitAssessmentHandler = async ({
  assessment,
  calibers,
  data,
  establishmentProfileId,
}) => {
  // assessmentId =>  assessment?.id
  // periodId => assessment.period_id
  // periodAssessmentId => assessment?.period_assessment_id
  // assessmentVersion => assessment.assessment_version

  // standard_categories =>

  console.log({ assessment, calibers, data, establishmentProfileId });
  const standardCategories = [];
  data?.standard_categories?.forEach((category) => {
    const standards = [];
    category?.caliber_categories?.forEach((caliberCategory) => {
      caliberCategory?.calibers?.forEach((caliber) => {
        standards.push({ id: caliber.caliber_id, name: caliber.caliber_name });
      });
    });
    standardCategories.push({
      id: category.standard_category_id,
      name: category.standard_category_name,
      standards,
    });
  });
  // status
  const periodId = assessment?.id;
  const assessmentId = assessment?.assessment?.id;
  const assessmentVersion = assessment?.assessment_version;
  const periodsAssessmentId = assessment?.period_assessment_id;
  const payload = {
    assessment: {
      id: assessmentId,
      period_id: periodId,
      period_assessment_id: periodsAssessmentId,
      standard_categories: standardCategories,
      is_active: true,
      is_deleted: false,
      status: "Completed",
      submission_date: assessment.assessment.submission_date,
      assessment_version: assessmentVersion,
      assessment_type: assessment.assessment_type,
      audit_status: assessment.audit_status,
      unified_assessment_status:
        assessment.assessment.unified_assessment_status,
      fast_track_branches_status:
        assessment.assessment.fast_track_branches_status,
    },
  };
  // /v1/establishments/4a7f3c23-7fe4-48b7-9463-ad01250ab142/assessments
  console.log("payload", payload);
  const baseUrl = `/v1/establishments/${establishmentProfileId}/`;

  const url =
    assessment.audit_status === RE_SUBMIT_STATUS ||
    assessment.audit_status === RE_SUBMIT_STATUS_API_VALUE
      ? baseUrl + "returned_assessment"
      : baseUrl + "assessments";

  const res = await request(url, "POST", payload)
    .then(async (res) => {
      if (res.status == 400) {
        let message = await res.json();
        throw message;
      } else return res;
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("caught error", error);
      throw error;
    });

  console.log("res", res);
  return res;
};
