import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./helpWidget.scss";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import TitledTextInput from "../../../components/TitledTextInput/TitledTextInput";
import LabeledDropDown from "../../../components/LabeledDropDown/LabeledDropDown";
import TextArea from "../../../components/common/TextArea";
import Button from "../../../components/Button/Button";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import {
  digitsOnly,
  emailOnly,
  isEmailFormatOnly,
  isPhoneNumberFormat,
  lettersWithNumbersAndSpecialCharacters,
} from "../../../../utilities/helpers";
import { arabicAndEnglishLettersOnly } from "../../../../../frontend-admin/src/utilities/helpers";
import Draggable from "react-draggable";
import DropZone from "../../../components/DropZone/DropZone";
import * as jiraActions from "../../../actions/jiraWidgetActions";
import { connect } from "react-redux";
import toastr from "toastr";
import "./helpWidget.scss";

export class HelpWidget extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpenForm: false,
      name: null,
      phoneNumber: null,
      requestType: null,
      email: null,
      description: "",
      emailError: null,
      phoneNumberError: null,
      requestTypeError: null,
      descriptionError: null,
      nameError: null,
      attachmentsDropZoneList: [],
      attachmentsList: [],
      ticketNumber: null,
    };

    this.toggle = this.toggle.bind(this);
    this.emailValidation = this.emailValidation.bind(this);
    this.numberValidation = this.numberValidation.bind(this);
    this.nameValidation = this.nameValidation.bind(this);
    this.onNewAddAttachmentClick = this.onNewAddAttachmentClick.bind(this);
    this.remove = this.remove.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.descriptionValidation = this.descriptionValidation.bind(this);
    this.requestValidation = this.requestValidation.bind(this);
    this.createNewTicket = this.createNewTicket.bind(this);
  }

  toggle = () => {
    const {
      isOpenForm,
      sendSuccess,
      name,
      email,
      phoneNumber,
      description,
      requestType,
    } = this.state;
    this.setState({
      isOpenForm: !isOpenForm,
    });
    if (!isOpenForm && sendSuccess) {
      this.setState({
        name: null,
        requestType: null,
        email: null,
        description: "",
        attachmentsList: [],
        attachmentsDropZoneList: [],
      });
    } else if (
      isOpenForm &&
      !name &&
      !email &&
      !phoneNumber &&
      !description &&
      !requestType
    ) {
      this.setState({
        emailError: null,
        phoneNumberError: null,
        requestTypeError: null,
        descriptionError: null,
        nameError: null,
      });
    }
  };
  emailValidation = () => {
    const { email } = this.state;
    const isCorrectFormat = isEmailFormatOnly(email);
    if (!isCorrectFormat) {
      this.setState({
        emailError: "الرجاء إدخال بريد إلكتروني صحيح",
      });
    }
  };

  numberValidation = () => {
    const { phoneNumber } = this.state;

    const isCorrectFormat = isPhoneNumberFormat(phoneNumber);
    if (!phoneNumber || phoneNumber.trim().length === 0) {
      this.setState({
        phoneNumberError: "الرجاء إدخال رقم التواصل",
      });
    } else if (!isCorrectFormat) {
      this.setState({
        phoneNumberError: "الرجاء إدخال رقم تواصل يبدأ بـ05",
      });
    } else {
      this.setState({
        phoneNumberError: null,
      });
    }
  };
  nameValidation = () => {
    const { name } = this.state;
    if (!name || name.trim().length === 0) {
      this.setState({
        nameError: "الرجاء إدخال الاسم",
      });
    }
  };
  descriptionValidation = () => {
    const { description } = this.state;
    if (description === "") {
      this.setState({
        descriptionError: "الرجاء إدخال الوصف",
      });
    }
  };
  requestValidation = () => {
    const { requestType } = this.state;
    if (requestType === null) {
      this.setState({
        requestTypeError: "الرجاء اختيار نوع الطلب",
      });
    }
  };
  onNewAddAttachmentClick = (event) => {
    const { attachmentsDropZoneList, attachmentsList } = this.state;
    if (attachmentsDropZoneList.length < 4) {
      const list = attachmentsDropZoneList.concat(
        <div
          key={attachmentsDropZoneList.length}
          className="help-widget-attachments__wrapper"
        >
          <div className="help-widget-attachments__drop-zone">
            <DropZone
              DropZoneTitle="قم بسحب الملف أو تحديده من جهازك"
              uploadAttachment={(val) => {
                const list = attachmentsList.concat(val);

                this.setState({ attachmentsList: list });
              }}
            />
          </div>
          <div className="help-widget-attachments__delete-button-wrapper">
            <i
              onClick={() => this.remove(attachmentsDropZoneList.length)}
              id="add-new-attachment-button"
              className="help-widget-attachments__delete-button-link"
            >
              <img
                src={"img/delete.svg"}
                alt="button icon"
                className="help-widget-attachments__delete-button-image"
              />
            </i>
          </div>
        </div>
      );
      this.setState({ attachmentsDropZoneList: list });
    } else {
      toastr.error("لا يمكنك إضافة أكثر من ٥ مرفقات");
    }
  };

  remove = (fileKey) => {
    const { attachmentsDropZoneList } = this.state;
    const newFiles = [...attachmentsDropZoneList];
    let list = newFiles.filter((attachment) => {
      if (attachment.key !== fileKey.toString()) return attachment;
    });

    this.setState({ attachmentsDropZoneList: list });
  };
  onSubmit = () => {
    const {
      requestType,
      name,
      description,
      email,
      attachmentsList,
      phoneNumber,
    } = this.state;
    const { jiraHelpWidget } = this.props;
    const descriptionWithType = "Establishment- " + description;

    if (name && email && requestType && description && phoneNumber) {
      jiraHelpWidget(
        name,
        email,
        requestType.value,
        descriptionWithType,
        attachmentsList,
        phoneNumber
      ).then((tickNum) => {
        this.setState({ ticketNumber: tickNum });
        if (tickNum) {
          toastr.success("تم الإسال بنجاح");
        } else toastr.error("حدث خطأ ما");
      });
    } else {
      if (!name) {
        this.nameValidation();
      }
      if (!email) {
        this.emailValidation();
      }
      if (!phoneNumber) {
        this.numberValidation();
      }
      if (!description) {
        this.descriptionValidation();
      }
      if (!requestType) {
        this.requestValidation();
      }
    }
  };
  createNewTicket = () => {
    this.setState({
      ticketNumber: null,
      name: null,
      email: null,
      description: null,
      requestType: null,
      attachmentsDropZoneList: [],
      attachmentsList: [],
    });
  };
  render() {
    const {
      isOpenForm,
      requestType,
      name,
      phoneNumber,
      description,
      email,
      requestTypeError,
      emailError,
      phoneNumberError,
      nameError,
      descriptionError,
      attachmentsDropZoneList,
      attachmentsList,
      ticketNumber,
    } = this.state;

    return (
      <div>
        <Draggable>
          <div className="help-widget-wrapper">
            <Button
              text="تواصل معنا"
              onClick={this.toggle}
              customStyle="help-widget-button"
              id="help-button"
              customTextSize="h7"
              icon="img/ContactUsiconWhite.svg"
            />
          </div>
        </Draggable>
        <Modal isOpen={isOpenForm} toggle={this.toggle}>
          <ModalHeader className="help-widget-popup-header">
            <Text
              Tag="div"
              textType="h8"
              isBold
              className="help-widget-popup-header__text"
            >
              اترك لنا رسالة
            </Text>
          </ModalHeader>
          <ModalBody className={"help-widget-modal-body"}>
            {ticketNumber ? (
              <div className="help-widget-attachments__info-bubble">
                <div className="help-widget-success-bubble-wrapper">
                  <div className="help-widget-success-bubble-wrapper__icon">
                    <img
                      src={"img/checkIcon.svg"}
                      style={{ width: 20, height: 20 }}
                    />
                  </div>
                  <Text Tag="div" textType="p4">
                    تم انشاء التذكره بنجاح
                  </Text>
                </div>
                <Text Tag="div" textType="p5">
                  رقم التذكرة {ticketNumber}
                </Text>

                <Button
                  text="إنشاء تذكرة جديدة"
                  onClick={this.createNewTicket}
                  customStyle="help-widget-new-ticket-button"
                  id="new-help-ticket-button"
                />
              </div>
            ) : (
              <Fragment>
                <div className="help-widget-labeled-drop-down">
                  <LabeledDropDown
                    label="نوع الطلب"
                    placeholder="نوع الطلب"
                    value={requestType}
                    onChange={(item) => {
                      this.setState({ requestType: item });
                    }}
                    onBlur={this.requestValidation}
                    onFocus={() => {
                      this.setState({
                        requestTypeError: null,
                      });
                    }}
                    options={[
                      {
                        label: "استفسار",
                        value: 4,
                      },
                      {
                        label: "الدعم الفني",
                        value: 8,
                      },
                    ]}
                    errorText={requestTypeError}
                  />
                </div>
                <TitledTextInput
                  title="الاسم"
                  type="text"
                  onChange={(event) => {
                    const nameInput = arabicAndEnglishLettersOnly(
                      event.target.value
                    );
                    this.setState({
                      name: nameInput,
                    });
                  }}
                  placeholder="الاسم"
                  value={name}
                  name="name"
                  onBlur={this.nameValidation}
                  onFocus={() => {
                    this.setState({
                      nameError: null,
                    });
                  }}
                  errorText={nameError}
                />
                <TitledTextInput
                  title="رقم التواصل"
                  type="text"
                  onChange={(event) => {
                    const numberInput = digitsOnly(event.target.value);
                    this.setState({
                      phoneNumber: numberInput,
                    });
                  }}
                  onBlur={this.numberValidation}
                  onFocus={() => {
                    this.setState({
                      phoneNumberError: null,
                    });
                  }}
                  placeholder="05xx-xxx-xxxx"
                  value={phoneNumber}
                  name="email"
                  maxLength="10"
                  errorText={phoneNumberError}
                />
                <TitledTextInput
                  title="البريد الإلكتروني"
                  type="text"
                  onChange={(event) => {
                    const emailInput = emailOnly(event.target.value);
                    this.setState({
                      email: emailInput,
                    });
                  }}
                  onBlur={this.emailValidation}
                  onFocus={() => {
                    this.setState({
                      emailError: null,
                    });
                  }}
                  placeholder="البريد الإلكتروني"
                  value={email}
                  name="email"
                  maxLength="70"
                  errorText={emailError}
                />

                <TextArea
                  name="description"
                  label="الوصف"
                  isResizable={false}
                  onChange={(event) => {
                    const descriptionInput =
                      lettersWithNumbersAndSpecialCharacters(
                        event.target.value
                      );
                    this.setState({
                      description: descriptionInput,
                    });
                  }}
                  value={description}
                  onBlur={this.descriptionValidation}
                  onFocus={() => {
                    this.setState({
                      descriptionError: null,
                    });
                  }}
                  errorText={descriptionError}
                  customWrapper={"help-widget-text-area-custom-wrapper"}
                />
                <Text
                  Tag="div"
                  textType="p5"
                  className="help-widget-attachments__title"
                  isBold
                >
                  المرفقات
                </Text>
                <div className="help-widget-attachments__info-bubble">
                  <Text Tag="div" textType="p6">
                    نوع الملفات المسموح بها هي .jpeg, .png, .bmp, .jpg, .pdf
                  </Text>

                  <Text Tag="div" textType="p6">
                    الحجم المسموح لكل ملف هو 5 ميجا بايت
                  </Text>
                </div>
                <DropZone
                  DropZoneTitle="قم بسحب الملف أو تحديده من جهازك"
                  uploadAttachment={(val) => {
                    const list = attachmentsList.concat(val);

                    this.setState({ attachmentsList: list });
                  }}
                />

                {attachmentsDropZoneList}
                <Button
                  text=""
                  icon={"img/plus.svg"}
                  iconHover={"img/plus.svg"}
                  onClick={this.onNewAddAttachmentClick}
                  customStyle="help-widget-add-button"
                  buttonIconCustomStyle="help-widget-add-button-icon"
                  id="add-attachment-button"
                />

                <Button
                  text="إرسال"
                  onClick={this.onSubmit}
                  customStyle="help-widget-send-button"
                  id="send-help-button"
                />
              </Fragment>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
HelpWidget.propTypes = { jiraHelpWidget: PropTypes.func };
HelpWidget.defaultProps = {};

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = (dispatch) => ({
  jiraHelpWidget: (
    name,
    email,
    requestType,
    description,
    attachments,
    phoneNumber
  ) =>
    dispatch(
      jiraActions.jiraHelpWidget(
        name,
        email,
        requestType,
        description,
        attachments,
        phoneNumber
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpWidget);
