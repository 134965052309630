import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../../components/Button/Button";
import PropTypes from "prop-types";
import "./popUp.scss";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import LabeledCheckbox from "../../../components/LabeledCheckBox/LabeledCheckbox";

class PopUp extends Component {
  render() {
    const {
      onClickAction,
      title,
      body,
      onClickCancel,
      actionButtonText,
      cancelButtonText,
      isOpen,
      toggleModal,
      dismissButtonText,
      onClickDismiss,
      icon,
      bodyLink,
      checkboxLabel,
      isChecked,
      onCheckboxChange,
      exit,
      isCustomPopUpModel,
      closeIcon,
      image,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        className={`${isCustomPopUpModel} pop-up`}
        toggle={toggleModal}
      >
        <ModalHeader className={"pop-up__header"}>
          <div className="pop-up__header-row">
            {icon && (
              <div className="pop-up__header-icon">
                <img src={icon} alt="alert icon" />
              </div>
            )}

            <Text
              Tag="div"
              textType="h8"
              isBold
              className="pop-up__header-title"
            >
              {title}
            </Text>
            {exit &&
              (closeIcon ? (
                <div
                  style={{ marginRight: 610, cursor: "pointer" }}
                  onClick={onClickCancel}
                >
                  {closeIcon}
                </div>
              ) : (
                <div className="pop-up__header-exit">
                  <span
                    className="icon"
                    aria-hidden="true"
                    onClick={onClickCancel}
                  >
                    ×
                  </span>
                </div>
              ))}
          </div>
        </ModalHeader>
        <ModalBody>
          <Text textType="p4" className="pop-up-body-wrapper">
            {body}
            {bodyLink}
          </Text>
          {checkboxLabel && (
            <div className="pop-up-body-wrapper">
              <LabeledCheckbox
                type="checkbox"
                checked={isChecked}
                onChange={onCheckboxChange}
                textType="p4"
                label={checkboxLabel}
              />
            </div>
          )}
          {image && (
            <div className="pop-up-image-wrapper">
              {/* <img src={image} alt="image_icon" /> */}
              {image}
            </div>
          )}
          <div className="pop-up-buttons-wrapper">
            {actionButtonText && (
              <Button onClick={() => onClickAction()} text={actionButtonText} />
            )}
            {cancelButtonText && (
              <div className="pop-up-buttons-wrapper__second-button">
                <Button
                  primary
                  text={cancelButtonText}
                  onClick={() => onClickCancel()}
                />
              </div>
            )}
            {dismissButtonText && (
              <Button
                secondary
                text={dismissButtonText}
                onClick={() => onClickDismiss()}
              />
            )}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
PopUp.propTypes = {
  onClickAction: PropTypes.func,
  onClickCancel: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  actionButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  dismissButtonText: PropTypes.string,
  onClickDismiss: PropTypes.func,
  icon: PropTypes.string,
  bodyLink: PropTypes.object,
  checkboxLabel: PropTypes.string,
  isChecked: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  closeIcon: PropTypes.elementType,
  image: PropTypes.elementType,
  isCustomPopUpModel: PropTypes.string,
};
export default PopUp;
