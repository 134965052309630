import React from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as certificateActions from "../../actions/certificateActions";

import {
  AUDIT_STATUS_NOAUDITING,
  AUDIT_STATUS_REAUDIT,
  AUDIT_STATUS_SHOWAUDITRESULT,
  AUDIT_STATUS_SHOWAUDITRESULTANDREAUDITT,
  STATUS_CANCELLED,
  STATUS_ENABLED,
  STATUS_SUBMITTED,
  STATUS_TIMEOUT,
  UNIFIED_ASSESSMENT_STATUS_NOT_PAID,
  UNIFIED_ASSESSMENT_STATUS_ENABLE,
  ASSESSMENT_TYPE_UNIFIED_ASSESSMENT,
  UNIFIED_ASSESSMENT_STATUS_DISABLE,
  INSPECTION_REQUEST_STATUS_NEW,
  INSPECTION_REQUEST_STATUS_INITIATED,
  INSPECTION_REQUEST_STATUS_ON_PROGRESS,
  INSPECTION_REQUEST_STATUS_COMPLETED,
  TAMAM_INSPECTION_STATUS_ONLY_INSPECTION,
  TAMAM_INSPECTION_STATUS_BOTH,
  TAMAM_INSPECTION_STATUS_ONLY_ASSESSMENT,
  ASSESSMENT_TYPE_BASIC,
  ASSESSMENT_TYPE_FAST_TRACK,
  STATUS_ACTIVE,
  AUDIT_STATUS_COMPLETED,
  AUDIT_STATUS_UNDERGRACPERIOD,
  AUDIT_STATUS_UNDERINSPECTION,
} from "../../common/constants/assessmentStatuses";
import {
  DETAILEDREPORT,
  RE_ASSESSMENT,
  FASTTRACK,
  UNIFIED_ASSESSMENT,
  ADDITIONAL_ASSESSMENT,
  GRACE_PERIOD,
  STATIC_FAST_TRACK_FOR_BRANCHES,
  FAST_TRACK,
  FAST_TRACK_BRANCHES,
} from "../../common/constants/paymentStatuses";
// eslint-disable-next-line import/namespace
import * as unifiedActions from "../../actions/unifiedActions";

import "./dropdown-action.scss";

import PopUp from "../../views/Components/PopUp/PopUp";
import {
  createReopenAssessment,
  createInspectionRequest,
  cancelInspectionRequest,
} from "../../actions/beforeLoadActions";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import Button from "../Button/Button";
import {
  STATUS_NOT_PAID,
  STATUS_ENABLE,
} from "../../common/constants/fastTrackForBranches";
import { PdfReport } from "../../../../shared-components/my-scope/pages/pdf-report";
import * as fastTrackActions from "../../actions/FastTrackActions";
import DotsIcon from "./DotsIcon";
import { saveSelectedDuration } from "../../actions/durationActions";
import { setTrackType } from "../AssessmentCards/FeatureCard";

export class DropdownAction extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
      isReopenAssessment: false,
      isCreatingInspectionRequest: false,
      isCancelInspectionRequest: false,
      isShowAuthorizationPopUp: false,
      isShowAuthorizationBranchesPopUp: false,
    };

    this.dropdownRef = null;

    this.setDropdownRef = this.setDropdownRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hide = this.hide.bind(this);
    this.redirectToSelfAssessmentResult =
      this.redirectToSelfAssessmentResult.bind(this);
    this.redirectToEstablishmentAssessmentPage =
      this.redirectToEstablishmentAssessmentPage.bind(this);
    this.redirectToSelfAssessmentPayment =
      this.redirectToSelfAssessmentPayment.bind(this);
    this.redirectToSelfAssessmentPaymentAdditionalAssessment =
      this.redirectToSelfAssessmentPaymentAdditionalAssessment.bind(this);
    this.redirectToFastTrackPayment =
      this.redirectToFastTrackPayment.bind(this);
    this.redirectToFastTrackResult = this.redirectToFastTrackResult.bind(this);
    this.redirectToDetailedReportPayment =
      this.redirectToDetailedReportPayment.bind(this);
    this.redirectToEstablishmentUnifiedAssessmentPage =
      this.redirectToEstablishmentUnifiedAssessmentPage.bind(this);
    this.redirectToUnifiedAssessmentPayment =
      this.redirectToUnifiedAssessmentPayment.bind(this);
    this.isReopenAssessment = this.isReopenAssessment.bind(this);
    this.createReopenAssessmentRequest =
      this.createReopenAssessmentRequest.bind(this);
    this.showCertificate = this.showCertificate.bind(this);
    this.isCreatingInspectionRequest =
      this.isCreatingInspectionRequest.bind(this);
    this.isCancelInspectionRequest = this.isCancelInspectionRequest.bind(this);
    this.createInspectionRequest = this.createInspectionRequest.bind(this);
    this.redirectToGracePeriodPayment =
      this.redirectToGracePeriodPayment.bind(this);
    this.redirectToFastTrackForBranchesPage =
      this.redirectToFastTrackForBranchesPage.bind(this);
    this.redirectToFastTrackForBranchesPayment =
      this.redirectToFastTrackForBranchesPayment.bind(this);
    this.showAuditReport = this.showAuditReport.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const { profileId, duration, showGracePeriod } = this.props;

    // Determine the effective IDs
    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;

    if (
      showGracePeriod &&
      duration.state === STATUS_SUBMITTED &&
      duration.is_detailed_report
    ) {
      this.props.fetchGracePeriodStatus(
        profileId,
        effectivePeriodId,
        effectiveAssessmentId,
        duration.assessment_version
      );
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setDropdownRef(element) {
    this.dropdownRef = element;
  }

  handleClickOutside(event) {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.hide();
    }
  }

  toggle() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  hide() {
    this.setState({ isOpen: false });
  }

  redirectToSelfAssessmentResult() {
    const { profileId, history, duration } = this.props;
    // Determine the effective IDs
    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;
    history.push(
      `/establishmentAssessmentResult/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}`
    );
  }

  redirectToEstablishmentAssessmentPage() {
    const { history, duration, saveSelectedDuration } = this.props;
    const { id, period_id, assessment_version, period_assessment_id } =
      duration;
    const isBasicAssessment =
      ASSESSMENT_TYPE_BASIC === duration.assessment_type;
    saveSelectedDuration(duration);
    const url = `/establishmentAssessmentInstruction/${
      id || period_id
    }/${assessment_version}/${isBasicAssessment}/${period_assessment_id}`;
    history.push(url);
  }

  redirectToSelfAssessmentPayment() {
    const {
      profileId,
      history,
      duration,
      saveSelectedDuration,
      setTrackType,
      trackType,
    } = this.props;

    const withoutResult = false;
    setTrackType(trackType);
    saveSelectedDuration(duration);
    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;
    history.push(
      `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${withoutResult}/${RE_ASSESSMENT}/${duration.period_assessment_id}`
    );
  }

  redirectToSelfAssessmentPaymentAdditionalAssessment() {
    const { profileId, history, duration, saveSelectedDuration } = this.props;
    const withoutResult = true;
    saveSelectedDuration(duration);
    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;
    history.push(
      `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${withoutResult}/${ADDITIONAL_ASSESSMENT}/${duration.period_assessment_id}`
    );
  }

  redirectToFastTrackPayment() {
    const {
      profileId,
      history,
      duration,
      fetchFastTrackServiceAuthorization,
      saveSelectedDuration,
    } = this.props;
    const withoutResult = false;

    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;

    saveSelectedDuration(duration);
    fetchFastTrackServiceAuthorization(
      profileId,
      effectivePeriodId,
      FAST_TRACK
    ).then((isFastTrackAuthorized) => {
      if (isFastTrackAuthorized) {
        history.push(
          `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${withoutResult}/${FASTTRACK}/${duration.period_assessment_id}`
        );
      } else {
        this.setState({ isShowAuthorizationPopUp: true, isOpen: false });
      }
    });
  }
  redirectToFastTrackForBranchesPayment() {
    const { profileId, history, duration, saveSelectedDuration } = this.props;
    const withoutResult = false;
    saveSelectedDuration(duration);
    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;

    history.push(
      `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${withoutResult}/${FAST_TRACK_BRANCHES}/${duration.period_assessment_id}`
    );
  }
  redirectToFastTrackForBranchesPage() {
    const {
      profileId,
      history,
      duration,
      establishmentProfile,
      fetchFastTrackResult,
      fetchFastTrackServiceAuthorization,
      saveSelectedDuration,
    } = this.props;
    const withoutResult = false;

    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;

    if (
      establishmentProfile.is_main_branch &&
      duration.assessment_type === FAST_TRACK &&
      duration.fast_track_branches_status === STATUS_ENABLE
    ) {
      fetchFastTrackResult(
        profileId,
        effectivePeriodId,
        effectiveAssessmentId,
        duration.assessment_version,
        false
      ).then((data) => {
        const isCertified =
          duration.assessment_type === ASSESSMENT_TYPE_FAST_TRACK &&
          duration.state === STATUS_SUBMITTED &&
          [
            AUDIT_STATUS_COMPLETED,
            AUDIT_STATUS_UNDERGRACPERIOD,
            AUDIT_STATUS_UNDERINSPECTION,
          ].includes(duration.audit_status) &&
          data.audit_report.certificate_status === "Certified";

        if (isCertified) {
          history.push({
            pathname: `/establishmentUnifiedAssessment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${duration.period_assessment_id}`,
            isBranchesSpecialTrack: true,
          });
        } else {
          saveSelectedDuration(duration);
          history.push(
            `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${withoutResult}/${STATIC_FAST_TRACK_FOR_BRANCHES}/${duration.period_assessment_id}`
          );
        }
      });
    } else {
      fetchFastTrackServiceAuthorization(
        profileId,
        effectivePeriodId,
        FAST_TRACK_BRANCHES
      ).then((isFastTrackBranchesAuthorized) => {
        if (isFastTrackBranchesAuthorized) {
          saveSelectedDuration(duration);
          history.push(
            `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${withoutResult}/${STATIC_FAST_TRACK_FOR_BRANCHES}/${duration.period_assessment_id}`
          );
        } else {
          this.setState({
            isShowAuthorizationBranchesPopUp: true,
            isOpen: false,
          });
        }
      });
    }
  }

  redirectToFastTrackResult(period_id, assessment_id, assessment_version) {
    const { profileId, history } = this.props;

    history.push(
      `/establishmentFastTrackResult/${period_id}/${assessment_id}/${profileId}/${assessment_version}`
    );
  }

  redirectToDetailedReportPayment() {
    const { profileId, history, duration, saveSelectedDuration } = this.props;
    const withoutResult = true;
    saveSelectedDuration(duration);

    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;

    history.push(
      `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${withoutResult}/${DETAILEDREPORT}/${duration.period_assessment_id}`
    );
  }

  redirectToGracePeriodPayment() {
    const { profileId, history, duration, saveSelectedDuration } = this.props;
    const withoutResult = true;
    saveSelectedDuration(duration);
    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;
    history.push(
      `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${withoutResult}/${GRACE_PERIOD}/${duration.period_assessment_id}`
    );
  }

  redirectToEstablishmentUnifiedAssessmentPage() {
    const { profileId, history, duration } = this.props;
    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;
    history.push(
      `/establishmentUnifiedAssessment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${duration.period_assessment_id}`
    );
  }

  redirectToUnifiedAssessmentPayment() {
    const { duration, profileId, saveSelectedDuration } = this.props;
    const withoutResult = true;
    saveSelectedDuration(duration);
    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;
    this.props.history.push(
      `/establishmentAssessmentPayment/${effectivePeriodId}/${effectiveAssessmentId}/${profileId}/${duration.assessment_version}/${withoutResult}/${UNIFIED_ASSESSMENT}/${duration.period_assessment_id}`
    );
  }

  isReopenAssessment() {
    this.setState({ isReopenAssessment: !this.state.isReopenAssessment });
  }

  createReopenAssessmentRequest() {
    const { profileId, duration } = this.props;

    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;
    return this.props.createReopenAssessmentRequest(
      profileId,
      effectivePeriodId,
      effectiveAssessmentId,
      duration.assessment_version,
      duration.period_assessment_id
    );
  }

  showCertificate() {
    const { duration, profileId, fetchFastTrackResult } = this.props;

    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;

    fetchFastTrackResult(
      profileId,
      effectivePeriodId,
      effectiveAssessmentId,
      duration.assessment_version,
      true
    );
  }
  showAuditReport() {
    const { duration, profileId, fetchFastTrackResult } = this.props;

    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;

    fetchFastTrackResult(
      profileId,
      effectivePeriodId,
      effectiveAssessmentId,
      duration.assessment_version,
      false
    ).then((data) => {
      const auditReport = data.audit_report;
      const report = data.reports;

      PdfReport(
        {
          assessment: auditReport.assessment,
          auditing_score: auditReport.auditing_score,
          certificate_name: auditReport.certificate_name,
          max_penalty: auditReport.max_penalty,
          closing_days: auditReport.closing_days,
        },
        {
          establishment: {
            ...report.establishment,
            nitaqat_color: report.establishment.nitaq_color,
          },
          request_created_by: auditReport.request_created_by.String,
          request_created_by_email: auditReport.request_created_by_email.String,
          request_created_by_mobile:
            auditReport.request_created_by_mobile.String,
          period_name: report.period_details.name,
          start_date: report.period_details.start_date,
          end_date: report.period_details.end_date,
          auditors: auditReport.auditors,
          committee_arabic_name: auditReport.committee_arabic_name,
          supervisor_name: auditReport.supervisor_name,
        },
        true
      );
    });
  }
  isCreatingInspectionRequest() {
    this.setState({
      isCreatingInspectionRequest: !this.state.isCreatingInspectionRequest,
    });
  }
  isCancelInspectionRequest() {
    this.setState({
      isCancelInspectionRequest: !this.state.isCancelInspectionRequest,
    });
  }
  createInspectionRequest() {
    const { profileId, duration } = this.props;

    const effectivePeriodId = duration.period_id || duration.id;
    const effectiveAssessmentId =
      duration.assessment_id || duration.assessment?.id;

    return this.props.createInspectionRequest(
      profileId,
      effectivePeriodId,
      effectiveAssessmentId,
      duration.assessment_version
    );
  }

  cancelInspectionRequest(inspection_id) {
    const { profileId } = this.props;

    return this.props.cancelInspectionRequest(profileId, inspection_id);
  }
  getActions() {
    const { duration, gracePeriodStatus, establishmentProfile } = this.props;

    const canGoToAssessmentPage =
      duration.state === STATUS_ENABLED && duration.is_free_assessment;

    const canReopenAssessment =
      !this.props.isReturnedAssessment &&
      duration.state === STATUS_SUBMITTED &&
      !this.props.isReturnedAssessment &&
      duration.is_open_reassessment &&
      duration.assessment_type === ASSESSMENT_TYPE_UNIFIED_ASSESSMENT &&
      duration.is_unified_assessment_free_reassessment ===
        UNIFIED_ASSESSMENT_STATUS_ENABLE &&
      ![
        INSPECTION_REQUEST_STATUS_NEW,
        INSPECTION_REQUEST_STATUS_INITIATED,
        INSPECTION_REQUEST_STATUS_ON_PROGRESS,
        INSPECTION_REQUEST_STATUS_COMPLETED,
      ].includes(duration?.inspection_assessment_status?.String);

    const canGoToDetailedPaymentReport =
      !this.props.isReturnedAssessment &&
      duration.state === STATUS_SUBMITTED &&
      !duration.is_detailed_report;

    const canGoToFastTrackPayment =
      !this.props.isReturnedAssessment &&
      duration.state === STATUS_SUBMITTED &&
      duration.assessment_type !== ASSESSMENT_TYPE_FAST_TRACK &&
      !duration.is_fast_track &&
      ![
        INSPECTION_REQUEST_STATUS_NEW,
        INSPECTION_REQUEST_STATUS_INITIATED,
        INSPECTION_REQUEST_STATUS_ON_PROGRESS,
        INSPECTION_REQUEST_STATUS_COMPLETED,
      ].includes(duration?.inspection_assessment_status?.String);

    const canGoToSelfAssessmentResult =
      !this.props.isReturnedAssessment &&
      duration.state === STATUS_SUBMITTED &&
      ![
        INSPECTION_REQUEST_STATUS_NEW,
        INSPECTION_REQUEST_STATUS_INITIATED,
        INSPECTION_REQUEST_STATUS_ON_PROGRESS,
        INSPECTION_REQUEST_STATUS_COMPLETED,
      ].includes(duration?.inspection_assessment_status?.String);
    const canShowCertificate =
      duration.assessment_type === FAST_TRACK &&
      duration.state === STATUS_SUBMITTED &&
      [
        AUDIT_STATUS_COMPLETED,
        AUDIT_STATUS_UNDERGRACPERIOD,
        AUDIT_STATUS_UNDERINSPECTION,
      ].includes(duration.audit_status);

    const canToUnifiedAssessmentPayment = false;
    /* !this.props.isReturnedAssessment &&
      duration.state == STATUS_SUBMITTED &&
      duration.unified_assessment_status ===
        UNIFIED_ASSESSMENT_STATUS_NOT_PAID &&
      ![
        INSPECTION_REQUEST_STATUS_NEW,
        INSPECTION_REQUEST_STATUS_INITIATED,
        INSPECTION_REQUEST_STATUS_ON_PROGRESS,
        INSPECTION_REQUEST_STATUS_COMPLETED,
      ].includes(duration.inspection_assessment_status.String); */

    const canGoToEstablishmentUnifiedAssessmentPage = false;
    /* !this.props.isReturnedAssessment &&
    duration.state == STATUS_SUBMITTED
 &&
    duration.unified_assessment_status === UNIFIED_ASSESSMENT_STATUS_ENABLE &&
    ![
      INSPECTION_REQUEST_STATUS_NEW,
      INSPECTION_REQUEST_STATUS_INITIATED,
      INSPECTION_REQUEST_STATUS_ON_PROGRESS,
      INSPECTION_REQUEST_STATUS_COMPLETED,
    ].includes(duration.inspection_assessment_status.String);
    */

    const canGoToSelfAssessmentPayment =
      !this.props.isReturnedAssessment &&
      duration.state === STATUS_ENABLED &&
      !duration.is_free_assessment &&
      ![
        INSPECTION_REQUEST_STATUS_NEW,
        INSPECTION_REQUEST_STATUS_INITIATED,
        INSPECTION_REQUEST_STATUS_ON_PROGRESS,
        INSPECTION_REQUEST_STATUS_COMPLETED,
      ].includes(duration?.inspection_assessment_status?.String);

    const canGoToSelfAssessmentPaymentWithUnified =
      !this.props.isReturnedAssessment &&
      duration.state === STATUS_SUBMITTED &&
      duration.is_open_reassessment &&
      duration.assessment_type === ASSESSMENT_TYPE_UNIFIED_ASSESSMENT &&
      duration.is_unified_assessment_free_reassessment ===
        UNIFIED_ASSESSMENT_STATUS_DISABLE &&
      ![
        INSPECTION_REQUEST_STATUS_NEW,
        INSPECTION_REQUEST_STATUS_INITIATED,
        INSPECTION_REQUEST_STATUS_ON_PROGRESS,
        INSPECTION_REQUEST_STATUS_COMPLETED,
      ].includes(duration?.inspection_assessment_status?.String);

    const canGoToSelfAssessmentPaymentWithoutUnified =
      !this.props.isReturnedAssessment &&
      duration.state === STATUS_SUBMITTED &&
      duration.is_open_reassessment &&
      duration.assessment_type !== ASSESSMENT_TYPE_UNIFIED_ASSESSMENT &&
      ![
        INSPECTION_REQUEST_STATUS_NEW,
        INSPECTION_REQUEST_STATUS_INITIATED,
        INSPECTION_REQUEST_STATUS_ON_PROGRESS,
        INSPECTION_REQUEST_STATUS_COMPLETED,
      ].includes(duration?.inspection_assessment_status?.String);

    const canCancelInspection =
      !this.props.isReturnedAssessment &&
      duration.state === STATUS_ENABLED &&
      !duration.is_free_assessment &&
      duration.tamam_inspection_status ===
        TAMAM_INSPECTION_STATUS_ONLY_INSPECTION;

    const canCreateInspection =
      !this.props.isReturnedAssessment &&
      duration.state === STATUS_ENABLED &&
      !duration.is_free_assessment &&
      duration.tamam_inspection_status === TAMAM_INSPECTION_STATUS_BOTH;

    const canGoToSelfAssessmentPaymentWithInspection =
      duration.state === STATUS_ENABLED &&
      !duration.is_free_assessment &&
      (duration.tamam_inspection_status === TAMAM_INSPECTION_STATUS_BOTH ||
        duration.tamam_inspection_status ===
          TAMAM_INSPECTION_STATUS_ONLY_ASSESSMENT);

    const canGoToGracePeriodPayment =
      !this.props.isReturnedAssessment &&
      duration.assessment_type === ASSESSMENT_TYPE_BASIC &&
      duration.state === STATUS_SUBMITTED &&
      duration.is_detailed_report &&
      gracePeriodStatus;

    const canGoToBranchesFastTrack =
      !this.props.isReturnedAssessment &&
      duration.status === STATUS_ACTIVE &&
      duration.state === STATUS_SUBMITTED &&
      ![
        INSPECTION_REQUEST_STATUS_NEW,
        INSPECTION_REQUEST_STATUS_INITIATED,
        INSPECTION_REQUEST_STATUS_ON_PROGRESS,
        INSPECTION_REQUEST_STATUS_COMPLETED,
      ].includes(duration?.inspection_assessment_status?.String) &&
      establishmentProfile.main_branch_sequence_id &&
      establishmentProfile.main_branch_labor_office_id &&
      duration.fast_track_branches_status !== STATUS_NOT_PAID;

    const canGoToBranchesFastTrackPayment =
      !this.props.isReturnedAssessment &&
      duration.status === STATUS_ACTIVE &&
      duration.state === STATUS_SUBMITTED &&
      ![
        INSPECTION_REQUEST_STATUS_NEW,
        INSPECTION_REQUEST_STATUS_INITIATED,
        INSPECTION_REQUEST_STATUS_ON_PROGRESS,
        INSPECTION_REQUEST_STATUS_COMPLETED,
      ].includes(duration?.inspection_assessment_status?.String) &&
      duration.fast_track_branches_status === STATUS_NOT_PAID;

    const canReviewRetuenedAssessment = this.props.isReturnedAssessment;
    return [
      {
        id: 1,
        idName: "start-evaluation-button",
        isVisible: canGoToAssessmentPage,
        label: "ابدأ التقييم",
        action: this.redirectToEstablishmentAssessmentPage,
      },

      {
        id: 2,
        idName: "self-assessment-button",
        isVisible: canGoToSelfAssessmentPayment,
        label: "التقييم الذاتي",
        action: this.redirectToSelfAssessmentPaymentAdditionalAssessment,
      },
      {
        id: 8,
        idName: "view-result-button",
        isVisible: canGoToSelfAssessmentResult,
        label: "استعراض",
        action: this.redirectToSelfAssessmentResult,
      },
      {
        id: 3,
        idName: "re-assessment-button",
        isVisible: canReopenAssessment,
        label: "إعادة التقييم",
        action: this.isReopenAssessment,
      },
      {
        id: 4,
        idName: "re-assessment-button",
        isVisible: canGoToSelfAssessmentPaymentWithUnified,
        label: "إعادة التقييم",
        action: this.redirectToSelfAssessmentPayment,
      },
      {
        id: 5,
        idName: "re-assessment-button",
        isVisible: canGoToSelfAssessmentPaymentWithoutUnified,
        label: "إعادة التقييم",
        action: this.redirectToSelfAssessmentPayment,
      },
      {
        id: 6,
        idName: "detailed-report-button",
        isVisible: canGoToDetailedPaymentReport,
        label: "التقرير المفصل",
        action: this.redirectToDetailedReportPayment,
      },

      {
        id: 7,
        idName: "fast-track-button",
        isVisible: canGoToFastTrackPayment,
        label: "المسار المميز",
        action: this.redirectToFastTrackPayment,
      },
      {
        id: 15,
        idName: "branches-fast-track-button",
        isVisible: canGoToBranchesFastTrack,
        label: "المسار المميز للفروع",
        action: this.redirectToFastTrackForBranchesPage,
      },
      {
        id: 15,
        idName: "branches-fast-track-button",
        isVisible: canGoToBranchesFastTrackPayment,
        label: "المسار المميز للفروع",
        action: this.redirectToFastTrackForBranchesPayment,
      },
      {
        id: 10,
        idName: "unified-assessment-button",
        isVisible: canToUnifiedAssessmentPayment,
        label: "التقييم الموحد",
        action: this.redirectToUnifiedAssessmentPayment,
      },

      {
        id: 11,
        idName: "unified-assessment-button",
        isVisible: canGoToEstablishmentUnifiedAssessmentPage,
        label: "التقييم الموحد",
        action: this.redirectToEstablishmentUnifiedAssessmentPage,
      },

      {
        id: 9,
        idName: "view-certificate-button",
        isVisible: canShowCertificate,
        label: "عرض الشهاده",
        action: this.showCertificate,
      },

      {
        id: 12,
        idName: "cancel-inspection-button",
        isVisible: canCancelInspection,
        label: "الغاء التفتيش",
        action: this.isCancelInspectionRequest,
      },
      {
        id: 13,
        idName: "inspection-button",
        isVisible: canCreateInspection,
        label: "تفتيش ميداني",
        action: this.isCreatingInspectionRequest,
      },
      {
        id: 14,
        idName: "grace-period-button",
        isVisible: canGoToGracePeriodPayment,
        label: "شهادة المهلة التصحيحية",
        action: this.redirectToGracePeriodPayment,
      },

      {
        id: 16,
        idName: "audit-report-button",
        isVisible: canShowCertificate,
        label: "تقرير شهادة الأمتثال",
        action: this.showAuditReport,
      },
      {
        id: 17,
        idName: "review-returned-assessment",
        isVisible: canReviewRetuenedAssessment,
        label: "مراجعة النموذج",
        action: this.props.goToReturnedAssessment,
      },
    ];
  }

  render() {
    const {
      duration,
      textAlign,
      label,
      profileId,
      ignoreActions,
      id,
      durationIndex,
      iconLeft,
    } = this.props;

    if (
      duration.state === STATUS_CANCELLED ||
      duration.state === STATUS_TIMEOUT
    ) {
      return null;
    }

    const actions = this.getActions().filter(
      (item) => item.isVisible && !ignoreActions.includes(item.id)
    );

    return (
      <div ref={this.setDropdownRef} className="dropdown-action-menu">
        {label && actions?.length === 1 && (
          <Button
            onClick={actions[0]?.action}
            text={actions[0]?.label}
            style={{ marginRight: "1rem" }}
            id={actions[0]?.idName}
          />
        )}

        {label && id && actions?.length > 1 && (
          <Button
            text={label}
            onClick={this.toggle}
            style={{
              marginRight: "1rem",
              paddingRight: "22px",
              paddingLeft: "0px",
              minWidth: "131px",
            }}
            id={id}
            iconLeft={iconLeft}
            buttonIconCustomStyle="arrow-dropDwon-button-icon"
          />
        )}

        {!label && actions && !!actions.length && (
          <button
            onClick={this.toggle}
            className="dropdown-action-menu__button-icon flex items-center justify-center"
            id={`duration-${durationIndex}-button`}
          >
            <DotsIcon />
          </button>
        )}

        {this.state.isOpen && (
          <div className="dropdown-action-menu__container">
            <ul className="dropdown-action-menu__list">
              {actions.map((item) => (
                <li
                  id={item.idName}
                  key={item.id}
                  onClick={() => {
                    this.hide();
                    item.action();
                  }}
                  className={`dropdown-action-menu__list-item dropdown-action-menu__list-item--${textAlign}`}
                >
                  <Text Tag="span" textType="h9">
                    {item.label}
                  </Text>
                </li>
              ))}
            </ul>
          </div>
        )}

        <PopUp
          isOpen={this.state.isReopenAssessment}
          title="إعادة التقييم"
          body="سيتم فتح فترة تقييمية جديده، هل تريد اعادة التقييم لهذة المنشأة؟"
          onClickAction={() => {
            const effectivePeriodId = duration.period_id || duration.id;
            const effectiveAssessmentId =
              duration.assessment_id || duration.assessment?.id;
            this.createReopenAssessmentRequest(
              profileId,
              effectivePeriodId,
              effectiveAssessmentId,
              duration.assessment_version,
              duration.period_assessment_id
            );
            this.isReopenAssessment();
          }}
          actionButtonText="تأكيد"
          onClickCancel={() => this.isReopenAssessment()}
          cancelButtonText="إلغاء"
        />

        <PopUp
          isOpen={this.state.isCancelInspectionRequest}
          title="تفتيش ميداني"
          body="هل تريد الغاء طلب التفتيش الميداني لهذه المنشأة؟"
          onClickAction={() => {
            this.cancelInspectionRequest(duration.inspection_id.UUID);
            this.isCancelInspectionRequest();
          }}
          actionButtonText="تأكيد"
          onClickCancel={this.isCancelInspectionRequest}
          cancelButtonText="إلغاء"
        />
        <PopUp
          isOpen={this.state.isCreatingInspectionRequest}
          title="تفتيش ميداني"
          body=" تمكنك هذه الخدمة من طلب تفتيش ميداني من قبل وزارة الموارد البشرية
          والتنمية الاجتماعية لتقييم المنشأة، حيث يتم جدولة الزيارة بحسب
          الفترة المتاحة. للإنتهاء من التقييم الذاتي بشكل أسرع يمكنك البدء
          بالتقييم."
          onClickAction={() => {
            this.createInspectionRequest();
            this.isCreatingInspectionRequest();
          }}
          actionButtonText="تأكيد"
          onClickCancel={this.isCreatingInspectionRequest}
          cancelButtonText="إلغاء"
        />

        <PopUp
          isOpen={this.state.isShowAuthorizationPopUp}
          title="المسار المميز"
          body="المنشأة الحاليه ليس لها صلاحية التقديم على المسار مميز"
          onClickDismiss={() =>
            this.setState({ isShowAuthorizationPopUp: false })
          }
          dismissButtonText="إغلاق"
        />
        <PopUp
          isOpen={this.state.isShowAuthorizationBranchesPopUp}
          title="المسار المميز لأفرع"
          body="المنشأة الحاليه ليس لها صلاحية التقديم على المسار المميز للأفرع"
          onClickDismiss={() =>
            this.setState({ isShowAuthorizationBranchesPopUp: false })
          }
          dismissButtonText="إغلاق"
        />
      </div>
    );
  }
}

DropdownAction.defaultProps = {
  textAlign: "center",
  ignoreActions: [],
  showGracePeriod: false,
};

DropdownAction.propTypes = {
  duration: PropTypes.object.isRequired,
  profileId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  label: PropTypes.string,
  fetchFastTrackResult: PropTypes.func.isRequired,
  createReopenAssessmentRequest: PropTypes.func.isRequired,
  createInspectionRequest: PropTypes.func.isRequired,
  cancelInspectionRequest: PropTypes.func.isRequired,
  textAlign: PropTypes.oneOf(["center", "left", "right"]),
  ignoreActions: PropTypes.array,
  fetchGracePeriodStatus: PropTypes.func.isRequired,
  showGracePeriod: PropTypes.bool,
  gracePeriodStatus: PropTypes.bool,
  establishmentProfile: PropTypes.object.isRequired,
  auditReport: PropTypes.object,
  id: PropTypes.string,
  durationIndex: PropTypes.number,
  fetchFastTrackServiceAuthorization: PropTypes.func.isRequired,
  saveSelectedDuration: PropTypes.func,
  setTrackType: PropTypes.func,
};
const mapStateToProps = (state) => ({
  gracePeriodStatus: state.unified.gracePeriodStatus,
  establishmentProfile: state.establishment.establishmentProfile,
  auditReport: state.certificate.auditReport,
  SAform: state.SAform,
});
const mapDispatchToProps = (dispatch) => ({
  cancelInspectionRequest: (establishmentId, inspection_id) =>
    dispatch(cancelInspectionRequest(establishmentId, inspection_id)),
  createInspectionRequest: (
    profileId,
    periodId,
    assessmentId,
    assessmentVersion
  ) =>
    dispatch(
      createInspectionRequest(
        profileId,
        periodId,
        assessmentId,
        assessmentVersion
      )
    ),
  createReopenAssessmentRequest: (
    establishmentId,
    periodId,
    assessmentId,
    assessmentVersion,
    periodAssessmentId
  ) =>
    dispatch(
      createReopenAssessment(
        establishmentId,
        periodId,
        assessmentId,
        assessmentVersion,
        periodAssessmentId
      )
    ),
  fetchFastTrackResult: (
    establishmentId,
    periodId,
    assessmentId,
    assessmentVersion,
    isShowCertificate
  ) =>
    dispatch(
      certificateActions.fetchFastTrackResult(
        establishmentId,
        periodId,
        assessmentId,
        assessmentVersion,
        isShowCertificate
      )
    ),
  fetchGracePeriodStatus: (
    establishmentId,
    periodId,
    assessmentId,
    assessmentVersion
  ) =>
    dispatch(
      unifiedActions.fetchGracePeriodStatus(
        establishmentId,
        periodId,
        assessmentId,
        assessmentVersion
      )
    ),
  reset: () => dispatch(unifiedActions.reset()),
  fetchFastTrackServiceAuthorization: (
    establishmentId,
    durationId,
    serviceType
  ) =>
    dispatch(
      fastTrackActions.fetchFastTrackServiceAuthorization(
        establishmentId,
        durationId,
        serviceType
      )
    ),
  saveSelectedDuration: (duration) => dispatch(saveSelectedDuration(duration)),
  setTrackType: (trackType) => dispatch(setTrackType(trackType)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DropdownAction));
