import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import * as certificateActions from "../../actions/certificateActions";
import QRCode from "qrcode.react";

import MOLLogo from "../../../public/img/MHRSD-Logo-certificate.svg";
// eslint-disable-next-line import/default
import IMSLogo from "../../../public/img/IMS-Logo-certificate.svg";

import "./certificate.scss";
import PrintStyle from "../PrintStyle/PrintStyle";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import { PdfCertificate } from "../../../../shared-components/my-scope/pages/pdf-certificate";

export class Certificate extends React.Component {
  constructor() {
    super();

    this.print = this.print.bind(this);
    this.getCertificateImage = this.getCertificateImage.bind(this);
  }

  componentDidMount() {
    document.body.classList.add("noscroll");
  }

  componentWillUnmount() {
    document.body.classList.remove("noscroll");
  }

  print() {
    const { reports, auditReport } = this.props;

    PdfCertificate(
      reports.establishment.establishment_name,
      auditReport.auditing_score,
      auditReport.certificate_number,
      moment(auditReport.certificate_end_date.String).format("YYYY-MM-DD")
    );
  }

  getCertificateImage(score) {
    if (score === 100) {
      return (
        <img
          src={"img/CertificateGold.svg"}
          className="certificate-logo"
          alt="Certificate Logo"
        />
      );
    }

    if (score >= 80) {
      return (
        <img
          src={"img/certificateSilver.svg"}
          className="certificate-logo"
          alt="Certificate Logo"
        />
      );
    }

    return (
      <img
        src={"img/certificateFail.svg"}
        className="certificate-logo"
        alt="Certificate Logo"
      />
    );
  }

  render() {
    const {
      hideCertificate,
      reports,
      auditReport,
      isLoading,
      certificateViewHandler,
    } = this.props;

    if (isLoading) {
      return null;
    }
    const isCertified = auditReport.certificate_status === "Certified";

    return (
      <div className="certificate">
        <PrintStyle
          size="landscape"
          styles={`
            .certificate-content-wrapper {
              width: 100% !important;
              height: 100% !important;
              max-width: unset !important;
            }
          `}
        />
        <header className="certificate-menu doNotPrint">
          <div className="certificate-menu-group">
            <div className="certificate-file">
              <i className="fa fa-file-o certificate-file__icon"></i>
              <Text Tag="span" textType="h9">
                {isCertified ? "  شهادة الامتثال" : "  إشعار نتيجة التدقيق"}
                {auditReport.certificate_name === "لا توجد"
                  ? ""
                  : ` - ${auditReport.certificate_name}`}
              </Text>
            </div>
          </div>
          <div className="certificate-menu-group">
            <div onClick={this.print} className="certificate-download">
              <i className="fa fa-download certificate-download__icon"></i>
              <Text Tag="span" textType="h9">
                تحميل
              </Text>
            </div>
            <div onClick={hideCertificate} className="certificate-close">
              ×
            </div>
          </div>
        </header>
        <div className="certificate-window">
          <div className="certificate-content-wrapper">
            <div className="certificate-content">
              <header className="certificate-header">
                <img
                  src={MOLLogo}
                  alt="MOL Logo"
                  className="certificate-mol-logo"
                />
                <img
                  src={IMSLogo}
                  alt="IMS Logo"
                  className="certificate-ims-logo"
                />
              </header>
              <main className="certificate-main">
                <Text
                  className="certificate-header-text"
                  textType="bold"
                  Tag="div"
                  fontSize={"font-30"}
                >
                   {isCertified ? "  شهادة الامتثال" : "  إشعار نتيجة التدقيق"}
                </Text>

                <Text
                  textType="roman"
                  className="certificate-text"
                  fontSize={"font-22"}
                >
                  {isCertified
                    ? "تمنح وزارة الموارد البشرية والتنمية الاجتماعية هذه الشهادة لشركة"
                    : "تمنح وزارة الموارد البشرية والتنمية الاجتماعية هذا الإشعار لشركة"}
                </Text>
                <Text
                  textType="bold"
                  className="certificate-company-name"
                  fontSize={"font-22"}
                >
                  {reports.establishment.establishment_name}
                </Text>
                <Text
                  textType="roman"
                  className="certificate-text"
                  fontSize={"font-22"}
                >
                  {isCertified
                    ? ` وذلك لامتثالها لقوانين وأنظمة الوزارة بنسبة ${auditReport.auditing_score}%`
                    : "وذلك لعدم اجتيازها النسبة المطلوبة للحصول على شهادة الامتثال، علماً"}
                </Text>
                {!isCertified && (
                  <Text
                    textType="roman"
                    className="certificate-text"
                    fontSize={"font-22"}
                  >
                    بأن الحد الأدنى للحصول على الشهادة هو 80%
                  </Text>
                )}
                <div className="certificate-logo-wrapper">
                  {this.getCertificateImage(auditReport.auditing_score)}
                </div>
              </main>
              <footer className="certificate-footer">
                <div>
                  <Text
                    textType="roman"
                    className="certificate-small-text"
                    fontSize={"font-10"}
                  >
                    رقم الشهادة {auditReport.certificate_number}
                  </Text>
                  <Text
                    textType="roman"
                    className="certificate-small-text"
                    fontSize={"font-10"}
                  >
                    تاريخ انتهاء الشهادة {""}
                    {moment(auditReport.certificate_end_date.String).format(
                      "YYYY-MM-DD"
                    )}
                  </Text>
                </div>
                <div className="certificate-qrcode">
                  <QRCode
                    value={auditReport.certificate_number}
                    size={64}
                    bgColor={"#fff"}
                    fgColor={"#000"}
                    level={"L"}
                    includeMargin={false}
                    renderAs={"png"}
                    id="qrcode"
                  />
                  <Text
                    textType="roman"
                    className="certificate-small-text"
                    fontSize={"font-10"}
                  >
                    التحقق من صحة الشهادة
                  </Text>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Certificate.defaultProps = {};

Certificate.propTypes = {
  auditReport: PropTypes.object.isRequired,
  reports: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hideCertificate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auditReport: state.certificate.auditReport,
  reports: state.certificate.reports,
  isLoading: state.certificate.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  hideCertificate: () => dispatch(certificateActions.hideCertificate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Certificate);
