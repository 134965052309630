import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import "./assessment.scss";
import Summary from "./Summary";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import { loadEstablishmentCurrentSpecialAssessments } from "../../../actions/establishmentAssessmentActions";
import { useDispatch, useSelector } from "react-redux";
import {
  RE_SUBMIT_STATUS,
  ON_HOLD,
} from "../../../common/constants/assessmentStatuses";
import Message from "./Message";
import Lottie from "react-lottie";
import * as animationLoader from "../../../../public/animation/loading.json";
import { FASTTRACK } from "../../../common/constants/paymentStatuses";
import FastTrackCard from "./FastTrackCard";
import fasttrackbuilding from "../../../../public/img/fast-track-building.svg";
import fasttrackcertificate from "../../../../public/img/fast-track-certificate.svg";
import { ReAssessmentCard } from "./ReAssessmentCard";
import Certificate from "../../../components/Certificate/Certificate";
import { fetchFastTrackResult } from "../../../actions/certificateActions";

const FastTrackAssessment = (props) => {
  const dispatch = useDispatch();
  const establishmentProfile = useSelector((state) => {
    return state.establishment.establishmentProfile;
  });
  const isCertificateVisible = useSelector((state) => {
    return state.certificate.isVisible;
  });

  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrentSpecialAssessment] = useState(null);
  const [haveActivePeriod, setHaveActivePeriod] = useState(false);

  useEffect(() => {
    if (establishmentProfile.id) {
      setIsLoading(true);
      dispatch(
        loadEstablishmentCurrentSpecialAssessments(establishmentProfile.id)
      ).then((result) => {
        setCurrentSpecialAssessment(result.spacialTrack);
        setHaveActivePeriod(result.spacialTrack.have_active_period);
        setIsLoading(false);
      });
    }
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // is period active ?  الفتره غير متاحه
  const isPeriodActive = haveActivePeriod ? haveActivePeriod : false;
  const allowToContinue =
    !haveActivePeriod &&
    (current?.assessment_status === "Partially Filled" ||
      current?.assessment_status === "Empty");
  // is the basic assessment already submited ? اكمل النموذج الاول
  const isBasicAssessmentComplited =
    current?.basic_assessment_status === "Completed" ||
    current?.basic_assessment_status === "UnderGracePeriod" ||
    current?.basic_assessment_status === "UnderInspection";
  // is the user allowed to request to purchase the spacial track ? طلب الخدمه
  function isPaid() {
    if (isPeriodActive && isBasicAssessmentComplited) {
      if (!current.is_paid) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  const isSpecialTrackPurchaseAllowed = isPaid();
  // is the auditor returned the assessment to the establishment ? معاد للتعديل
  const isReturnedAssessment =
    isPeriodActive &&
    (current?.audit_status === RE_SUBMIT_STATUS ||
      current?.audit_status === ON_HOLD);
  const assessmentCompleted =
    current?.assessment_status === "Completed" ||
    current?.assessment_status === "UnderGracePeriod" ||
    current?.assessment_status === "UnderInspection";

  const auditingCompleted =
    current?.audit_status === "Completed" ||
    current?.audit_status === "UnderGracePeriod" ||
    current?.audit_status === "UnderInspection";

  // is the special track assessment ready to view نتيجة التقييم
  const isCompleted = current && !isReturnedAssessment && assessmentCompleted;
  // is the special track assessment not completed by the auditor
  const isUnderAuditing =
    current &&
    !auditingCompleted  &&
    assessmentCompleted  &&
    isPeriodActive;

  // is the special track Partially Filled? اكمل التقييم
  const isContinueAssessment =
    (isPeriodActive && current?.assessment_status === "Partially Filled") ||
    (!isPeriodActive && current?.assessment_status === "Partially Filled");
  // is the special track assessment ready for self-assessment بدء التقييم
  const isSpecialReadyToStart =
    (isPeriodActive &&
      isBasicAssessmentComplited &&
      current.is_paid &&
      current?.assessment_status === "Empty") ||
    (!isPeriodActive && current?.assessment_status === "Empty");
  const isReassessmentFastTrack =
    isPeriodActive && current?.type === "Re-Assessment";
  const isReAssessmentSubmitted =
    current && isReassessmentFastTrack && isCompleted;

  const onClickButton = () => {
    const { history } = props;
    if (isSpecialReadyToStart || isContinueAssessment) {
      history.push(
        `/establishmentAssessmentInstruction/${current.period_id}/${current.assessment_version}/false/${current.period_assessment_id}`
      );
    } else if (isSpecialTrackPurchaseAllowed) {
      const withoutResult = true;
      history.push({
        pathname: `/establishmentAssessmentPayment/${current.period_id}/${current.assessment_id}/${establishmentProfile.id}/${current.assessment_version}/${withoutResult}/Fast-track/${current.period_assessment_id}`,
        state: { trackType: "special" },
      });
      history.push(
        `/establishmentAssessmentPayment/${current.period_id}/${current.assessment_id}/${establishmentProfile.id}/${current.assessment_version}/${withoutResult}/Fast-track/${current.period_assessment_id}`
      );
    } else if (isCompleted || isUnderAuditing || isReAssessmentSubmitted)
      history.push(
        `/establishmentAssessmentResult/${current.period_id}/${current.assessment_id}/${establishmentProfile.id}/${current.assessment_version}`
      );
  };

  const reassessmentLink = useMemo(() => {
    if (current && establishmentProfile) {
      return `/establishmentAssessmentPayment/${current?.period_id}/${
        current?.assessment_id
      }/${establishmentProfile?.profileId}/${
        current?.assessment_version
      }/${false}/${FASTTRACK}/${current?.period_assessment_id}`;
    }
    return undefined;
  }, [current, establishmentProfile]);
  const isCertified = auditingCompleted && current?.certificate_status === "Certified";
  const isNotCertified = auditingCompleted && current?.certificate_status === "NotCertified";
  const certificateViewHandler = () => {
    dispatch(
      fetchFastTrackResult(
        establishmentProfile.id,
        current.period_id,
        current.assessment_id,
        current.assessment_version,
        true
      )
    );
  };
  if (isLoading) {
    return (
      <PageWrapper withBreadcrumbs>
        <div className="loader-div">
          <Lottie options={defaultOptions} height={160} width={160} />
          <p className="assessment-submission-loading-text">
            يرجى الانتظار..
          </p>
        </div>
      </PageWrapper>
    );
  } else if (
    haveActivePeriod === false ||
    (!haveActivePeriod && !allowToContinue)
  ) {
    return (
      <PageWrapper>
        <div
          style={{ marginRight: "2rem", marginLeft: "2rem", marginTop: "1rem" }}
        >
          <h3 className={"text-2xl text-[#14415A] font-mol font-bold"}>
            {"المسار المميز"}
          </h3>
          <div
            className={
              "mt-4 flex flex-col border border-[#E2E2EA] bg-white rounded w-full h-full flex-1 items-center justify-center min-h-[703px]"
            }
          >
            <img
              src="/img/EmptyStateList.svg"
              alt="empty state list"
              className={"object-fill object-center"}
            />
            <div className={"mt-10"}>
              <h3
                className={
                  "text-base font-mol font-semibold text-[#14415A] leading-relaxed max-w-xl text-center"
                }
              >
                {
                  "عذرا، هذه الخدمة غير متاحة حاليا، يمكنك الاستفادة من هذه الخدمة قريبًا عند بداية الفترة التقييمية الجديدة"
                }
              </h3>
            </div>
          </div>
        </div>
      </PageWrapper>
    );
  } else {
    return (
      <React.Fragment>
        <PageWrapper>
          <div
            style={{
              marginRight: "2rem",
              marginLeft: "2rem",
              marginTop: "1rem",
            }}
          >
            <Text Tag="div" textType="h5" isBold className="assessment-title">
              المسار المميز
            </Text>
            <div className="assessment-card">
              {(isReturnedAssessment ||
                (isReassessmentFastTrack && !isCompleted && !isUnderAuditing) ||
                (!isBasicAssessmentComplited && !allowToContinue) ||
                (isCompleted && !isUnderAuditing)) && (
                <div className="assessment-message">
                  <Message
                    isReturnedAssessment={isReturnedAssessment}
                    isBasicTrackNotComplete={!isBasicAssessmentComplited}
                    isCompletedAndCertified={isCompleted && isCertified}
                    isCompletedAndNotCertified={isCompleted && isNotCertified}
                    isReassessment={isReassessmentFastTrack}
                  />
                </div>
              )}
              <Summary
                isReturnedAssessment={isReturnedAssessment}
                isUnderAuditing={isUnderAuditing}
                isCompleted={isCompleted}
                isFastTrackAssessmentPayment={
                  (isPeriodActive && isBasicAssessmentComplited) ||
                  allowToContinue
                }
                isFastTrackAssessmentAvailable={
                  (isPeriodActive &&
                    isBasicAssessmentComplited &&
                    current.is_paid) ||
                  allowToContinue
                }
                onClickButton={onClickButton}
                isGoToFastTrackAssessment={isSpecialReadyToStart}
                isReassessment={isReassessmentFastTrack}
                isReAssessmentSubmitted={isReAssessmentSubmitted}
                isFastTrackAssessment={true}
                isContinueAssessment={isContinueAssessment}
                isCompletedAndCertified={isCompleted && isCertified}
                isCompletedAndNotCertified={isCompleted && isNotCertified}
                onViewCertificate={certificateViewHandler}
              />

              <div
                className="horizontal-line"
                style={{
                  marginTop: 78,
                  marginBottom: 40,
                  borderTop: "2px solid #47478612",
                  width: "100%",
                }}
              ></div>
              <div className="fast-track-card">
                <FastTrackCard
                  title="أهم مميزات شهادة الامتثال"
                  content={[
                    "عدم وجود زيارات تفتيشية طوال مدة الشهادة ما لم يكن هناك شكوى أو بلاغ ",
                    " رفع نسبة الإمتثال للمنشآت ",
                    " زيادة الوعي لدى أصحاب المنشآت ومفوضيها ",
                    " حماية المنشآت من الوقوع في المخالفات ",
                    " زيادة الوعي لدى أصحاب المنشآت ومفوضيها ",
                    " إتاحة الفرصة للقطاع الخاص بالإستفادة من الدور الإستشاري للمدققين.",
                  ]}
                />
                <FastTrackCard
                  title="شروط الحصول على شهادة الامتثال"
                  content={[
                    "إكمال التقييم الذاتي ",
                    "  عدم وجود اي مخالفة جسيمة و عالية الجسامة",
                    " أن لا تقل نسبة الامتثال عن 80% ",
                  ]}
                  image={fasttrackbuilding}
                />
                <FastTrackCard
                  title="فئات شهادة لامتثال"
                  subtitle={["الفئة الذهبية", "الفئة الفضية "]}
                  description={[
                    "مدة صلاحيتها سنة إذا كانت نتيجة التقييم %100",
                    "مدة صلاحيتها 6 أشهر إذا كانت نتيجة التقييم تتراوح بين",
                    "(%80 - %99)",
                  ]}
                  image={fasttrackcertificate}
                  height="180px"
                />
              </div>
            </div>
          </div>
        </PageWrapper>
        {isCertificateVisible && <Certificate />}
      </React.Fragment>
    );
  }
};
FastTrackAssessment.propTypes = {};

export default FastTrackAssessment;
