import { Stack } from "@mui/material";
import React from "react";

const styles = {
  padding: 4,
  backgroundColor: "#F6F9FBB3",
  border: "1px solid #14828726",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  borderRadius: 1,
};

export const StackContainer = ({ children }) => {
  return (
    <Stack sx={styles} spacing={2}>
      {children}
    </Stack>
  );
};
