import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import {
  createTraining,
  fetchTraining,
  updateTraining,
} from "../../actions/trainingActions";
import * as TrainingSubjectActions from "../../actions/trainingSubjectActions";

import { listFormattedForDropdown } from "../../selectors/selectors";

import toastr from "toastr";
import EstablishmentTrainingCourseDetails from "./EstablishmentTrainingCourseDetails";
import PageWrapper from "../PageWrapper/PageWrapper";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import "./manageEstablishmentTrainingPage.scss";
import { STATUS_REGISTER } from "../../common/constants/trainingStatuses";
import EstablishmentTrainingUserDetails from "./EstablishmentTrainingUserDetails";
import Loader from "../Loader/Loader";

export const emptyTraining = {
  id: "",
  email: "",
  address: "",
  subject_id: "",
  mobile: "",
  status: "",
};
export const emptyTrainingSubject = {
  id: "",
  name: "",
  descripion: "",
  details: "",
};

export class ManageEstablishmentTrainingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      training: { ...emptyTraining },
      trainingSubject: { ...emptyTrainingSubject },
      errors: {},
      tabSelected: 1,
    };

    this.saveTraining = this.saveTraining.bind(this);
    this.updateTrainingState = this.updateTrainingState.bind(this);
    this.searchTrainingCategories = this.searchTrainingCategories.bind(this);
    this.reset = this.reset.bind(this);
    this.redirectToEditTraining = this.redirectToEditTraining.bind(this);
  }

  componentDidMount() {
    const { isLogin } = this.props;
    const { id, establishment_id, status } = this.props.match.params;
    if (!isLogin) {
      window.location = process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
      window.location.reload();
    }
    if (id && status === STATUS_REGISTER) {
      this.props.onFetchTrainingSubject(establishment_id, id);
    } else if (id) {
      this.props.onFetchTraining(establishment_id, id);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.training, this.props.training)) {
      this.setState({ training: this.props.training || emptyTraining });
    }
    if (!isEqual(prevProps.trainingSubject, this.props.trainingSubject)) {
      this.setState({
        trainingSubject: this.props.trainingSubject || emptyTrainingSubject,
      });
    }

    if (prevProps.trainingCreating && !this.props.trainingCreating) {
      if (!this.props.trainingCreatingError) {
        toastr.success("تم إضافة طلب التدريب بنجاح.");
        this.redirect();
      } else {
        toastr.error(this.props.trainingCreatingError.reason);
      }
    }

    if (prevProps.trainingUpdating && !this.props.trainingUpdating) {
      if (!this.props.trainingUpdatingError) {
        toastr.success("تم إضافة طلب التدريب بنجاح.");
        this.redirect();
      } else {
        toastr.error(this.props.trainingUpdatingError.reason);
      }
    }
  }

  updateTrainingState(event) {
    const re = /^[0-9\b]+$/;
    const field = event.target.name;
    let training = { ...this.state.training }; // prevent mutating

    switch (field) {
      case "subject_id": {
        training.subject_id = event.target.value;
        break;
      }

      default: {
        training[field] = event.target.value;
      }
    }
    return this.setState({ training: training });
  }

  saveTraining(trainingInfo) {
    const establishment_id = this.props.match.params.establishment_id;
    if (this.props.match.params.id) {
      this.props.onUpdateTraining(establishment_id, trainingInfo);
    } else {
      this.props.onCreateTraining(establishment_id, trainingInfo);
    }
  }

  reset() {
    if (this.props.match.params.id) {
      this.setState({ training: this.props.training });
    } else {
      this.setState({ training: { ...emptyTraining } });
    }
  }

  // for saerch loading
  searchTrainingCategories(input) {
    // should used action for search
    this.props.onGetTrainingCategories();
  }

  redirect() {
    this.props.history.push("/establishmentTrainings/trainings");
  }
  redirectToEditTraining() {
    const { training, trainingSubject } = this.state;
    const { status } = this.props.match.params;
    this.props.history.push(
      `/establishmentTrainingRegister/${this.props.match.params.establishment_id}/${this.props.match.params.id}/${status}`
    );
  }
  handleChange = (event, newValue) => {
    this.setState({ tabSelected: newValue });
  };
  render() {
    const { trainingSubject, training, isEditUserInfo } = this.state;
    const { trainingFetching, trainingSubjectFetching } = this.props;
    const { status } = this.props.match.params;
    const isStatusRegister = status === STATUS_REGISTER;
    const trainingName = isStatusRegister
      ? trainingSubject.name
      : training.subject_name;
    return (
      <PageWrapper
        withBreadcrumbs
        breadcrumbLabel={training.name}
        overrideStyle={status !== STATUS_REGISTER ? { padding: "0" } : {}}
      >
        <div className="establishment-training-page">
          {(isStatusRegister && !trainingSubjectFetching) ||
          (!isStatusRegister && !trainingFetching) ? (
            <Fragment>
              <div
                style={
                  status !== STATUS_REGISTER
                    ? {
                        flex: 1,
                        backgroundColor: "white",
                      }
                    : { display: "flex", flex: 1, flexDirection: "column" }
                }
              >
                <div
                  className={`${
                    status !== STATUS_REGISTER
                      ? "website-page-title-padding"
                      : ""
                  }`}
                >
                  <div className="training-details-header ">
                    <Text
                      Tag="div"
                      isBold
                      textType="h5"
                      className="training-details-header__text"
                    >
                      {trainingName ? trainingName : ""}
                    </Text>
                  </div>
                  {status !== STATUS_REGISTER && (
                    <div className="Panel" style={{ maxWidth: "185px" }}>
                      <nav>
                        <ul className="Tabs">
                          <li
                            className={
                              this.state.tabSelected === 0
                                ? "Tabs__tab  activeTab Tab "
                                : "Tabs__tab Tab"
                            }
                            onClick={(e) => this.handleChange(e, 0)}
                          >
                            <Text Tag="span" textType="h8">
                              بياناتي
                            </Text>
                          </li>
                          <li
                            className={
                              this.state.tabSelected === 1
                                ? " Tabs__tab  activeTab Tab "
                                : " Tabs__tab Tab"
                            }
                            onClick={(e) => this.handleChange(e, 1)}
                          >
                            <Text Tag="span" textType="h8">
                              عن الدورة
                            </Text>
                          </li>

                          <li
                            className="Tabs__presentation-slider"
                            role="presentation"
                          ></li>
                        </ul>
                      </nav>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={
                  status !== STATUS_REGISTER
                    ? {
                        display: "flex",
                        width: "100%",
                        padding: "60px",
                      }
                    : {}
                }
              >
                {this.state.tabSelected === 0 && (
                  <EstablishmentTrainingUserDetails
                    trainingSubject={training}
                  />
                )}
                {this.state.tabSelected === 1 && (
                  <EstablishmentTrainingCourseDetails
                    trainingStatus={status}
                    trainingSubject={
                      isStatusRegister ? trainingSubject : training
                    }
                    onRegisterClick={this.redirectToEditTraining}
                  />
                )}
              </div>
            </Fragment>
          ) : (
            <div className="loader-div">
              <Loader />
            </div>
          )}
        </div>
      </PageWrapper>
    );
  }
}

ManageEstablishmentTrainingPage.propTypes = {
  training: PropTypes.instanceOf(Object),
  trainingSubject: PropTypes.instanceOf(Object),
  trainingCreating: PropTypes.bool.isRequired,
  trainingCreatingError: PropTypes.instanceOf(Object),
  trainingFetching: PropTypes.bool.isRequired,
  trainingFetchingError: PropTypes.instanceOf(Object),
  trainingUpdating: PropTypes.bool.isRequired,
  trainingUpdatingError: PropTypes.instanceOf(Object),
  onCreateTraining: PropTypes.func.isRequired,
  onFetchTraining: PropTypes.func.isRequired,
  onUpdateTraining: PropTypes.func.isRequired,
  onFetchTrainingSubject: PropTypes.func.isRequired,
  isLogin: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

ManageEstablishmentTrainingPage.defaultProps = {
  training: null,
  trainingSubject: null,
  trainingCreatingError: null,
  trainingFetchingError: null,
  trainingUpdatingError: null,
};

const mapStateToProps = (state) => ({
  training: state.trainings.training,
  trainingCreating: state.trainings.trainingCreating,
  trainingCreatingError: state.trainings.trainingCreatingError,
  trainingFetching: state.trainings.trainingFetching,
  trainingFetchingError: state.trainings.trainingFetchingError,
  trainingUpdating: state.trainings.trainingUpdating,
  trainingUpdatingError: state.trainings.trainingUpdatingError,
  isLogin: state.auth.token !== null,
  trainingSubject: state.trainingSubjects.trainingSubject,
  trainingSubjectFetching: state.trainingSubjects.trainingSubjectFetching,
});

const mapDispatchToProps = {
  onCreateTraining: createTraining,
  onFetchTraining: fetchTraining,
  onUpdateTraining: updateTraining,
  onFetchTrainingSubject: TrainingSubjectActions.fetchTrainingSubject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageEstablishmentTrainingPage);
