import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { listFormattedForDropdown } from "../../../../../frontend-admin/src/selectors/selectors";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
// import {
//   STATUSES,
//   STATUS_IS_ACTIVE,
//   STATUS_IS_BLOCKED,
//   getStatusColor,
// } from "../../../common/constants/blockedStatus";
import Button from "../../../components/Button/Button";
import LabeledCheckbox from "../../../components/LabeledCheckBox/LabeledCheckbox";
import LabeledDropDown from "../../../components/LabeledDropDown/LabeledDropDown";
import TextInput from "../../../components/TextInput/TextInput";
import LocationPicker from "../../../components/maps/LocationPicker";
import { AttachmentUploader } from "../AssessmentFeature/self-components/components/attachments/AttachmentUploader";
import "./profile-conent.scss";
export const ProfileContent = ({
  establishmentProfile,
  userInfo,
  validationErrors,
  defaultMapLocation,
  isEdit,
  onEdit,
  onSave,
  onLocationChange,
  onProfileFieldChange,
  OnBlurInput,
  setValue,
  establishmentInfo,
  onCancel,
  isProfileFirstLogin,
  isAddressEditable,
  BanksList,
  handleRemoveFileById,
  handleGetAndUploadFile,
  targetId,
}) => {
  const mapLocationSpecified =
    establishmentProfile.lat.Valid && establishmentProfile.lng.Valid;
  const establishmentMapLocation = {
    lat: establishmentProfile.lat.Float64,
    lng: establishmentProfile.lng.Float64,
  };
  const [dismissMessage, setDismissMesssage] = useState(false);
  const onClose = () => {
    setDismissMesssage(true);
  };
  const isEditAddress = isAddressEditable && isEdit;

  useEffect(() => {
    if (targetId === "bank_form") {
      console.log("scrolling into view ", targetId);
      console.log("sto started");
      const element = document.getElementById(targetId);
      console.log("element", element);
      if (element) {
        console.log("scrolling into view ", element?.scrollIntoView);
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [targetId, isEdit]);

  // useEffect(() => {
  //   return () => {
  //     if (isEdit) onCancel();
  //   };
  // }, [isEdit]);

  return (
    <div className="establishmnet-profile-content">
      {!dismissMessage &&
        isEdit &&
        establishmentInfo.payment_requester_name === "" &&
        establishmentInfo.IBAN_number === "" && (
          <div className="green-info-bar">
            <Text
              Tag="div"
              textType="p5"
              className="green-info-bar-text"
              isBold
            >
              يمكنك إضافة البيانات البنكية الخاصة بالمنشأة من خلال الانتقال إلى
              قسم البيانات البنكية الموجود في آخر الصفحة وتعبئة البيانات
              المطلوبة ثم النقر على "حفظ"
            </Text>
            <div className="close-button-wrapper">
              <button onClick={onClose} aria-label="close">
                <span className="icon" aria-hidden="true">
                  ×
                </span>
              </button>
            </div>
          </div>
        )}
      <div className="establishmnet-profile-header">
        <Text
          Tag="div"
          isBold
          textType="h5"
          className="establishmnet-profile-header__text"
        >
          ملف المنشأة
        </Text>

        <span className="profile-edit-buttons-wrapper  profile-edit-buttons-wrapper__edit">
          {!isEdit && (
            <Button
              primary
              onClick={onEdit}
              text="تعديل"
              style={{
                width: "118px",
                height: "38px",
              }}
            />
          )}
        </span>
      </div>

      <div className="establishment-profile-data-wrapper">
        <div className="establishmnet-profile-data">
          <Card className="establishmnet-profile-card">
            {!dismissMessage && isProfileFirstLogin && (
              <div className="green-info-bar">
                <Text
                  Tag="div"
                  textType="p5"
                  className="green-info-bar-text"
                  isBold
                >
                  يرجى إضافة كل من اسم المنشأة باللغة الانجليزية والرقم الضريبي
                  وتحديد الموقع الجغرافي للمنشأة، مع العلم أن جميع البيانات تم
                  الحصول عليها من وزارة الموارد البشرية والتنمية الاجتماعية، في
                  حال الرغبة بتعديلها يمكنك الانتقال الى صفحة
                  <a
                    href="https://www.mol.gov.sa/SecureSSL/Login.aspx"
                    target="_blank"
                  >
                    إدارة الخدمات الالكترونية
                  </a>
                </Text>
                <div className="close-button-wrapper">
                  <button onClick={onClose} aria-label="close">
                    <span className="icon" aria-hidden="true">
                      ×
                    </span>
                  </button>
                </div>
              </div>
            )}
            <div className="establishmnet-profile-card-wrapper">
              <div className="establishmnet-profile-card-wrapper">
                <div className="establishmnet-profile-card__header ">
                  <div className="establishmnet-profile-card-title-and-button">
                    {!isEdit && (
                      <Text
                        Tag="div"
                        textType="p3"
                        className="blue-title"
                        isBold
                      >
                        {establishmentProfile.establishment_name}
                      </Text>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!isEdit && (
              <div className="establishmnet-profile-card__container">
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    اسم المنشأة باللغة الإنجليزية
                  </Text>
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.english_name}
                  </Text>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    رقم المنشأة
                  </Text>
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.labor_office_id +
                      "/" +
                      establishmentProfile.sequence_id}
                  </Text>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    نشاط المنشأة الحالي
                  </Text>
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.economic_activity_name}
                  </Text>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    نشاط المنشأة من بداية السنة
                  </Text>
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.economic_activity_start_year_name}
                  </Text>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    حجم المنشأة الحالي
                  </Text>
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.size_name}
                  </Text>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    حجم المنشأة من بداية السنة
                  </Text>
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.yearly_size_name === ""
                      ? "لا يوجد"
                      : establishmentProfile.yearly_size_name}
                  </Text>
                </div>

                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    نطاق المنشأة
                  </Text>
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.nitaqat_color}
                  </Text>
                </div>

                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    الرقم الضريبي
                  </Text>
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.tax_number}
                  </Text>
                </div>
                {/* {!isEdit && (
                  <div className="profile-info">
                    <Text
                      Tag="p"
                      textType="p5"
                      className="profile-info__header"
                    >
                      حالة الخدمات الإلكترونية
                    </Text>

                    <Text
                      Tag="p"
                      textType="p5"
                      className={`profile-info__text profile-info__item-status profile-info__item-status--${getStatusColor(
                        establishmentProfile.is_blocked
                      )}`}
                      isBold
                    >
                      {establishmentProfile.is_blocked
                        ? STATUSES[STATUS_IS_BLOCKED]
                        : STATUSES[STATUS_IS_ACTIVE]}
                    </Text>
                  </div>
                )} */}
              </div>
            )}
            {isEdit && (
              <div className="establishmnet-profile-card__container1">
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    اسم المنشأة
                  </Text>

                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      value={establishmentProfile.establishment_name}
                      name="establishment_name"
                      disabled
                    />
                  </div>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    اسم المنشأة باللغة الإنجليزية
                  </Text>

                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      onChange={(e) => {
                        let englishNameValue = onProfileFieldChange(e);
                        setValue("english_name", englishNameValue);
                      }}
                      onBlur={() =>
                        OnBlurInput(
                          "english_name",
                          establishmentInfo.english_name
                        )
                      }
                      placeholder="english name"
                      value={establishmentInfo.english_name}
                      name="english_name"
                      errorText={validationErrors.english_name}
                    />
                  </div>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    رقم المنشأة
                  </Text>
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled
                      value={
                        establishmentProfile.labor_office_id +
                        "/" +
                        establishmentProfile.sequence_id
                      }
                      name="est_num"
                    />
                  </div>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    نطاق المنشأة
                  </Text>

                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled
                      value={establishmentProfile.nitaqat_color}
                      name="nitaqat_color"
                    />
                  </div>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    نشاط المنشأة الحالي
                  </Text>
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled
                      value={establishmentProfile.economic_activity_name}
                      name="economic_activity_name"
                    />
                  </div>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    نشاط المنشأة من بداية السنة
                  </Text>

                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled
                      value={
                        establishmentProfile.economic_activity_start_year_name
                      }
                      name="economic_activity_start_year_name"
                    />
                  </div>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    حجم المنشأة الحالي
                  </Text>

                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled
                      value={establishmentProfile.size_name}
                      name="size_name"
                    />
                  </div>
                </div>
                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    حجم المنشأة من بداية السنة
                  </Text>

                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled
                      value={
                        establishmentProfile.yearly_size_name === ""
                          ? "لا يوجد"
                          : establishmentProfile.yearly_size_name
                      }
                      name="yearly_size_name"
                    />
                  </div>
                </div>

                <div className="profile-info">
                  <Text Tag="p" textType="p5" className="profile-info__header">
                    الرقم الضريبي
                  </Text>

                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      onChange={(e) => {
                        let taxNumValue = onProfileFieldChange(e);
                        setValue("tax_number", taxNumValue);
                      }}
                      onBlur={() =>
                        OnBlurInput("tax_number", establishmentInfo.tax_number)
                      }
                      placeholder="1234567890"
                      value={establishmentInfo.tax_number}
                      name="tax_number"
                      errorText={validationErrors.tax_number}
                      maxLength="15"
                    />
                  </div>
                </div>
              </div>
            )}
          </Card>
          <Card className="establishmnet-profile-card">
            <Text textType="p3" className="establishmnet-profile-card__header">
              بيانات المفوض على المنشأة
            </Text>

            <div className="establishmnet-profile-card__container">
              <div className="profile-info profile-info__user">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  اسم المفوض
                </Text>
                {isEdit ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled
                      value={userInfo.name_ar}
                      name="user_name_ar"
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {userInfo.name_ar}
                  </Text>
                )}
              </div>
              <div className="profile-info profile-info__user">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  رقم الهوية
                </Text>
                {isEdit ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled
                      value={userInfo.id_number}
                      name="user_id_number"
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {userInfo.id_number}
                  </Text>
                )}
              </div>

              <div className="profile-info profile-info__user">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  رقم التواصل
                </Text>
                {isEdit ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled
                      value={
                        userInfo.phone_number
                          ? userInfo.phone_number
                          : "لا يوجد"
                      }
                      name="user_phone_number"
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {userInfo.phone_number ? userInfo.phone_number : "لا يوجد"}
                  </Text>
                )}
              </div>
              <div className="profile-info profile-info__user">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  البريد الالكتروني
                </Text>
                {isEdit ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled={!userInfo.is_email_editable}
                      value={userInfo.email}
                      name="email"
                      errorText={validationErrors.email}
                      onChange={(e) => {
                        onProfileFieldChange(e);
                      }}
                      onBlur={() => OnBlurInput("email", userInfo.email)}
                      placeholder="البريد الإلكتروني"
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {userInfo.email}
                  </Text>
                )}
              </div>
            </div>
          </Card>
          <Card className="establishmnet-profile-card establishmnet-profile-map">
            <Text
              Tag="div"
              textType="p3"
              className="establishmnet-profile-card__header"
            >
              عنوان المنشأة
            </Text>
            <div
              className={`${
                isEditAddress
                  ? "establishmnet-profile-card__container3"
                  : "establishmnet-profile-card__container"
              }`}
            >
              <div className="profile-info ">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  الدولة
                </Text>
                {isEditAddress ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      disabled
                      value={establishmentProfile.address_country || "غير محدد"}
                      name="user_id_number"
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.address_country || "غير محدد"}
                  </Text>
                )}
              </div>
              <div className="profile-info ">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  المنطقة
                </Text>
                {isEditAddress ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      onChange={(e) => {
                        let addressCityValue = onProfileFieldChange(e);
                        setValue("address_city", addressCityValue);
                      }}
                      onBlur={() =>
                        OnBlurInput(
                          "address_city",
                          establishmentInfo.address_city
                        )
                      }
                      placeholder="المنطقة"
                      value={establishmentInfo.address_city}
                      name="address_city"
                      errorText={validationErrors.address_city}
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.address_city || "غير محدد"}
                  </Text>
                )}
              </div>
              <div className="profile-info ">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  الحي
                </Text>
                {isEditAddress ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      onChange={(e) => {
                        let addressDistrictValue = onProfileFieldChange(e);
                        setValue("address_district", addressDistrictValue);
                      }}
                      onBlur={() =>
                        OnBlurInput(
                          "address_district",
                          establishmentInfo.address_district
                        )
                      }
                      placeholder="الحي"
                      value={establishmentInfo.address_district}
                      name="address_district"
                      errorText={validationErrors.address_district}
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.address_district || "غير محدد"}
                  </Text>
                )}
              </div>
              <div className="profile-info ">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  اسم الشارع
                </Text>
                {isEditAddress ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      onChange={(e) => {
                        let addressStreetValue = onProfileFieldChange(e);
                        setValue("address_street", addressStreetValue);
                      }}
                      onBlur={() =>
                        OnBlurInput(
                          "address_street",
                          establishmentInfo.address_street
                        )
                      }
                      placeholder="اسم الشارع"
                      value={establishmentInfo.address_street}
                      name="address_street"
                      errorText={validationErrors.address_street}
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.address_street || "غير محدد"}
                  </Text>
                )}
              </div>

              <div className="profile-info ">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  رقم المبنى
                </Text>
                {isEditAddress ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      onChange={(e) => {
                        let addressBuildingNumberValue =
                          onProfileFieldChange(e);
                        setValue(
                          "address_building_number",
                          addressBuildingNumberValue
                        );
                      }}
                      onBlur={() =>
                        OnBlurInput(
                          "address_building_number",
                          establishmentInfo.address_building_number
                        )
                      }
                      placeholder="رقم المبنى"
                      value={establishmentInfo.address_building_number}
                      name="address_building_number"
                      errorText={validationErrors.address_building_number}
                      maxLength={4}
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.address_building_number || "غير محدد"}
                  </Text>
                )}
              </div>

              <div className="profile-info ">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  الرمز البريدي
                </Text>
                {isEditAddress ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      onChange={(e) => {
                        let addressPostalCodeValue = onProfileFieldChange(e);
                        setValue("address_postal_code", addressPostalCodeValue);
                      }}
                      onBlur={() =>
                        OnBlurInput(
                          "address_postal_code",
                          establishmentInfo.address_postal_code
                        )
                      }
                      placeholder="الرمز البريدي                      "
                      value={establishmentInfo.address_postal_code}
                      name="address_postal_code"
                      errorText={validationErrors.address_postal_code}
                      maxLength={5}
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.address_postal_code || "غير محدد"}
                  </Text>
                )}
              </div>
              <div className="profile-info ">
                <Text Tag="p" textType="p5" className="profile-info__header">
                  الرقم الإضافي
                </Text>
                {isEdit ? (
                  <div className="profile-input ">
                    <TextInput
                      type="text"
                      onChange={(e) => {
                        let addressBuildingNumberValue =
                          onProfileFieldChange(e);
                        setValue(
                          "address_additional_number",
                          addressBuildingNumberValue
                        );
                      }}
                      onBlur={() =>
                        OnBlurInput(
                          "address_additional_number",
                          establishmentInfo.address_additional_number
                        )
                      }
                      placeholder="الرقم الإضافي"
                      value={establishmentInfo.address_additional_number}
                      name="address_additional_number"
                      errorText={validationErrors.address_additional_number}
                      maxLength={4}
                    />
                  </div>
                ) : (
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    {establishmentProfile.address_additional_number ||
                      "غير محدد"}
                  </Text>
                )}
              </div>
            </div>
            <Text
              Tag="p"
              textType="p5"
              className="profile-info__header establishmnet-profile-map__title"
            >
              الموقع على الخريطة
            </Text>
            {!isEdit && establishmentProfile.google_address && (
              <Text
                textType="p5"
                Tag="div"
                className="establishmnet-profile-map__location"
                isBold
              >
                {establishmentProfile.google_address}
              </Text>
            )}
            {/* {isEdit && (
              <div className="profile-input ">
                <TextInput
                  type="text"
                  disabled
                  value={establishmentProfile.google_address}
                  name="google address"
                />
              </div>
            )} */}

            <div className="profile-map">
              <LocationPicker
                googleMapURL={
                  "https://maps.googleapis.com/maps/api/js?key=" +
                  process.env.REACT_APP_GOOGLE_MAPS_API_KEY +
                  "&language=en&v=3&libraries=geometry,drawing,places"
                }
                loadingElement={<div>Loading...</div>}
                defaultPosition={
                  mapLocationSpecified
                    ? establishmentMapLocation
                    : defaultMapLocation
                }
                mapControlsEnabled={isEdit}
                onChange={onLocationChange}
                withInputSearch={isEdit ? true : false}
                GoogleAddress={establishmentProfile.google_address}
              />

              {validationErrors.google_address && (
                <Text
                  Tag="div"
                  textType="p6"
                  className="profile-input__warning center"
                >
                  {validationErrors.google_address}
                </Text>
              )}
            </div>
            {/* {!isEdit && establishmentProfile.google_address && (
              <Text
                textType="p6"
                Tag="div"
                className="establishmnet-profile-map__location"
                isBold
              >
                {establishmentProfile.google_address}
              </Text>
            )} */}
          </Card>
          <Card className="establishmnet-profile-card">
            <Text
              Tag={"span"}
              textType="p3"
              className="establishmnet-profile-card__header"
            >
              البيانات البنكية
              {isEdit && (
                <Text
                  Tag={"span"}
                  textType="p5"
                  className="establishmnet-profile-card__header"
                >
                  {" "}
                  (اختياري)
                </Text>
              )}
            </Text>
            {!isEdit &&
              establishmentInfo.payment_requester_name.length == 0 &&
              establishmentInfo.IBAN_number.trim().length == 0 && (
                <>
                  <Text
                    Tag="p"
                    textType="p5"
                    className="profile-info__text"
                    isBold
                  >
                    لم يتم اضافة البيانات البنكية بعد ....
                  </Text>

                  <div className="profile-info">
                    <Button
                      primary
                      onClick={() => onEdit({ targetId: "bank_form" })}
                      text="إضافة البيانات البنكية"
                      style={{
                        width: "200px",
                        height: "38px",
                      }}
                    />
                  </div>
                </>
              )}
            {!isEdit &&
              establishmentInfo.payment_requester_name !== "" &&
              establishmentInfo.IBAN_number !== "" && (
                <div className="establishmnet-profile-card__container4">
                  <div className="profile-info">
                    <Text
                      Tag="p"
                      textType="p5"
                      className="profile-info__header"
                    >
                      الاسم الكامل
                    </Text>

                    <Text
                      Tag="p"
                      textType="p5"
                      className="profile-info__text"
                      isBold
                    >
                      {establishmentProfile.payment_requester_name}
                    </Text>
                  </div>
                  <div className="profile-info">
                    <Text
                      Tag="p"
                      textType="p5"
                      className="profile-info__header"
                    >
                      رقم الايبان الخاص بالمنشأة
                    </Text>

                    <Text
                      Tag="p"
                      textType="p5"
                      className="profile-info__text"
                      isBold
                    >
                      {establishmentProfile.IBAN_number}
                    </Text>
                  </div>
                  <div className="profile-info">
                    <Text
                      Tag="p"
                      textType="p5"
                      className="profile-info__header"
                    >
                      اسم البنك
                    </Text>

                    <Text
                      Tag="p"
                      textType="p5"
                      className="profile-info__text"
                      isBold
                    >
                      {establishmentProfile?.bank_info?.label}
                    </Text>
                  </div>
                  {establishmentProfile?.bank_certificate_attachment?.file_name
                    ?.length > 0 && (
                    <div className="profile-info">
                      <AttachmentUploader
                        file={
                          establishmentProfile?.bank_certificate_attachment
                            ?.file_name?.length > 0
                            ? establishmentProfile.bank_certificate_attachment
                            : null
                        }
                        name={"خطاب تعريف بالأيبان مصدق من الغرفة التجارية"}
                        index={0}
                        isShowDelete={false}
                      />
                    </div>
                  )}
                </div>
              )}
            {isEdit && (
              <div id="bank_form">
                <div className="establishmnet-profile-card__container4">
                  <div className="profile-info">
                    <Text
                      Tag="p"
                      textType="p5"
                      className="profile-info__header"
                    >
                      الاسم الكامل
                    </Text>

                    <div className="profile-input ">
                      <TextInput
                        type="text"
                        onChange={(e) => {
                          let PaymentRequesterNameValue =
                            onProfileFieldChange(e);
                          setValue(
                            "payment_requester_name",
                            PaymentRequesterNameValue
                          );
                        }}
                        onBlur={() =>
                          OnBlurInput(
                            "payment_requester_name",
                            establishmentInfo.payment_requester_name
                          )
                        }
                        placeholder="الاسم الكامل"
                        value={establishmentInfo.payment_requester_name}
                        name="payment_requester_name"
                        errorText={validationErrors.payment_requester_name}
                      />
                    </div>
                  </div>
                  <div className="profile-info">
                    <Text
                      Tag="p"
                      textType="p5"
                      className="profile-info__header"
                    >
                      رقم الايبان الخاص بالمنشأة
                    </Text>

                    <div className="profile-input ">
                      <TextInput
                        type="text"
                        onChange={(e) => {
                          let input = e.target.value;
                          if (input.length < 3) {
                            input = "SA";
                          } else {
                            input = input.replace(/[^a-zA-Z0-9]/g, "");
                            if (!input.startsWith("SA")) {
                              input = "SA" + input;
                            }
                          }
                          const formattedInput = input.slice(0, 24);
                          setValue("IBAN_number", formattedInput);
                        }}
                        onBlur={() =>
                          OnBlurInput(
                            "IBAN_number",
                            establishmentInfo.IBAN_number
                          )
                        }
                        placeholder="SA-"
                        value={establishmentInfo.IBAN_number}
                        name="IBAN_number"
                        errorText={validationErrors.IBAN_number}
                        maxLength="24"
                      />
                    </div>
                  </div>

                  <div className="profile-info">
                    <Text
                      Tag="p"
                      textType="p5"
                      className="profile-info__header"
                    >
                      اسم البنك
                    </Text>
                    <LabeledDropDown
                      placeholder="يرجى تحديد البنك"
                      value={
                        establishmentInfo.bank_info &&
                        establishmentInfo.bank_info.label !== ""
                          ? {
                              value: establishmentInfo.bank_info.id,
                              label: establishmentInfo.bank_info.label,
                            }
                          : null
                      }
                      name="bank_id"
                      onChange={(e) => {
                        const bankName = e
                          ? {
                              id: e.value,
                              label: e.label,
                            }
                          : { id: "", label: "" };

                        setValue("bank_id", bankName);
                        setValue("bank_info", bankName);
                      }}
                      onBlur={() => {
                        OnBlurInput("bank_id", establishmentInfo.bank_id);
                        OnBlurInput("bank_info", establishmentInfo.bank_info);
                      }}
                      options={
                        BanksList ? listFormattedForDropdown(BanksList) : null
                      }
                      isClearable
                      errorText={validationErrors.bank_info}
                    />
                  </div>
                </div>
                <div className="establishmnet-profile-card__container5">
                  <div className="profile-info ">
                    <AttachmentUploader
                      customErrorMessage={
                        validationErrors.bank_certificate_attachment
                      }
                      file={
                        establishmentInfo?.bank_certificate_attachment
                          ?.file_name?.length > 0 ||
                        establishmentInfo?.bank_certificate_attachment?.name
                          ?.length > 0
                          ? establishmentInfo.bank_certificate_attachment
                          : null
                      }
                      removeFile={(id) => handleRemoveFileById(id)}
                      name={"خطاب تعريف بالأيبان مصدق من الغرفة التجارية"}
                      index={0}
                      getFile={(attachment) => {
                        handleGetAndUploadFile(
                          attachment.file,
                          establishmentProfile.id
                        );
                      }}
                      imageOnly={true}
                    />
                  </div>
                  <div className="profile-info ">
                    <LabeledCheckbox
                      type="checkbox"
                      checked={
                        establishmentInfo.iban_disclaimer
                          ? establishmentInfo.iban_disclaimer
                          : false
                      }
                      onChange={(e) =>
                        setValue(
                          "iban_disclaimer",
                          !establishmentInfo.iban_disclaimer
                        )
                      }
                      label="أقر بأن رقم اللآيبان المدخل صحيح ويخص المنشأة، كما أعلم أنه سيتم استخدامه في حال استرجاع أي مستحقات مالية تخص العميل وأتحمل كافة التبعات القانونية في حال ثبوت خلاف ذلك "
                      style={{ color: "#171725" }}
                      textType="p4"
                      id="all-establishments-checkbox"
                      errorText={validationErrors.iban_disclaimer}
                    />
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
      {isEdit && (
        <div className="profile-edit-buttons-wrapper">
          <Button
            onClick={onSave}
            text="حفظ"
            style={{
              width: "118px",
              height: "38px",
            }}
          />
          {!isProfileFirstLogin && (
            <div className="profile-edit-buttons-wrapper__cancel">
              <Button
                primary
                onClick={onCancel}
                text="إلغاء"
                style={{
                  width: "118px",
                  height: "38px",
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ProfileContent.propTypes = {
  establishmentProfile: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  validationErrors: PropTypes.object.isRequired,
  defaultMapLocation: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onLocationChange: PropTypes.func.isRequired,
  onProfileFieldChange: PropTypes.func.isRequired,
  OnBlurInput: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  establishmentInfo: PropTypes.object,
  BanksList: PropTypes.array.isRequired,
};

export default ProfileContent;
