import React, { Fragment, useState } from "react";
import PopUp from "../../views/Components/PopUp/PopUp";
import { useDispatch } from "react-redux";
import {
  cancelInspectionRequest,
  createInspectionRequest,
  createReopenAssessment,
} from "../../actions/beforeLoadActions";
import { saveSelectedDuration } from "../../actions/durationActions";
import { RE_ASSESSMENT } from "../../common/constants/paymentStatuses";
import { SAVE_SELECTED_TRACK_TYPE } from "../../actions/actionTypes";

export const setTrackType = (trackType) => ({
  type: SAVE_SELECTED_TRACK_TYPE,
  payload: { trackType },
});

export const AssessmentFeatureCard = ({
  item,
  profileId,
  duration,
  history,
  trackType,
  isCreateInspection,
}) => {
  const [popUpOptions, setPopUpOptions] = useState({});
  const { title, desc, icon, link, name, isPurchased, card } = item ?? {};
  const dispatch = useDispatch();
  const handleOnClick = () => {
    if (link === "create_inspection_request") {
      setPopUpOptions({
        name: "inspection",
        isVisible: true,
        title: "طلب خدمة التفتيش",
        body: `يرجى الملاحظة أنه يمكنك الانتهاء من التقييم بشكل أسرع وذلك عن طريق البدء بالتقييم الذاتي، هل لا تزال تريد الاستمرار بطلب خدمة التفتيش؟`,
        actionButton: "الاستمرار",
      });
      return;
    }
    if (!duration.is_reassessment && name === "reassessment") {
      setPopUpOptions({
        name: "reassessment",
        isVisible: true,
        title: "إعادة التقييم",
        body: "سيتم فتح فترة تقييمية جديده، هل تريد اعادة التقييم لهذة المنشأة؟",
        actionButton: "تأكيد",
      });
    }
  };

  const handleOnClickAction = async (name) => {
    if (!duration.is_reassessment && name === "reassessment") {
      setPopUpOptions({ isVisible: false });
      const withoutResult = false;
      dispatch(saveSelectedDuration(duration));
      dispatch(setTrackType(trackType));
      history.push(
        `/establishmentAssessmentPayment/${duration.period_id}/${duration.assessment_id}/${profileId}/${duration.assessment_version}/${withoutResult}/${RE_ASSESSMENT}/${duration.period_assessment_id}`
      );
    }
    if (name === "inspection") {
      setPopUpOptions({ isVisible: false });
      dispatch(
        createInspectionRequest(
          profileId,
          duration.period_id,
          duration.assessment_id,
          duration.assessment_version
        )
      ).then(() => {
        isCreateInspection();
      });
    }
  };

  const handleOnClickButton = () => {
    // inspection case
    if (name === "inspection") {
      dispatch(cancelInspectionRequest(profileId, duration.inspection_id));
      return;
    }
    if (name === "detailed_report") {
      window.location.href = "/#" + link;
    }
    if (name === "special_track") {
      window.location.href = "/#" + link;
    }
  };

  const handleGetLink = () => {
    if (link === "reassessment" || link === "create_inspection_request") {
      return null;
    }
    return "/#" + link;
  };
  if (card === "hide") return null;
  else
    return (
      <div
        className={
          "flex flex-col items-center w-[239px] h-[370px] pt-6 pb-3 border border-border rounded-[10px] bg-white"
        }
      >
        <h3 className={"text-base text-deep_sea_blue font-mol font-bold"}>
          {title}
        </h3>

        <div
          className={
            "w-full mt-4 h-[104px] max-h-[114px] rounded overflow-hidden flex justify-center items-center z-50"
          }
        >
          <img
            className={
              "w-full h-full object-contain z-50" + name === "inspection" &&
              "object-cover"
            }
            style={{ height: "100%" }}
            src={icon}
            alt={title}
          />
        </div>
        {/* description */}
        <div
          className={
            "w-full min-h-[136px] max-h-[136px] h-[136px] mt-4 text-center px-4"
          }
        >
          <p className={"text-sm text-dark_gray font-effra font-medium"}>
            {desc}
          </p>
        </div>
        {/* link */}

        {isPurchased ? (
          <Fragment>
            {name === "grace_period" ||
            name === "reassessment" ||
            name === "inspection" ? (
              <div>
                <p
                  className={
                    "text-sm text-deep_sea_blue font-mol font-bold text-center leading-relaxed"
                  }
                >
                  {name === "grace_period"
                    ? " تم إضافة مهلة تصحيحية لمنشأتك"
                    : ""}
                  {name === "reassessment" || name === "inspection"
                    ? "تم طلب هذه الخدمة"
                    : ""}
                </p>
              </div>
            ) : (
              <button
                className={
                  "w-[200px] h-[38px] rounded-[6px] flex justify-center items-center bg-white mx-auto text-sm font-mol font-semibold"
                }
                onClick={handleOnClickButton}
                style={{
                  border: "1px solid",
                  borderColor: "#F5961E!important",
                  color: "#F5961E",
                }}
              >
                عرض
              </button>
            )}
          </Fragment>
        ) : (
          <div className={"mt-auto flex items-center gap-x-2"}>
            {link === "reassessment" ? (
              <p className={"text-sm text-dark_gray font-effra font-medium"}>
                {" "}
                لا يمكن طلب الخدمة بعد اضافة المسار المميز{" "}
              </p>
            ) : (
              ""
            )}
            {link === "invisible" ? (
              <p className={"text-sm text-dark_gray font-effra font-medium"}>
                {" "}
                يمكن طلب هذه الخدمة بعد اكمال التقييم{" "}
              </p>
            ) : (
              ""
            )}
            {link === "Disable" ? (
              <p className={"text-sm text-dark_gray font-effra font-small"}>
                {"   "}
                لا يمكن طلب الخدمة
                {"   "}
              </p>
            ) : (
              ""
            )}
            {link !== "Disable" &&
            link !== "invisible" &&
            link !== "reassessment" ? (
              <Fragment>
                <a
                  href={handleGetLink()}
                  style={{ color: "#14415a" }}
                  className={
                    "text-sm text-deep_sea_blue font-mol font-semibold"
                  }
                  onClick={handleOnClick}
                >
                  {"معرفة المزيد "}
                </a>
                <svg
                  className={"mt-[1px]"}
                  width="15.924"
                  height="11"
                  viewBox="0 0 15.924 11"
                >
                  <path
                    id="Path_20277"
                    data-name="Path 20277"
                    d="M20364.115,19222.447l6.1-5.449,1.367,1.455-3.51,3.123h11.967v1.967h-11.92l3.441,2.99-1.352,1.465Z"
                    transform="translate(-20364.115 -19216.998)"
                    fill="#14415a"
                  />
                </svg>
              </Fragment>
            ) : (
              ""
            )}
          </div>
        )}

        <PopUp
          isOpen={popUpOptions.isVisible}
          title={popUpOptions.title}
          body={popUpOptions.body}
          onClickAction={() => handleOnClickAction(popUpOptions?.name)}
          actionButtonText={popUpOptions.actionButton}
          onClickCancel={() => setPopUpOptions({ isVisible: false })}
          cancelButtonText="إلغاء"
        />
      </div>
    );
};
