export const EColors = {
    deep_sea_blue: 'deep_sea_blue',
    gray: 'gray',
    lavender_gray: 'lavender_gray',
    light_gray: 'light_gray',
    turquoise_blue: 'turquoise_blue',
    turquoise_blue_20: '20_turquoise_blue',
    light_gray_50: '50_light_gray',
    ghost_white: 'ghost_white',
    green: 'green',
    sea_green: 'sea_green',
    light_green: 'light_green',
    steel_blue: 'steel_blue',
    dark_gray: 'dark_gray',
    agate_gray: 'agate_gray',
    red: 'red',
    orange: 'orange',
  };

  export const IMS_COLORS = {
    deep_sea_blue: "#14415A",
    gray: "#92929D",
    lavender_gray: "#C1C6CA",
    light_gray: "#DFDFDF",
    turquoise_blue: "#148287",
    "20_turquoise_blue": "#14828733",
    "50_light_gray": "#DFDFDF80",
    ghost_white: "#F6F9FB",
    green: "#1B8F5B",
    sea_green: "#2DB473",
    light_green: "#D5F0E3",
    steel_blue: "#0E2A37",
    dark_gray: "#505050",
    agate_gray: "#AFACAC",
    red: "#DA3434",
    orange: "orange",
  };
  