import React, { useEffect, useState } from "react";
import {
  useActiveStep,
  useCalibers,
  useData,
  usePeriod,
  useSetNextStep,
  useSetPrevStep,
  useSteps,
} from "../../self-assessment-form-context/index";
import { checkCaliberValidation } from "../../../util/CheckCaliberValidation";
import { useSelector } from "react-redux";
import { submitAssessmentHandler } from "../../self-assessment-form-context/ctx-handler";
import Loader from "../../../../../../components/Loader/Loader";

export const ButtonsRow = ({ step, setErrors, goToSuccessScreen }) => {
  const next = useSetNextStep();
  const establishmentProfileId = useSelector(
    (state) => state?.establishment.establishmentProfile.id
  );
  const prev = useSetPrevStep();
  const period = usePeriod();
  const activeStep = useActiveStep();
  const ctxData = useData();
  const steps = useSteps();
  const calibers = useCalibers();
  const [loadingSubmit, setLoadingSubmit] = useState(false); // Define loadingSubmit state variable

  const handleNext = async () => {
    setErrors({});
    if (step?.type === "qiwa") {
      return next();
    }

    if (step?.type === "addons") {
      if (step?.hasAgreed) {
        setLoadingSubmit(true); // Set loadingSubmit to true before submitting
        // submit assessment
        // try {
        // Call submitAssessmentHandler with loadingSubmit and setLoadingSubmit
        await submitAssessmentHandler({
          assessment: period,
          calibers,
          data: ctxData,
          establishmentProfileId,
        })
          .then((result) => {
            console.log("result", result);
            goToSuccessScreen();
          })
          .catch((error) => {
            console.error("Error submitting assessment:", error);
            setLoadingSubmit(false); // Set loadingSubmit to false if there's an error
            if (error) {
              setErrors({
                step,
                missingAnswer: error,
              });
            } else {
              setErrors({
                step,
                agreement: "يجب الموافقة على الشروط والأحكام",
              });
            }
          });
        // } catch (error) {
        //   console.error("Error submitting assessment:", error);
        //   setLoadingSubmit(false); // Set loadingSubmit to false if there's an error
        //   setErrors({ step, agreement: "يجب الموافقة على الشروط والأحكام" });
        // }
      } else {
        setErrors({ step, agreement: "يجب الموافقة على الشروط والأحكام" });
        return;
      }
      return;
    }
    // console.log("step", step);

    const caliber = calibers?.[step?.caliber_id];

    // console.log("caliber", caliber);
    const { isValid, scrollTarget, errors } = checkCaliberValidation(caliber);
    // console.log("isValid", { isValid, scrollTarget, errors });
    if (isValid) {
      return next();
    } else if (!isValid) {
      // console.log("scrollTarget", scrollTarget);
      if (Object.keys(errors)?.length) {
        setErrors(errors);
      }
      if (scrollTarget) {
        // console.log("should scroll now", scrollTarget);
        document
          .getElementById(scrollTarget)
          ?.scrollIntoView({ behavior: "smooth" });
      }
    } else if (activeStep >= steps?.length) {
      // alert("submit");
      // push("/dashboard/self/success");
    } else {
      if (false) {
        // alert('submit');
      } else {
        next();
      }
    }
  };

  if (loadingSubmit) {
    return (
      <div
        className={
          "flex-1 w-full min-h-[600px] flex items-center justify-center"
        }
      >
        <Loader />
      </div>
    );
  } else {
    return (
      <div className="mt-6 w-full flex justify-end gap-x-4 ">
        {activeStep?.index === 0 &&
        activeStep?.categoryIndex === 0 &&
        activeStep?.caliberIndex === 0 ? null : (
          <button
            onClick={prev}
            className={
              "w-[118px] h-[38px] rounded-[6px] shadow-sm shadow-[#00000026] border-[1.5px] border-[#F5961E] text-[#F5961E] text-sm font-mol font-bold focus:outline-none"
            }
          >
            {"السابق"}
          </button>
        )}

        <button
          onClick={handleNext}
          className={
            "w-[118px] h-[38px] rounded-[6px] shadow-sm shadow-[#00000026] bg-ims-dark_orange text-white text-sm font-mol font-bold focus:outline-none"
          }
        >
          {step?.type === "addons" ? "إرسال" : "التالي"}
        </button>
      </div>
    );
  }
};
