import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import toastr from "toastr";
import * as unifiedActions from "../../../actions/unifiedActions";
import "./assessment-payment.scss";
import * as profileActions from "../../../actions/establishmentProfileActions";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import {
  ADDITIONAL_ASSESSMENT,
  DESCRIPTION,
  DETAILEDREPORT,
  FAST_TRACK_BRANCHES,
  FASTTRACK,
  GRACE_PERIOD,
  RE_ASSESSMENT,
  STATIC_FAST_TRACK_FOR_BRANCHES,
  STATUSES,
  UNIFIED_ASSESSMENT,
  DETAILED_REPORT_WITH_GRACE_PERIOD,
} from "../../../common/constants/paymentStatuses";
import {
  STATUS_NOT_PAID,
  STATUSES as BILL_STATUSES,
} from "../../../common/constants/billStatuses";
import LabeledCheckbox from "../../../components/LabeledCheckBox/LabeledCheckbox";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import Button from "../../../components/Button/Button";
import SwitchButton from "../../../components/SwitchButton/SwitchButton";
import PopUp from "../../Components/PopUp/PopUp";
import Loader from "../../../components/Loader/Loader";
import { CheckBox } from "../AssessmentFeature/lib/components/checkbox/checkbox";
import { EventNote } from "@mui/icons-material";

export class AssessmentPayment extends React.Component {
  constructor() {
    super();
    // trackType
    // basic
    // special
    this.state = {
      approve: false,
      correctionChecked: false,
      correctionOpen: false,
      isLoading: false,
      isShowPopUp: false,
    };

    this.approveToggle = this.approveToggle.bind(this);
    this.payForService = this.payForService.bind(this);
    this.handlePaymentClick = this.handlePaymentClick.bind(this);
    this.toggleCorrectionModal = this.toggleCorrectionModal.bind(this);
    this.toggleCorrectionSwitch = this.toggleCorrectionSwitch.bind(this);
    this.getServices = this.getServices.bind(this);
    this.payWithCorrection = this.payWithCorrection.bind(this);
    this.payWithoutCorrection = this.payWithoutCorrection.bind(this);
    this.getBills = this.getBills.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.redirectToPayment = this.redirectToPayment.bind(this);
  }

  componentDidMount() {
    const {
      loadPaymentServicesByType,
      match,
      fetchUnifiedEstablishments,
      fetchGracePeriodStatus,
      location,
    } = this.props;
    // console.log("trackType", this.props.location.state.trackType);
    const {
      establishmentId,
      serviceType,
      period_assessment_id,
      periodId,
      assessmentId,
      assessmentVersion,
      assessmentType,
    } = match.params;
    if (establishmentId && serviceType !== STATIC_FAST_TRACK_FOR_BRANCHES) {
      loadPaymentServicesByType(establishmentId, period_assessment_id, serviceType);
    }

    if (serviceType === DETAILEDREPORT && assessmentType != RE_ASSESSMENT) {
      fetchGracePeriodStatus(
        establishmentId,
        periodId,
        assessmentId,
        assessmentVersion
      );
    }
    if (serviceType === GRACE_PERIOD) {
      fetchGracePeriodStatus(
        establishmentId,
        periodId,
        assessmentId,
        assessmentVersion
      );
    }
    if (serviceType === UNIFIED_ASSESSMENT) {
      fetchUnifiedEstablishments(
        establishmentId,
        periodId,
        assessmentId,
        period_assessment_id,
        assessmentVersion
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { establishmentProfile, gracePeriodStatus } = this.props;
    const { establishmentProfile: prevEstablishmentProfile } = prevProps;

    if (establishmentProfile.id !== prevEstablishmentProfile.id) {
      this.props.history.goBack();
    }

    if (gracePeriodStatus !== prevProps.gracePeriodStatus) {
      this.toggleCorrectionSwitch(gracePeriodStatus);
    }
  }

  componentWillUnmount() {
    const { reset } = this.props;

    reset();
  }

  approveToggle(val) {
    this.setState({ approve: val });
  }

  redirectToPayment(id, serviceType, paymentBill) {
    const { history, location } = this.props;
    // if (serviceType === RE_ASSESSMENT)
    //   history.push({
    //     pathname: `/checkout/${id}/${serviceType}/${paymentBill.sadad_number}/${paymentBill.payment_resp_bill_number}`,
    //     state: { trackType: location.state.trackType },
    //   });
    // else
    history.push(
      `/checkout/${id}/${serviceType}/${paymentBill.sadad_number}/${paymentBill.payment_resp_bill_number}`
    );
  }
  payForService(serviceId) {
    const {
      periodId,
      assessmentId,
      establishmentId,
      assessmentVersion,
      serviceType,
    } = this.props.match.params;

    const { approve } = this.state;
    const { unifiedEstablishments, history } = this.props;
    if (!approve) {
      toastr.error("يجب الموافقة على الشروط والاحكام");
      return;
    }
    this.setState({ isLoading: true });

    if (serviceType === RE_ASSESSMENT) {
      this.props
        .submitPayment(
          establishmentId,
          serviceId,
          periodId,
          assessmentId,
          assessmentVersion
        )
        .then((val) => {
          // toastr.success("تم طلب الدفع بنجاح لخدمة إعادة التقييم");
          this.setState({ isLoading: false });

          this.redirectToPayment(
            val.hyperpay_bill.id,
            RE_ASSESSMENT,
            val.payment_bill
          );
        })
        .catch((error) => {
          if (error == 424) {
            this.setState({
              isLoading: false,
              isShowPopUp: true,
            });
          } else if (error && error.details) {
            this.setState({ isLoading: false });
            toastr.error(error.details);
          } else {
            this.setState({ isLoading: false });
            toastr.error("حدث خطأ ما");
          }
        });
    }

    if (serviceType === FASTTRACK) {
      this.props
        .submitPayment(
          establishmentId,
          serviceId,
          periodId,
          assessmentId,
          assessmentVersion
        )
        .then((val) => {
          // toastr.success("تم طلب الدفع بنجاح المسار المميز");
          this.setState({ isLoading: false });
          this.redirectToPayment(
            val.hyperpay_bill.id,
            FASTTRACK,
            val.payment_bill
          );
        })
        .catch((error) => {
          if (error == 424) {
            this.setState({
              isLoading: false,
              isShowPopUp: true,
            });
          } else if (error && error.details) {
            this.setState({ isLoading: false });

            toastr.error(error.details);
          } else {
            this.setState({ isLoading: false });
            toastr.error("حدث خطأ ما");
          }
        });
    }
    if (serviceType === ADDITIONAL_ASSESSMENT) {
      this.props
        .submitPayment(
          establishmentId,
          serviceId,
          periodId,
          assessmentId,
          assessmentVersion
        )
        .then((val) => {
          // toastr.success("تم طلب الدفع بنجاح لخدمة التقييم الذاتي");
          this.setState({ isLoading: false });
          this.redirectToPayment(
            val.hyperpay_bill.id,
            ADDITIONAL_ASSESSMENT,
            val.payment_bill
          );
        })
        .catch((error) => {
          if (error == 424) {
            this.setState({
              isLoading: false,
              isShowPopUp: true,
            });
          } else if (error && error.details) {
            this.setState({ isLoading: false });
            toastr.error(error.details);
          } else {
            this.setState({ isLoading: false });
            toastr.error("حدث خطأ ما");
          }
        });
    }
    if (serviceType === DETAILEDREPORT) {
      this.props
        .submitPayment(
          establishmentId,
          serviceId,
          periodId,
          assessmentId,
          assessmentVersion
        )
        .then((val) => {
          // toastr.success("تم طلب الدفع بنجاح لخدمة التقرير المفصل");
          this.setState({ isLoading: false });
          this.redirectToPayment(
            val.hyperpay_bill.id,
            DETAILEDREPORT,
            val.payment_bill
          );
        })
        .catch((error) => {
          if (error == 424) {
            this.setState({
              isLoading: false,
              isShowPopUp: true,
            });
          } else if (error && error.details) {
            this.setState({ isLoading: false });
            toastr.error(error.details);
          } else {
            this.setState({ isLoading: false });
            toastr.error("حدث خطأ ما");
          }
        });
    }
    if (serviceType === FAST_TRACK_BRANCHES) {
      this.props
        .submitPayment(
          establishmentId,
          serviceId,
          periodId,
          assessmentId,
          assessmentVersion
        )
        .then((val) => {
          // toastr.success("تم طلب الدفع بنجاح لخدمة المسار المميز للفروع");
          this.setState({ isLoading: false });
          this.redirectToPayment(
            val.hyperpay_bill.id,
            FAST_TRACK_BRANCHES,
            val.payment_bill
          );
        })
        .catch((error) => {
          if (error == 424) {
            this.setState({
              isLoading: false,
              isShowPopUp: true,
            });
          } else if (error && error.details) {
            this.setState({ isLoading: false });
            toastr.error(error.details);
          } else {
            this.setState({ isLoading: false });
            toastr.error("حدث خطأ ما");
          }
        });
    }
    if (serviceType === UNIFIED_ASSESSMENT) {
      const selectedIds = unifiedEstablishments.map((element) => element.id);
      this.props
        .payForUnifiedService(establishmentId, {
          payment_service_id: serviceId,
          assessment_id: assessmentId,
          period_id: periodId,
          assessment_version: Number(assessmentVersion),
          establishment_ids: selectedIds,
        })
        .then((val) => {
          // toastr.success("تم طلب الدفع بنجاح لخدمة التقييم الموحد");
          this.setState({ isLoading: false });
          this.redirectToPayment(
            val.hyperpay_bill.id,
            UNIFIED_ASSESSMENT,
            val.payment_bill
          );
        })
        .catch((error) => {
          if (error && error.details) {
            toastr.error(error.details);
          } else {
            toastr.error("حدث خطأ ما");
          }
        });
    }
  }

  handlePaymentClick() {
    const { correctionChecked } = this.state;
    const {
      match,
      gracePeriodStatus,
      bills,
      isLoadingPayment,
      isLoadingUnifiedPayment,
    } = this.props;
    const {
      establishmentId,
      serviceType,
      period_assessment_id,
      periodId,
      assessmentId,
      assessmentVersion,
    } = match.params;

    const activeBills =
      bills && bills.filter((bill) => bill.bill_status === STATUS_NOT_PAID);
    if (
      serviceType === DETAILEDREPORT &&
      !correctionChecked &&
      gracePeriodStatus
    ) {
      this.toggleCorrectionModal(true);
      return;
    }

    if (
      serviceType === DETAILEDREPORT &&
      correctionChecked &&
      !isLoadingPayment
    ) {
      this.payWithCorrection();
      return;
    }
    if (serviceType === GRACE_PERIOD && !isLoadingPayment) {
      this.payWithCorrection();
      return;
    } else if (!isLoadingPayment && !isLoadingUnifiedPayment) {
      this.payWithoutCorrection();
    }
  }

  toggleCorrectionModal(toggleOn) {
    this.setState({ correctionOpen: toggleOn });
  }

  toggleCorrectionSwitch(gracePeriodStatus = null) {
    if (gracePeriodStatus) {
      this.setState({ correctionChecked: gracePeriodStatus });
    } else
      this.setState((state) => ({
        correctionChecked: !state.correctionChecked,
      }));
  }
  
  getServices() {
    const { correctionChecked } = this.state;
    const { paymentServices, match } = this.props;
    const { serviceType, assessmentType } = match.params;
    const isGetServices =
    paymentServices && paymentServices.length > 0
        ? paymentServices.filter((service) => {
            if (
              serviceType === DETAILEDREPORT &&
              assessmentType != RE_ASSESSMENT
            ) {
              if (service.type === GRACE_PERIOD) {
                return correctionChecked;
              }
            }
            return service.type === serviceType;
          })
        : [];
    return isGetServices;
  }

  getBills() {
    const { correctionChecked } = this.state;
    const { bills, match, location } = this.props;
    const { serviceType, assessmentType } = match.params;

    return bills
      ? bills.filter((service) => {
          if (
            serviceType === DETAILEDREPORT &&
            assessmentType != RE_ASSESSMENT
          ) {
            if (service.service_type === GRACE_PERIOD) {
              return correctionChecked;
            }
          }
          return service.service_type === serviceType;
        })
      : [];
  }

  payWithCorrection() {
    const { paymentServices, match, submitPaymentWithCorrection, history } =
      this.props;

    const {
      periodId,
      assessmentId,
      establishmentId,
      assessmentVersion,
      serviceType,
    } = match.params;

    const serviceIds = paymentServices
      .filter(
        (service) =>
          service.type === serviceType || service.type === GRACE_PERIOD
      )
      .map((service) => service.id);

    this.toggleCorrectionModal(false);
    this.setState({ isLoading: true });
    const selectedService =
      serviceType === GRACE_PERIOD
        ? serviceType
        : DETAILED_REPORT_WITH_GRACE_PERIOD;
    submitPaymentWithCorrection(
      establishmentId,
      serviceIds,
      periodId,
      assessmentId,
      assessmentVersion
    )
      .then((val) => {
        // toastr.success("تم طلب الدفع بنجاح");
        this.setState({ isLoading: false });
        history.push(
          `/checkout/${val.hyperpay_bill.id}/${selectedService}/${val.payment_bill.sadad_number}/${val.payment_bill.payment_resp_bill_number}`
        );
      })
      .catch((error) => {
        if (error == 424) {
          this.setState({
            isLoading: false,
            isShowPopUp: true,
          });
        } else if (error && error.details) {
          this.setState({ isLoading: false });
          toastr.error(error.details);
        } else {
          this.setState({ isLoading: false });
          toastr.error("حدث خطأ ما");
        }
      });
  }

  payWithoutCorrection() {
    const serviceList = this.getServices();
    const serviceId = serviceList && serviceList[0] ? serviceList[0].id : null;

    this.toggleCorrectionModal(false);
    this.payForService(serviceId);
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      correctionOpen: !prevState.correctionOpen,
    }));
  };

  render() {
    const { serviceType } = this.props.match.params;
    const { gracePeriodStatus, isLoadingServices, isLoadingStatus, isAlreadyPaid } =
      this.props;

    const { approve, correctionOpen, correctionChecked, isLoading } =
      this.state;
    const serviceList = this.getServices();

    const { main_branch_labor_office_id, main_branch_sequence_id } =
      this.props.establishmentProfile;
    return (
      <PageWrapper withBreadcrumbs>
        {isLoading ? (
          <div className="assessment-payment-loader" style={{ height: "70vh" }}>
            <div className="loader-wrapper">
              <Loader />
            </div>
          </div>
        ) : (
          <Fragment>
            <PopUp
              isOpen={correctionOpen}
              title="اضافة شهادة المهلة التصحيحية"
              body="تتيح لك هذة الخدمة الحصول على شهادة مهلة تصحيحية من الزيارات التفتيشية التابعة لوزارة الموارد البشرية والتنمية الاجتماعية لمدة شهرين."
              actionButtonText="إضافة شهادة المهلة التصحيحية"
              cancelButtonText="التخطي والدفع"
              onClickAction={this.payWithCorrection}
              onClickCancel={this.payWithoutCorrection}
              toggleModal={this.toggleModal}
            />
            {isLoadingServices ||
            (isLoadingStatus && serviceType === DETAILEDREPORT) ? (
              <div className="loader-div">
                <Loader />
              </div>
            ) : (
              <div className="assessment-payment">
                <div className="assessment-payment-card">
                  <Text
                    textType="p3"
                    isBold
                    className="assessment-payment-card-header"
                  >
                    تفاصيل الخدمة
                  </Text>
                  {serviceType === STATIC_FAST_TRACK_FOR_BRANCHES ? (
                    <Text
                      textType="p4"
                      className="assessment-payment-card-text"
                    >
                      {DESCRIPTION[serviceType]}
                      <b>
                        {" "}
                        ولكي تتاح لك هذه الخدمه يجب البدء بتقييم المسار المييز
                        والحصول على شهادة امتثال للمنشأة الرئيسية (
                        {main_branch_sequence_id} /{main_branch_labor_office_id}
                        ).
                      </b>
                    </Text>
                  ) : (
                    <Fragment>
                      {serviceList?.length !== 0 && (
                        <Text
                          textType="p4"
                          className="assessment-payment-card-text"
                        >
                          {DESCRIPTION[serviceList[0]?.type]}
                        </Text>
                      )}

                      {serviceType === DETAILEDREPORT && gracePeriodStatus && (
                        <div className="grace-period-switch">
                          <SwitchButton
                            value={correctionChecked}
                            onChange={this.toggleCorrectionSwitch}
                            label="اضافة شهادة المهلة تصحيحة"
                          />
                        </div>
                      )}
                      <div className="service-table assessment-payment-table-big-screen">
                        <table>
                          <thead>
                            <tr>
                              <Text
                                Tag="th"
                                textType="p4"
                                isBold
                                className="payment-first-column "
                              >
                                نوع الخدمة
                              </Text>

                              <Text Tag="th" textType="p4" isBold>
                                المبلغ
                              </Text>

                              <Text Tag="th" textType="p4" isBold>
                                ضريبة القيمة المضافة 15%
                              </Text>

                              <Text Tag="th" textType="p4" isBold>
                                المبلغ الإجمالي
                              </Text>
                            </tr>
                          </thead>
                          <tbody>
                            {serviceList &&
                              serviceList.length > 0 &&
                              serviceList.map((service) => (
                                <tr key={service.id}>
                                  <Text
                                    Tag="td"
                                    textType="p5"
                                    className="payment-first-column "
                                  >
                                    {STATUSES[service.type]}
                                  </Text>

                                  <Text
                                    Tag="td"
                                    textType="p5"
                                  >{`${service.amount} ريال`}</Text>

                                  <Text Tag="td" textType="p5">
                                    {`${service.amount * service.vat} ريال`}
                                  </Text>

                                  <Text Tag="td" textType="p5">
                                    {`${
                                      service.amount * service.vat +
                                      service.amount
                                    } ريال سعودي`}
                                  </Text>
                                </tr>
                              ))}

                            {serviceList && serviceList.length > 1 && (
                              <tr>
                                <Text
                                  Tag="td"
                                  textType="p5"
                                  className="payment-first-column"
                                >
                                  المجموع
                                </Text>

                                <td></td>
                                <td></td>

                                <Text Tag="td" textType="p5">
                                  {`${serviceList.reduce(
                                    (p, c) => p + c.amount * c.vat + c.amount,
                                    0
                                  )} ريال سعودي`}
                                </Text>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="service-table assessment-payment-table-small-screen">
                        <table>
                          <tbody>
                            {serviceList &&
                              serviceList.length > 0 &&
                              serviceList.map((service) => (
                                <Fragment key={service.id}>
                                  <tr>
                                    <Text Tag="th" textType="p4" isBold>
                                      نوع الخدمة
                                    </Text>

                                    <Text Tag="td" textType="p5">
                                      {STATUSES[service.type]}
                                    </Text>
                                  </tr>
                                  <tr>
                                    <Text Tag="th" textType="p4" isBold>
                                      المبلغ
                                    </Text>

                                    <Text
                                      Tag="td"
                                      textType="p5"
                                    >{`${service.amount} ريال`}</Text>
                                  </tr>
                                  <tr className="assessment-payment-table-row">
                                    <Text
                                      Tag="th"
                                      textType="p4"
                                      isBold
                                      className="assessment-payment-table-row"
                                    >
                                      ضريبة القيمة المضافة 15%
                                    </Text>

                                    <Text Tag="td" textType="p5">
                                      {`${service.amount * service.vat} ريال`}
                                    </Text>
                                  </tr>
                                </Fragment>
                              ))}
                            {serviceList && serviceList.length && (
                              <tr>
                                <Text Tag="th" textType="p4" isBold>
                                  المبلغ الإجمالي
                                </Text>

                                <Text Tag="td" textType="p5">
                                  {`${serviceList.reduce(
                                    (p, c) => p + c.amount * c.vat + c.amount,
                                    0
                                  )} ريال سعودي`}
                                </Text>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="service-form">
                        <div className="inner-service-form">
                          {/* {serviceType === DETAILEDREPORT &&
                            !gracePeriodStatus && (
                              <div className="service-form">
                                <LabeledCheckbox
                                  type="checkbox"
                                  checked={correctionChecked}
                                  onChange={this.toggleCorrectionSwitch}
                                  label="أرغب في اضافة شهادة المهلة التصحيحية لمدة شهرين"
                                  normalFont
                                  id="grace-period-switch-button"
                                />
                              </div>
                            )} */}
                          <CheckBox
                            label="أقر وأوافق على أن المبالغ المدفوعة للخدمة غير خاضعة لسياسة
 الاسترداد"
                            checked={approve}
                            onChange={this.approveToggle}
                            id="payment-agreement-1-checkbox"
                          />
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            )}
            { isAlreadyPaid && 
             <PopUp
             isOpen={isAlreadyPaid}
             title="الخدمة مدفوعة"
             bodyLink={ 
              <span>  لقد تم دفع رسوم الخدمة من قبل، الرجاء الانتقال  
              <a  href={process.env.REACT_APP_ESTABLISHMENT_BASE_URI+`/#/billsList`} >  لصفحة الفواتير  </a> 
              للاطلاع على حالة الدفع 
             </span>}
             />
            }
            {!isLoadingServices &&
              serviceType !== STATIC_FAST_TRACK_FOR_BRANCHES && (
                <div className="assessment-payment-footer">
                  <Button
                    onClick={this.handlePaymentClick}
                    text="ادفع"
                    disabled={!approve || isAlreadyPaid}
                    id="payment-1-button"
                  />
                </div>
              )}
          </Fragment>
        )}
        <PopUp
          isOpen={this.state.isShowPopUp}
          title="إضافة عنوان المنشأة"
          body="لإتمام عملية الدفع يرجى إضافة العنوان الوطني للمنشأة ، و ذلك عن طريق الانتقال لصفحة ملف المنشأة."
          onClickCancel={() => this.setState({ isShowPopUp: false })}
          cancelButtonText="حسنًا"
        />
      </PageWrapper>
    );
  }
}

AssessmentPayment.propTypes = {
  loadPaymentServicesByType: PropTypes.func.isRequired,
  submitPayment: PropTypes.func.isRequired,
  submitTrainingPayment: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object,
  bills: PropTypes.array,
  establishmentProfile: PropTypes.object.isRequired,
  payForUnifiedService: PropTypes.func.isRequired,
  fetchUnifiedEstablishments: PropTypes.func,
  fetchGracePeriodStatus: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  unifiedEstablishments: PropTypes.array,
  gracePeriodStatus: PropTypes.bool,
  submitPaymentWithCorrection: PropTypes.func.isRequired,
  paymentServices: PropTypes.array.isRequired, 
  isAlreadyPaid: PropTypes.bool.isRequired,
};

AssessmentPayment.defaultProps = {
  match: { params: {} },
};

const mapStateToProps = (state) => ({
  isAlreadyPaid: state.payment.isAlreadyPaid,
  paymentServices: state.payment.paymentServices,
  isLoadingServices: state.payment.isLoadingServices,
  bills: state.payment.bills,
  establishmentProfile: state.establishment.establishmentProfile,
  unifiedEstablishments: state.unified.establishments,
  gracePeriodStatus: state.unified.gracePeriodStatus,
  isLoadingStatus: state.unified.isLoadingStatus,
  isLoadingPayment: state.paymentInitiate.isLoadingPayment,
  isLoadingUnifiedPayment: state.unified.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUnifiedEstablishments: (
    establishmentId,
    periodId,
    assessmentId,
    periodAssessmentId,
    assessmentVersion
  ) =>
    dispatch(
      unifiedActions.fetchUnifiedEstablishments(
        establishmentId,
        periodId,
        assessmentId,
        periodAssessmentId,
        assessmentVersion
      )
    ),
  submitPayment: (
    establishment_id,
    payment_service_id,
    period_id,
    assessment_id,
    assessmentVersion
  ) =>
    dispatch(
      profileActions.submitPayment(
        establishment_id,
        payment_service_id,
        period_id,
        assessment_id,
        assessmentVersion
      )
    ),
  loadPaymentServicesByType: (establishmentId, period_assessment_id, serviceType) => 
     dispatch(profileActions.loadPaymentServicesByType(establishmentId, period_assessment_id, serviceType))
  ,submitPaymentWithCorrection: (
    establishment_id,
    payment_service_ids,
    period_id,
    assessment_id,
    assessmentVersion
  ) =>
    dispatch(
      profileActions.submitPaymentWithCorrection(
        establishment_id,
        payment_service_ids,
        period_id,
        assessment_id,
        assessmentVersion
      )
    ),
  submitTrainingPayment: (establishment_id, payment_service_id, training_id) =>
    dispatch(
      profileActions.submitTrainingPayment(
        establishment_id,
        payment_service_id,
        training_id
      )
    ),
  payForUnifiedService: (establishmentId, paymentBill) =>
    dispatch(unifiedActions.payForService(establishmentId, paymentBill)),
  fetchGracePeriodStatus: (
    establishmentId,
    periodId,
    assessmentId,
    assessmentVersion
  ) =>
    dispatch(
      unifiedActions.fetchGracePeriodStatus(
        establishmentId,
        periodId,
        assessmentId,
        assessmentVersion
      )
    ),
  reset: () => dispatch(unifiedActions.reset()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AssessmentPayment));
