import React from "react";
import { Box, Stack } from "@mui/material";
import { AttachmentIcon, AttachmentItem, CheckIcon } from "./PrevAnswers";

import { Col, Row } from "reactstrap";
import { RenderAnswerView } from "./PrevAnswers/RenderAnswerView";
import { StackContainer } from "./StackContainer";
const boldFont = { fontWeight: "bold", fontSize: 16, color: "#0E2A37" };
const regFont = { fontSize: boldFont.fontSize, color: "#505050" };
const thinFont = { fontSize: boldFont.fontSize, color: "#0E2A37" };

const rowStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const effraFont = { fontFamily: "Effra" };

export const PrevAnswerInDetailedReport = (props) => {
  return (
    <Stack spacing={6} style={effraFont}>
      <StackContainer spacing={2}>
        {props?.reason && (
          <Box>
            <h1 style={{ ...boldFont, color: "#148287" }}>
              {props?.caliber.is_answered === "answered" && "تم إرجاع هذا البند، و تحديث بياناته من قبل المنشأة"}
              {props?.caliber.is_answered === "returned" && "تم إرجاع هذا البند لتحديث بياناته من قبل المنشأة - لم يتم الاستجابة لطلب تحديث بيانات البند"}
            </h1>
            <span style={thinFont}>{"سبب الإرجاع : "}</span>
            <span style={regFont}>{props?.reason}</span>
          </Box>
        )}
        {props?.details && (
          <Box style={{ marginTop: 4 }}>
            <span style={thinFont}>{"التفاصيل : "}</span>
            <span style={regFont}>{props?.details}</span>
          </Box>
        )}
        {props?.caliber.is_answered === "not_answered" ?  null :
        <Row>
          <Col>
            <div
              className="horizontal-line"
              style={{ marginTop: 7, marginBottom: 7, marginRight: 1, marginLeft: 0, borderTop: "1px solid #74727233" }}
            ></div>
          </Col>
        </Row> }
        {props?.caliber.is_answered === "not_answered" ? null :
          <div style={{ marginTop: 49 }}>
            <Stack
              display={"flex"}
              direction="row"
              alignItems={"center"}
              columnGap={1}
            >
              <CheckIcon />
              <h1 style={{ ...boldFont, color: "#148287" }}>
                {"تقييم المنشأة - قبل التعديل "}
              </h1>
            </Stack>

            <div style={{ ...rowStyles, columnGap: 48, marginTop: 4 }}>
              <Box sx={{ ...rowStyles, gap: 6 }}>
                <span style={regFont}>
                  {
                    "بعد الإطلاع على شرح البند الموجود أعلاه، هل توافق على هذا البند؟"
                  }
                </span>
                {props?.caliber.is_answered === "not_answered" ? null : <RenderAnswerView type={props?.answer} />}

              </Box>
            </div>
          </div>
        }
        {props?.attachments?.length && props?.caliber.is_answered === "answered" ? (
          <div style={{ marginTop: 49 }}>
            <Stack
              display={"flex"}
              direction="row"
              alignItems={"center"}
              columnGap={1}
            >
              <AttachmentIcon />
              <h1 style={{ ...boldFont, color: "#148287" }}>{"المرفقات"}</h1>
            </Stack>

            <Stack
              display={"flex"}
              direction="row"
              alignItems={"center"}
              columnGap={1}
            >
              {props?.attachments?.map((attachItem, idx) => (
                <AttachmentItem key={attachItem?.id + idx} item={attachItem} />
              ))}
            </Stack>
          </div>
        ) : null}
      </StackContainer>
    </Stack>

  );
};
