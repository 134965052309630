import React from "react";
import PropTypes from "prop-types";

import "./caliber-paragraph.scss";
import { Text } from "../../../../../../shared-components/my-scope/atoms/text/index";
import Label from "reactstrap/lib/Label";

const replaceNewLineWithBr = (value) => {
  const withoutBeginEnd = value.replace(/^\s+|\s+$/g, "");
  const replacedWithBr = withoutBeginEnd.replace(/[\r\n]+/g, "<br />");

  return replacedWithBr;
};

const createMockup = (val) => ({ __html: val });

export const CaliberParagraph = ({ title, desc }) => {
  return (
    <div className="caliber-paragraph">
      <Text isBold textType="p5" className="caliber-paragraph__title">
        {title}
      </Text>
      <Text Tag="div" textType="p5" className="caliber-paragraph__desc">
        <p dangerouslySetInnerHTML={createMockup(replaceNewLineWithBr(desc))} />
      </Text>
    </div>
  );
};

CaliberParagraph.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

CaliberParagraph.defaultProps = {};

export default CaliberParagraph;
