import React from "react";
import PopUp from "../../../../../frontend-admin/src/components/PopUp/PopUp";
import { Button, Stack, Typography } from "@mui/material";
import moment from "moment";

const RowWithLabelAndTitle = ({ label, value }) => {
  return (
    <Stack
      direction={"row"}
      sx={{ columnGap: "8px", display: "flex", alignItems: "baseline" }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontFamily: "MOLarabic",
          fontWeight: "600",
          color: "#148287",
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          fontSize: "16px",
          fontFamily: "Effra",
          fontWeight: "500",
          color: "#505050",
        }}
      >
        {value}
      </Typography>
    </Stack>
  );
};

export const SdadModal = ({ isOpen, handleClose, bill }) => {
  const { name, sadad_number, due_date } = bill ?? {};
  return (
    <PopUp
      asChildren
      isOpen={isOpen}
      isCustomBody={true}
      title={"بيانات السداد"}
      childsStyle={{
        paddingInline: "8px",
        paddingTop: "0px",
        marginTop: "-10px",
      }}
    >
      <Stack sx={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
        <RowWithLabelAndTitle label={"اسم الجهة :"} value={name} />
        <RowWithLabelAndTitle label={"رقم المفوتر :"} value={"903"} />
        <RowWithLabelAndTitle label={"رقم السداد :"} value={sadad_number} />
        <div
          style={{
            marginTop: "16px",
            display: "flex",
            flexDirection: "column",
            rowGap: "6px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Effra",
              fontWeight: "500",
              color: "#505050",
            }}
          >
            {" يرجى المسارعة بسداد الفاتورة علمًا أن آخر موعد للسداد هو "}{" "}
            {due_date && moment(due_date).format("YYYY/MM/DD")}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Effra",
              fontWeight: "500",
              color: "#505050",
            }}
          >
            {"الساعة "}{" "}
            {due_date && moment(due_date).locale("ar-sa").format("hh:mm A")}
          </Typography>
        </div>

        <button
          style={{
            marginTop: "40px",
            width: "118px",
            height: "38px",
            borderRadius: "6px",
            border: "1px solid #F5961E",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
            fontFamily: "MOLarabic",
            fontWeight: "600",
            backgroundColor: "#FFF",
            color: "#F5961E",
            marginRight: "auto",
          }}
          onClick={handleClose}
        >
          {"حسنا"}
        </button>
      </Stack>
    </PopUp>
  );
};
