import React from "react";
import TitleWithSubTitle from "./TitleWithSubTitle";
import FeatureItem from "./FeatureItem";
import PropTypes from "prop-types";
import "../home/homepage.css";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import AnimationRightToLeft from "../AnimationRightToLeft/AnimationRightToLeft";
import AnimationLeftToRight from "../AnimationLeftToRight/AnimationLeftToRight";

const SectionDescriptionWithIcon = ({
  data,
  imageSrc,
  descriptionMaxwidth,
}) => (
  <div className="flex-1 section-inner-style  small-screen-view">
    <div className="image-container display-benefits-large-screen">
      <AnimationRightToLeft>
        <img className="image-size-about" src={imageSrc} />
      </AnimationRightToLeft>
    </div>

    <div className="flex-1">
      <AnimationLeftToRight>
        <div
          style={{
            maxWidth: "570px",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <TitleWithSubTitle title={data.title} subtitle={data.subtitle} />
          <div className={`feature-list thread-text ${descriptionMaxwidth}`}>
            {Array.isArray(data.description) ? (
              data.description.map(({ iconTitle }, i) => (
                <FeatureItem key={i} iconTitle={iconTitle} />
              ))
            ) : (
              <Text Tag="div" textType="p2" className="thread-text">
                {data.description}
              </Text>
            )}
          </div>
        </div>
      </AnimationLeftToRight>
    </div>

    <div className="image-container display-benefits-small-screen">
      <img className="image-size-benefits" src={imageSrc} />
    </div>
  </div>
);

SectionDescriptionWithIcon.propTypes = {
  data: PropTypes.object.isRequired,
  imageSrc: PropTypes.string,
  descriptionMaxwidth: PropTypes.string,
};
SectionDescriptionWithIcon.defaultProps = {
  descriptionMaxwidth: "",
};

export default SectionDescriptionWithIcon;
