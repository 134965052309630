import React from "react";
import PropTypes from "prop-types";
import "./buttonWithIcon.css";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";

const ButtonWithIcon = ({
  text,
  color,
  align,
  goTo,
  onClick,
  textSize,
  smallArrow,
  textType,
}) => {
  const handleClick = () => {
    goTo();
    onClick(true);
  };

  return (
    <div
      className="button-with-icon-wrapper"
      style={{
        textAlign: align && align,
        color: color && color,
      }}
    >
      <a onClick={handleClick} style={{ fontSize: textSize ? textSize : "" }}>
        <Text Tag="span" textType={textType}>
          {text}
        </Text>
        {smallArrow && (
          <span className={"button-with-icon-image-wrapper"}>
          <img
            className={smallArrow ? "button-with-icon-image-wrapper-size " : ""}
            src={"img/arrow.svg"}
            style={{ filter: color && "brightness(5)" }}
          />
        </span>
        )}
        
      </a>
    </div>
  );
};

ButtonWithIcon.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  align: PropTypes.string,
  goTo: PropTypes.func,
  onClick: PropTypes.func,
  textSize: PropTypes.string,
  smallArrow: PropTypes.bool,
  textType: PropTypes.string,
};
ButtonWithIcon.defaultProps = {
  color: null,
  align: null,
  smallArrow: false,
  goTo: () => {},
  onClick: () => {},
  textSize: null,
  textType: "h7",
};

export default ButtonWithIcon;
