import React from "react";
import { Card, Typography } from "@material-ui/core";
import { ANSWERS, ANSWER_EMPTY } from "../../common/constants/caliberAnswers";
import {
  NoIcon,
  YesIcon,
} from "../../../../frontend-establishment/src/components/establishmentprofile/PrevAnswers";
import {
  ANSWER_ACTIVE,
  ANSWER_COMPLIANT,
  ANSWER_NOT_COMPLIANT,
  Not_Active,
} from "../../../../frontend-establishment/src/common/constants/caliberAnswers";
import { Link } from "@mui/material";
import "./qiwaCaliberCard.scss";

export const QiwaCaliberCard = ({ items }) => {
  const filteredItems =
    items?.filter((item) => item?.integration_service_value?.length > 0) ?? [];
  return (
    <Card
      className="qiwa-card "
      style={{
        width: "100%",
        height: "100%",
        boxShadow: "none",
        padding: 40,
        borderRadius: 23,
      }}
    >
      <Typography
        style={{
          fontFamily: "MolArabic",
          fontSize: 22,
          color: "#148287",
          fontWeight: "600",
        }}
      >
        {"البنود المؤتمتة"}
      </Typography>
      <Typography
        style={{ fontFamily: "Effra", fontSize: 20, color: "#505050" }}
      >
        {
          "يحتوي هذا الجزء من النموذج على جميع البنود التي تم الحصول على إجاباتها من خلال منصة قوى، في حال رغبتك برفع التزامك بالبند يرجى زيارة المنصة من خلال الرابط التالي"
        }{" "}
        <Link
          href="https://www.qiwa.sa/ar/"
          sx={{
            marginInline: "1px",
            color: "#148287",
            textDecoration: "underline",
            ":hover": { color: "#148287" },
          }}
          target="_blank"
        >
          {"منصة قوى"}
        </Link>
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 32,
          marginTop: 35,
        }}
      >
        {filteredItems?.map?.((item, idx) => (
          <CardItem key={idx} item={item} />
        ))}
      </div>
    </Card>
  );
};

// Card Item
const CardItem = ({ item }) => {
  // console.log("item", item);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: 256,
        paddingBottom: 16,
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "#BBCAC633",
          height: 65,
          padding: "16px 24px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ fontFamily: "Effra", fontSize: 20, color: "#14415A" }}
        >
          {item?.caliber_name}
        </Typography>
      </div>

      <div
        style={{
          marginTop: 50,
          display: "flex",
          columnGap: 40,
          paddingInline: 40,
          alignItems: "center",
        }}
      >
        <Typography
          style={{ fontFamily: "Effra", fontSize: 18, color: "#505050" }}
        >
          {"هل تلتزم المنشأة بالبند المذكور أعلاه؟"}
        </Typography>

        <div
          style={{
            minWidth: 72,
            paddingInline: 8,
            height: 40,
            borderRadius: 10,
            backgroundColor: "#FFF6E4",
            color: "#F5961E",
            display: "flex",
            columnGap: 8,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography style={{ fontFamily: "Effra", fontWeight: "600" }}>
            {item?.integration_service_value === ANSWER_NOT_COMPLIANT && "لا"}
            {item?.integration_service_value === Not_Active && "لا"}
            {item?.integration_service_value === ANSWER_ACTIVE && "نعم"}
            {item?.integration_service_value === ANSWER_COMPLIANT && "نعم"}
            {item?.integration_service_value == null && ANSWERS[ANSWER_EMPTY]}
          </Typography>
          {item?.integration_service_value === ANSWER_NOT_COMPLIANT && (
            <NoIcon fill={"#F5961E"} />
          )}
          {item?.integration_service_value === ANSWER_ACTIVE && (
            <YesIcon fill={"#F5961E"} />
          )}
        </div>
      </div>
      {!!item?.commitment_percentage && (
        <div
          style={{
            marginTop: 34,
            display: "flex",
            paddingInline: 40,
            alignItems: "flex-end",
            columnGap: 6,
            color: "#505050",
            fontFamily: "Effra",
            fontSize: 18,
          }}
        >
          {"- "} {" نسبة الالتزام بتوثيق العقود "} {item?.commitment_percentage}{" "}
          {"%"}
        </div>
      )}
      <div
        style={{
          marginTop: 50,
          display: "flex",
          paddingInline: 40,
          alignItems: "baseline",
        }}
      >
        <Typography
          style={{
            fontFamily: "Effra",
            fontSize: "12px",
            lineHeight: "16px",
            color: "#00654F",
          }}
        >
          {" * "}
        </Typography>
        <Typography
          style={{ fontFamily: "Effra", fontSize: "18px", color: "#56823A" }}
        >
          {
            " تم الحصول على هذه الإجابة من خلال منصة قوى، في حال رغبتك لرفع التزامك بالبند يرجى زيارة المنصة من خلال الرابط التالي "
          }{" "}
          <Link
            href="https://www.qiwa.sa/ar/"
            sx={{
              color: "#148287",
              textDecoration: "underline",
              ":hover": { color: "#148287" },
              marginInline: "4px",
            }}
            target="_blank"
          >
            {"منصة قوى"}
          </Link>
        </Typography>
      </div>
    </div>
  );
};
