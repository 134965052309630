import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Text } from "../../../lib/components/typography";
import { CheckBox } from "../../../lib/components/checkbox";
import { useSetSteps, useSteps } from "../../self-assessment-form-context";
import { Tables } from "../table";
import {
  RE_ASSESSMENT,
  STATUSES,
} from "../../../../../../common/constants/paymentStatuses";
import { twMerge } from "../../../lib/tw-merge";

export const AddonsWithAgreements = ({
  isDetailedReportAllowed,
  serviceList,
  errors,
  assessmentType,
}) => {
  const [agreementOfData, setAgreementOfData] = useState(false);
  const [agreementOfEdit, setAgreementOfEdit] = useState(false);
  const [checkedService, setCheckedService] = useState(false);
  const [isLoadedAgreements, setIsLoadedAgreements] = useState(null);

  const steps = useSteps();
  const setSteps = useSetSteps();

  const hasAgreed = useMemo(() => {
    return agreementOfData && agreementOfEdit;
  }, [agreementOfData, agreementOfEdit]);
  const isError = errors?.agreement?.length ? true : false;
  const isCheckedService = useMemo(() => checkedService, [checkedService]);

  const handleAgreementChange = async (hasAgreed) => {
    const updatedSteps = [...steps];

    updatedSteps[updatedSteps?.length - 1] = {
      ...updatedSteps?.[updatedSteps?.length - 1],
      hasAgreed,
    };

    setSteps(updatedSteps);
    setIsLoadedAgreements(true);
  };

  const handleCheckboxChange = (isCheckedService) => {
    const updatedSteps = [...steps];
    updatedSteps[updatedSteps?.length - 1] = {
      ...updatedSteps?.[updatedSteps?.length - 1],
      isCheckedService,
    };
    setSteps(updatedSteps);
  };

  useEffect(() => {
    handleAgreementChange(hasAgreed);
  }, [hasAgreed]);

  useEffect(() => {
    //only runs on mount after handleAgreementChange finishes
    if (isLoadedAgreements) handleCheckboxChange(isCheckedService);
  }, [isLoadedAgreements]);

  useEffect(() => {
    //check isLoadedAgreements value, so handleCheckboxChange doesn't run while handleAgreementChange is running
    //only runs after checkbox is checked
    if (isLoadedAgreements) {
      handleCheckboxChange(isCheckedService);
    }
  }, [isCheckedService]);

  const headerTitle = [
    {
      Header: "نوع الخدمة",
      accessor: "col1", // accessor is the "key" in the data
    },
    {
      Header: "المبلغ",
      accessor: "col2",
    },
    {
      Header: "ضريبة القيمة المضافة",
      accessor: "col3",
    },
    {
      Header: "المبلغ الإجمالي",
      accessor: "col4",
    },
  ];
  const isReAssessment = assessmentType === RE_ASSESSMENT;
  const tableRows = isReAssessment
    ? [
        {
          col1: STATUSES[serviceList[0]?.type],
          col2: `${serviceList[0]?.amount} ريال`,
          col3: `${serviceList[0]?.amount * serviceList[0]?.vat} ريال`,
          col4: `${
            serviceList[0]?.amount * serviceList[0]?.vat +
            serviceList[0]?.amount
          } ريال سعودي`,
        },

        {
          col1: "المجموع",
          col2: "",
          col3: "",
          col4: `${
            serviceList[0]?.amount * serviceList[0]?.vat +
            serviceList[0]?.amount
          } ريال سعودي`,
        },
      ]
    : [
        {
          col1: STATUSES[serviceList[0]?.type],
          col2: `${serviceList[0]?.amount} ريال`,
          col3: `${serviceList[0]?.amount * serviceList[0]?.vat} ريال`,
          col4: `${
            serviceList[0]?.amount * serviceList[0]?.vat +
            serviceList[0]?.amount
          } ريال سعودي`,
        },
        {
          col1: STATUSES[serviceList[1]?.type],
          col2: `${serviceList[1]?.amount} ريال`,
          col3: `${serviceList[1]?.amount * serviceList[1]?.vat} ريال`,
          col4: `${
            serviceList[1]?.amount * serviceList[1]?.vat +
            serviceList[1]?.amount
          } ريال سعودي`,
        },
        {
          col1: "المجموع",
          col2: "",
          col3: "",
          col4: `${serviceList.reduce(
            (p, c) => p + c.amount * c.vat + c.amount,
            0
          )} ريال سعودي`,
        },
      ];
  const totalAmount = isReAssessment
    ? serviceList[0]?.amount * serviceList[0]?.vat + serviceList[0]?.amount
    : serviceList.reduce((p, c) => p + c.amount * c.vat + c.amount, 0);
  return (
    <div className="flex flex-col text-start pr-[62px] pl-[72px]">
      {isDetailedReportAllowed && (
        <Fragment>
          <Text
            size="xl"
            className={"font-mol font-semibold text-ims-deep_sea_blue"}
          >
            {"الاضافات"}
          </Text>
          <Text
            size="lg"
            className="mt-6 font-effra font-light leading-8 text-ims-dark_gray"
          >
            {isReAssessment
              ? "يمكنك اضافة خدمة التقرير المفصل، يوضح التقرير المفصل الوضع الحالي للمنشأة بما في ذلك نسبة الامتثال والخطوات الإرشادية الواجب عملها لرفع هذه النسبة لتفادي الغرامات المالية."
              : "يمكنك اضافة خدمتي التقرير المفصل و المهلة التصحيحية، يوضح التقرير المفصل الوضع الحالي للمنشأة بما في ذلك نسبة الامتثال والخطوات الإرشادية الواجب عملها لرفع هذه النسبة لتفادي الغرامات المالية.بينما تتيح خدمة المهلة التصحيحية الحصول على مهلة تصحيحية مدتها شهرين لتأجيل الزيارات التفتيشية التابعة للوزارة."}
          </Text>
          <Text size="lg" className="text-ims-dark_gray font-bold mt-4">
            {`تكلفة الخدمة شامل الضريبة المضافة (15%) : ${totalAmount} ريال`}
          </Text>
          <div
            style={{
              overflow: "hidden",
              height: isCheckedService ? "auto" : 0,
              transition: "1s",
              maxHeight: "100%",
            }}
          >
            <Tables
              headerClss={"text-[#4F4F55] bg-ims-light_gray"}
              headerTitle={headerTitle}
              tableRows={tableRows}
            />
          </div>
          {/* )} */}
          <div className="mt-8 flex gap-x-4">
            <CheckBox
              label={
                isReAssessment
                  ? "أرغب في اضافة خدمة التقرير المفصل"
                  : "أرغب في اضافة خدمة التقرير المفصل وخدمة المهلة التصحيحية"
              }
              onChange={(value) => setCheckedService(value)}
              checked={isCheckedService}
            />
          </div>

          <div className="w-full h-[1px] bg-gray-100 my-10" />
        </Fragment>
      )}
      <Text
        size="xl"
        className={twMerge(
          "font-mol font-semibold text-ims-turquoise_blue",
          isError && "text-red-500"
        )}
      >
        {"الاقرارات"}
      </Text>
      <div className={"mt-8 flex flex-col gap-y-4"}>
        <CheckBox
          label="أقر بأن جميع البيانات التي تم ادخالها وإرفاقها صحيحة و محدثة."
          onChange={(value) => setAgreementOfData(value)}
        />
        <CheckBox
          label="أوافق على أنه لا يمكن التعديل على النموذج في حال الارسال"
          onChange={(value) => setAgreementOfEdit(value)}
        />
      </div>

      {isError && (
        <Text className={"text-red-500 text-sm font-bold mt-4"}>
          {errors?.agreement}
        </Text>
      )}
      {errors?.missingAnswer && (
        <Text className={"text-red-500 text-sm font-bold mt-4"}>
          ** {errors?.missingAnswer}
        </Text>
      )}
    </div>
  );
};
