import React from "react";
import { Box, Stack } from "@mui/material";
import { CheckIcon } from "./CheckIcon";
import { AttachmentIcon } from "./AttachmentIcon";
import { AttachmentItem } from "./AttachmentItem";
import { RenderAnswerView } from "./RenderAnswerView";
import { StackContainer } from "./StackContainer";

const boldFont = { fontWeight: "bold", fontSize: 16, color: "#0E2A37" };
const regFont = { fontSize: boldFont.fontSize, color: "#505050" };

const rowStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
const effraFont = { fontFamily: "Effra" };

export const PrevAnswersWithHistory = (props) => {
  return (
    <Stack spacing={6} style={effraFont}>
      <StackContainer spacing={2}>
        <Box>
          <span style={boldFont}>{"سبب الإرجاع : "}</span>
          <span style={regFont}>{props?.caliber_return_reason_name}</span>
        </Box>
        {props?.return_caliber_details && (
          <Box>
            <span style={boldFont}>{"التفاصيل : "}</span>
            <span style={regFont}>{props?.return_caliber_details}</span>
          </Box>
        )}
      </StackContainer>

      <StackContainer spacing={1}>
        <Stack
          display={"flex"}
          direction="row"
          alignItems={"center"}
          columnGap={1}
        >
          <CheckIcon />
          <h1 style={{ ...boldFont, color: "#148287" }}>
            {"الاجابة السابقة للمنشأة"}
          </h1>
        </Stack>
        <div style={{ ...rowStyles, columnGap: 48, marginTop: 32 }}>
          <Box sx={{ ...rowStyles, gap: 6 }}>
            <span style={regFont}>
              {
                "بعد الإطلاع على شرح البند الموجود أعلاه، هل توافق على هذا البند؟"
              }
            </span>
            <RenderAnswerView type={props?.answer} />
          </Box>
        </div>

        {props?.attachments?.filter(
          (attach) => attach?.url_attachment?.length > 0
        )?.length > 0 && (
          <div style={{ marginTop: 49 }}>
            <Stack
              display={"flex"}
              direction="row"
              alignItems={"center"}
              columnGap={1}
            >
              <AttachmentIcon />
              <h1 style={{ ...boldFont, color: "#148287" }}>{"المرفقات"}</h1>
            </Stack>

            <div
              style={{
                marginTop: 32,
                display: "flex",
                gap: 80,
                flexWrap: "wrap",
              }}
            >
              {props?.attachments?.map(
                (attachItem, idx) =>
                  attachItem?.url_attachment && (
                    <AttachmentItem
                      key={attachItem?.id + idx}
                      item={attachItem}
                    />
                  )
              )}
            </div>
          </div>
        )}
      </StackContainer>
    </Stack>
  );
};
