import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import Header from "../../components/Header/";
import Footer from "../../components/Footer/Footer";
import Spinner from "../../components/spinner/Spinner";
import HelpWidget from "../../views/Components/HelpWidget/HelpWidget";

class Content extends React.Component {
  render() {
    const { children, history, isLogin, noFooter } = this.props;
    const outPages = [
      "/specialTrack",
      "/faq",
      "/about",
      "/interactiveGuide",
      "/home",
    ];
    const billInfoPage = ["/billInfo"];
    const isOuterPages = outPages.includes(history.location.pathname);
    const isBillInfoPage = billInfoPage.includes(history.location.pathname);
    return (
      <div
        className={
          (isLogin && !isOuterPages) || isBillInfoPage
            ? "app"
            : "app app-background-color "
        }
      >
        <Header history={history} />
        <HelpWidget />
        <div className="app-body">
          <main className="main">
            <Spinner>
              <Container id="container" fluid>
                <div
                  id="website-container-margins"
                  className={
                    isLogin && !isOuterPages
                      ? "website-container-margins"
                      : null
                  }
                >
                  {children}
                </div>
              </Container>
            </Spinner>
          </main>

          <div id="overlay" className="app-overlay"></div>
        </div>
        {!noFooter && <Footer />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let userInfo = {
    id: "",
    name_ar: { String: "" },
    name_en: { String: "" },
    phone_number: { String: "" },
    email: { String: "" },
    id_number: "",
  };
  if (state.auth.userInfo) {
    userInfo = state.auth.userInfo;
  }
  return {
    isLogin: state.auth.token !== null,
  };
}
Content.defaultProps = {
  noFooter: false,
};
export default connect(mapStateToProps)(Content);
