import {
  CERTIFICATE_SHOW_CERTIFICATE,
  CERTIFICATE_HIDE_CERTIFICATE,
  CERTIFICATE_START_LOADING,
  CERTIFICATE_STOP_LOADING,
  CERTIFICATE_SET_ERROR,
  UNIFIED_FETCH_FAST_TRACK_RESULT_SUCCESS,
} from "../actions/actionTypes";
import request from "../common/request";

export const startLoading = () => ({
  type: CERTIFICATE_START_LOADING,
});

export const stopLoading = () => ({
  type: CERTIFICATE_STOP_LOADING,
});

export const setError = () => ({
  type: CERTIFICATE_SET_ERROR,
});

export const showCertificate = () => ({
  type: CERTIFICATE_SHOW_CERTIFICATE,
});

export const hideCertificate = () => ({
  type: CERTIFICATE_HIDE_CERTIFICATE,
});

export const fetchFastTrackResultSuccess = (
  auditReport = {},
  reports = {}
) => ({
  type: UNIFIED_FETCH_FAST_TRACK_RESULT_SUCCESS,
  auditReport,
  reports,
});

export const fetchFastTrackResult =
  (
    establishmentId,
    periodId,
    assessmentId,
    assessmentVersion,
    isShowCertificate
  ) =>
  (dispatch) => {
    dispatch(startLoading());

    return request(
      `/v1/establishments/${establishmentId}/fast_track_result/${periodId}/${assessmentId}/${assessmentVersion}`
    )
      .then((res) => res.json())
      .then((data) => {
        dispatch(fetchFastTrackResultSuccess(data.audit_report, data.reports));
        dispatch(stopLoading());
        isShowCertificate && dispatch(showCertificate());
        return data;
      })
      .catch((error) => {
        dispatch(stopLoading());
        dispatch(setError());
      });
  };
