import React, { Component } from "react";
import { NavLink } from "reactstrap";
import PropTypes from "prop-types";

const { bool } = PropTypes;

const HeaderLogo = ({ isLogin }) => (
  <NavLink
    className="header-logo"
    href={`/#/${isLogin ? "establishmentProfile" : "home"}`}
    style={{ display: "flex", flex: 1 }}
  >
    <img
      src={"img/IMS-MHRSD-logo.svg"}
      style={{ width: "calc(var(--base-size) * 12.5)", height: " calc(var(--base-size) * 4.81)" }}
      alt="mlsd logo"
    />
  </NavLink>
);

HeaderLogo.propTypes = {
  isLogin: PropTypes.bool,
};

export default HeaderLogo;
