import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Content from "../../containers/Content/Content";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as auth from "../../actions/authActions";
import {
  aboutFastTrack,
  aboutProgram,
  programBenefits,
  programStatus,
} from "./static-data";
import "./homepage.css";
import { Link } from "react-scroll";
import DescriptionWithBackground from "../reusableComponents/DescriptionWithBackground";
import ButtonWithIcon from "../reusableComponents/ButtonWithIcon";
import TitleWithSubTitle from "../reusableComponents/TitleWithSubTitle";
import SectionDescriptionWithIcon from "../reusableComponents/SectionDescriptionWithIcon";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import Button from "../Button/Button";
import AnimationRightToLeft from "../AnimationRightToLeft/AnimationRightToLeft";
import AnimationLeftToRight from "../AnimationLeftToRight/AnimationLeftToRight";
import AnimationBottomToTop from "../AnimationBottomToTop/AnimationBottomToTop";
import PopUp from "../../views/Components/PopUp/PopUp";
import { fetchImsInfo } from "../../actions/imsInfo";

const { object } = PropTypes;

const HomePage = (props) => {
  const myRef = useRef();
  const fistElement = useRef();
  const [programStats, setProgramStats] = useState({});

  const syncStatistics = (imsInfoData) => {
    programStatus.description[0].number = imsInfoData.establishments_count;
    programStatus.description[1].number = imsInfoData.assessments_count;
    return programStatus;
  };

  useEffect(() => {
    fetchImsInfo().then((result) => {
      const programStatus = syncStatistics(result);
      setProgramStats(programStatus);
    });

    let elem = document.getElementById("website-container-margins");
    elem.style.margin = 0;
    window.scrollTo({ top: 0, behavior: "smooth" });
    fistElement.current.focus();
    return () => {};
  }, []);

  const handleUserKeyPress = (e) => {
    const active = document.activeElement;
    if (e.keyCode === 40 && active.nextSibling) {
      active.nextSibling.scrollIntoView({
        behavior: "smooth",
      });
      active.nextSibling.focus();
    }
    if (e.keyCode === 38 && active.previousSibling) {
      active.previousSibling.scrollIntoView({
        behavior: "smooth",
      });
      active.previousSibling.focus();
    }
  };

  useEffect(() => {
    const node = myRef.current;
    node.addEventListener("keydown", handleUserKeyPress);

    return () => {
      node.removeEventListener("keydown", handleUserKeyPress);
    };
  }, []);

  const logIn = () => {
    window.location =
      process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
    window.location.reload();
  };

  //const logInToOSH = () => {
  //  window.location = window.env.SECOND_URI + "/#/redirect";
  // };

  const goToInteractiveGuide = () => {
    props.history.push("/interactiveGuide");
  };
  const goToAboutProgram = () => {
    props.history.push("/about");
  };
  const gotToFaq = () => {
    props.history.push("/faq");
  };
  const goToFastTrack = () => {
    props.history.push("/specialTrack");
  };

  const StatsWrapper = ({ wrapper, children }) => wrapper(children);

  const renderStatsComponent = (statsArray) => {
    return statsArray?.map((item, i) => {
      return (
        <StatsWrapper
          key={`${i}`}
          wrapper={(children) =>
            i === 0 ? (
              <AnimationRightToLeft>{children}</AnimationRightToLeft>
            ) : (
              <AnimationLeftToRight>{children}</AnimationLeftToRight>
            )
          }
        >
          <div
            className={`stats-component ${
              i === 0 ? "stats-component-first-child" : ""
            }`}
          >
            <img src={item.icon} className="stats-icon-size flex-1" />
            <div className="stats-data">
              <span className="stats-number">{item.number}</span>
              <Text
                Tag="span"
                textType="p2"
                isBold
                className="stats-description"
              >
                {item.description}
              </Text>
            </div>
          </div>
        </StatsWrapper>
      );
    });
  };

  return (
    <Content history={props.history}>
      <div className="guide-page-wrap">
        <div className="animated fadeIn main-page" ref={myRef}>
          <div
            ref={fistElement}
            tabIndex="0"
            className="row jumbotron banner-1"
            style={{
              marginBottom: 0,
              background: " url('/img/hero-bg.jpg') 50% 100% no-repeat",
              backgroundSize: "cover",
              display: "flex",
            }}
          >
            <div className="banner-content">
              {/* <PopUp
                isOpen={isShowPopUp}
                title="تنبيه صيانة"
                body="عميلنا العزيز، في ظل سعينا الدائم لتقديم تجربة أفضل لكم، نشعركم أنه لغرض الصيانة والتطوير ستكون خدماتنا غير متاحة يوم الثلاثاء الموافق 2023/12/26 من الساعة 04:00 مساءً حتى الساعة 08:00 مساءً، ونعتذر عن أي إزعاج قد يترتب على ذلك."
                image={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    width="333"
                    height="142"
                    viewBox="0 0 333 142"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <path
                          id="Rectangle_143285"
                          data-name="Rectangle 143285"
                          d="M0,0H333a0,0,0,0,1,0,0V127a15,15,0,0,1-15,15H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                          transform="translate(972.917 577)"
                          fill="#fff"
                          stroke="#707070"
                          stroke-width="1"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Mask_Group_52"
                      data-name="Mask Group 52"
                      transform="translate(-972.917 -577)"
                      opacity="0.19"
                      clip-path="url(#clip-path)"
                    >
                      <g
                        id="Group_165604"
                        data-name="Group 165604"
                        transform="translate(1066.155 577.369)"
                      >
                        <path
                          id="Path_48794"
                          data-name="Path 48794"
                          d="M839.976,456.233l-16.9,13.6,7.957,13.784,20.239-7.842A73.451,73.451,0,0,0,862.2,486.7l-7.841,20.24L868.14,514.9l13.6-16.9a72.236,72.236,0,0,0,14.937,4.009L900,523.439h15.914l3.326-21.43A72.275,72.275,0,0,0,934.182,498l13.6,16.9,13.784-7.957-7.843-20.24a73.326,73.326,0,0,0,10.928-10.927l20.239,7.842,7.957-13.784-16.9-13.6a72.328,72.328,0,0,0,4.009-14.938l21.43-3.325V422.055l-21.43-3.326a72.338,72.338,0,0,0-4.009-14.937l16.9-13.6-7.958-13.783-20.238,7.843a73.394,73.394,0,0,0-10.928-10.929l7.842-20.24-13.785-7.958-13.6,16.9a72.283,72.283,0,0,0-14.939-4.008l-3.326-21.43H900l-3.326,21.43a72.352,72.352,0,0,0-14.937,4.007l-13.6-16.9-13.784,7.956,7.842,20.241a73.364,73.364,0,0,0-10.931,10.93l-20.236-7.843-7.959,13.782,16.9,13.6a72.366,72.366,0,0,0-4.009,14.939l-21.429,3.326,0,15.915,21.431,3.325a72.248,72.248,0,0,0,4.009,14.938m11.3-26.22a56.689,56.689,0,1,1,56.69,56.69,56.69,56.69,0,0,1-56.69-56.69"
                          transform="translate(-814.535 -272.731)"
                          fill="#e0e0e0"
                        />
                        <path
                          id="Path_48795"
                          data-name="Path 48795"
                          d="M895.628,331.744,887.857,338l3.658,6.339,9.305-3.606a33.748,33.748,0,0,0,5.025,5.024l-3.6,9.305,6.336,3.658,6.253-7.772a33.122,33.122,0,0,0,6.867,1.845l1.529,9.853h7.316l1.529-9.853a33.133,33.133,0,0,0,6.869-1.845l6.253,7.772,6.336-3.658-3.6-9.305a33.737,33.737,0,0,0,5.024-5.024l9.305,3.606L965.912,338l-7.771-6.253a33.282,33.282,0,0,0,1.844-6.867l9.853-1.529v-7.317l-9.853-1.529a33.28,33.28,0,0,0-1.844-6.867l7.771-6.253-3.659-6.337-9.3,3.606a33.745,33.745,0,0,0-5.024-5.024l3.6-9.305-6.337-3.658-6.252,7.77a33.2,33.2,0,0,0-6.869-1.844l-1.529-9.853h-7.316L921.7,286.59a33.306,33.306,0,0,0-6.867,1.842l-6.252-7.769-6.337,3.657,3.606,9.305a33.6,33.6,0,0,0-5.026,5.025l-9.3-3.606-3.659,6.336,7.772,6.253a33.229,33.229,0,0,0-1.843,6.869l-9.852,1.529v7.317l9.853,1.529a33.22,33.22,0,0,0,1.843,6.867m5.193-12.054a26.063,26.063,0,1,1,26.064,26.063,26.064,26.064,0,0,1-26.064-26.063"
                          transform="translate(-738.332 -276.737)"
                          fill="#e0e0e0"
                        />
                        <path
                          id="Path_48796"
                          data-name="Path 48796"
                          d="M895.628,331.744,887.857,338l3.658,6.339,9.305-3.606a33.748,33.748,0,0,0,5.025,5.024l-3.6,9.305,6.336,3.658,6.253-7.772a33.122,33.122,0,0,0,6.867,1.845l1.529,9.853h7.316l1.529-9.853a33.133,33.133,0,0,0,6.869-1.845l6.253,7.772,6.336-3.658-3.6-9.305a33.737,33.737,0,0,0,5.024-5.024l9.305,3.606L965.912,338l-7.771-6.253a33.282,33.282,0,0,0,1.844-6.867l9.853-1.529v-7.317l-9.853-1.529a33.28,33.28,0,0,0-1.844-6.867l7.771-6.253-3.659-6.337-9.3,3.606a33.745,33.745,0,0,0-5.024-5.024l3.6-9.305-6.337-3.658-6.252,7.77a33.2,33.2,0,0,0-6.869-1.844l-1.529-9.853h-7.316L921.7,286.59a33.306,33.306,0,0,0-6.867,1.842l-6.252-7.769-6.337,3.657,3.606,9.305a33.6,33.6,0,0,0-5.026,5.025l-9.3-3.606-3.659,6.336,7.772,6.253a33.229,33.229,0,0,0-1.843,6.869l-9.852,1.529v7.317l9.853,1.529a33.22,33.22,0,0,0,1.843,6.867m5.193-12.054a26.063,26.063,0,1,1,26.064,26.063,26.064,26.064,0,0,1-26.064-26.063"
                          transform="translate(-689.492 -175.438)"
                          fill="#e0e0e0"
                        />
                        <path
                          id="Path_48797"
                          data-name="Path 48797"
                          d="M895.628,331.744,887.857,338l3.658,6.339,9.305-3.606a33.748,33.748,0,0,0,5.025,5.024l-3.6,9.305,6.336,3.658,6.253-7.772a33.122,33.122,0,0,0,6.867,1.845l1.529,9.853h7.316l1.529-9.853a33.133,33.133,0,0,0,6.869-1.845l6.253,7.772,6.336-3.658-3.6-9.305a33.737,33.737,0,0,0,5.024-5.024l9.305,3.606L965.912,338l-7.771-6.253a33.282,33.282,0,0,0,1.844-6.867l9.853-1.529v-7.317l-9.853-1.529a33.28,33.28,0,0,0-1.844-6.867l7.771-6.253-3.659-6.337-9.3,3.606a33.745,33.745,0,0,0-5.024-5.024l3.6-9.305-6.337-3.658-6.252,7.77a33.2,33.2,0,0,0-6.869-1.844l-1.529-9.853h-7.316L921.7,286.59a33.306,33.306,0,0,0-6.867,1.842l-6.252-7.769-6.337,3.657,3.606,9.305a33.6,33.6,0,0,0-5.026,5.025l-9.3-3.606-3.659,6.336,7.772,6.253a33.229,33.229,0,0,0-1.843,6.869l-9.852,1.529v7.317l9.853,1.529a33.22,33.22,0,0,0,1.843,6.867m5.193-12.054a26.063,26.063,0,1,1,26.064,26.063,26.064,26.064,0,0,1-26.064-26.063"
                          transform="translate(-977.427 -155.438)"
                          fill="#e0e0e0"
                        />
                      </g>
                    </g>
                  </svg>
                }
                onClickCancel={setIsShowPopUp}
                cancelButtonText="حسنًا"
              ></PopUp> */}
              <Text Tag="div" textType="h2" className="main-title-home">
                منصة
              </Text>
              <Text Tag="div" textType="h1" isBold className="subtitle">
                التقييم الذاتي
              </Text>

              <Text Tag="div" textType="h3" className="main-description-home">
                تأكد من امتثال منشأتك
              </Text>
              <div className="main-buttons">
                <Button
                  onClick={() => {
                    logIn();
                  }}
                  text="ابدأ التقييم"
                  bigButton
                  isBold
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
              }}
            >
              <Link
                className="test6"
                to="main-thread"
                spy={true}
                smooth={true}
                duration={500}
                offset={-40}
              >
                <img src={"img/down-arrow.svg"} />
              </Link>
            </div>
          </div>
          <div
            className="main-thread "
            id="main-thread"
            tabIndex="1"
            style={{
              marginBottom: "200px",
            }}
          >
            <section className="flex-1 section-inner-style small-screen-view ">
              <div className="flex-1">
                <div style={{ maxWidth: "570px", flexDirection: "column" }}>
                  <AnimationRightToLeft>
                    <TitleWithSubTitle
                      title={aboutProgram.title}
                      subtitle={aboutProgram.subtitle}
                    />
                  </AnimationRightToLeft>
                  <AnimationBottomToTop>
                    <Text Tag="div" textType="p2" className="thread-text">
                      {aboutProgram.description}
                    </Text>
                    <ButtonWithIcon
                      text="اقرأ المزيد عن البرنامج"
                      goTo={goToAboutProgram}
                    />
                  </AnimationBottomToTop>
                </div>
              </div>
              <div className="image-container">
                <AnimationLeftToRight>
                  <img
                    src={"img/aboutProgram.svg"}
                    className="image-size-about"
                  />
                </AnimationLeftToRight>
              </div>
            </section>
          </div>

          <section tabIndex="2" className="flex-1 section-outer-style">
            <SectionDescriptionWithIcon
              data={programBenefits}
              imageSrc="img/programBenefits.svg"
              descriptionMaxwidth="thread-text-max-width"
            />
          </section>

          <section
            className="flex-1 stats-section section-outer-style "
            tabIndex="3"
          >
            <div className="flex-1 section-inner-style">
              <div
                style={{
                  flexDirection: "column",
                }}
                className="flex-1 "
              >
                <AnimationRightToLeft>
                  <TitleWithSubTitle
                    title={programStats?.title}
                    subtitle={programStats?.subtitle}
                    customStyle="stats-title"
                  />
                </AnimationRightToLeft>
                <div className="stats-wrapper">
                  {renderStatsComponent(programStats?.description)}
                </div>
              </div>
            </div>
          </section>

          <section
            className="flex-1 section-outer-style "
            tabIndex="4"
            style={{ paddingBottom: "200px" }}
          >
            <DescriptionWithBackground
              data={aboutFastTrack}
              linkText="اقرأ المزيد عن الخدمة"
              onClick={goToFastTrack}
            />
          </section>
        </div>
      </div>
    </Content>
  );
};

HomePage.propTypes = {
  history: object.isRequired,
};

const mapStateToProps = (state) => ({
  ajaxCall: state.ajaxStatus,
  auth: state.auth,
  isLogin: state.auth.token !== null,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(auth, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
