import React from "react";
import "../public/tailwind-output.css";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
// import { setup } from "../src/components/common/Config";
import "@babel/polyfill";

import { createBrowserHistory } from "history";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import "font-awesome/css/font-awesome.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "../scss/style.scss";
//custom style
import "./styles/styles.css";
import "./styles/mobile.css";
import "./styles/audit.min.css";

// Containers
// import "../public/tailwind-output.css";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/custom.css";
import Maintenance from "./Maintenance";
import App from "./App";
//Keep commented so it does not affect the website style
//  import Maintenance from "./Maintenance";

/*import InitSentry from "../../sentry";
InitSentry({
  dsn: process.env.REACT_APP_SENTRY_DSN_ESTABLISHMENT,
  api_server: process.env.REACT_APP_API_URI,
});
*/
const history = createBrowserHistory();

const store = configureStore();

const app = (
  <Provider store={store}>
    <HashRouter history={history}>
      <App />
    </HashRouter>
  </Provider>
);

// setup();

ReactDOM.render(app, document.getElementById("root"));
