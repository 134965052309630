import React from "react";
import PropTypes from "prop-types";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import { englishToArabic } from "../../../utilities/helpers";

class CollapseItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  toggleCollapsed(id) {
    this.setState({ collapsed: !this.state.collapsed });

    let elem = document.getElementById(id);
    if (elem.style.display === "block") {
      elem.style.display = "none";
    } else {
      elem.style.display = "block";
    }
  }
  renderArrayDescription(description) {
    return description.map((item, i) => (
      <span key={i}>
        <div className="panel-text">
          <Text Tag="div" textType="h7">
            {item.subtitle}
          </Text>

          <ul
            className="panel-point"
            style={{
              listStyleType: item.steps ? "none" : "disc ",
              paddingRight: !item.steps ? "17px" : "0",
            }}
          >
            {item.subDescription.map((point, index) => (
              <Text Tag="li" textType="h7" key={`item${index}`}>
                {item.steps && `${englishToArabic(`${index + 1}`)}-`}
                {point}
              </Text>
            ))}
          </ul>
        </div>
      </span>
    ));
  }

  render() {
    const { title, description, id } = this.props;
    const { collapsed } = this.state;

    return (
      <div className="panel-card ">
        <div
          className="panel-heading faq-panel-heading flex-1"
          onClick={() => {
            this.toggleCollapsed(id);
          }}
        >
          <Text Tag="div" textType="h7" className="faq-panel-title" isBold>
            {title}
          </Text>

          <i
            className={`fa icon-arrow-${
              collapsed ? "down" : "up"
            } collapse-btn arrow-wrapper `}
          />
        </div>

        <div id={id} className="panel-body faq-panel-body">
          {Array.isArray(description) ? (
            this.renderArrayDescription(description)
          ) : (
            <Text Tag="div" textType="h7">
              {description}
            </Text>
          )}
        </div>
      </div>
    );
  }
}

CollapseItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  id: PropTypes.string,
};

export default CollapseItem;
