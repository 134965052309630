import React from "react";
import { PdfIcon } from "./PdfIcon";

const itemStyle = {
  display: "flex",
  gap: 24,
  minWidth: 580,
  maxWidth: "100%",
  flex: 1,
  backgroundColor: "#FFF",
  border: "1px solid #DFDFDFB3",
  padding: 24,
  borderRadius: 8,
};
export const AttachmentItem = ({ item }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
      <h1 style={{ fontSize: 18, color: "#505050", fontFamily: "Effra" }}>
        {item?.attachment_name}
      </h1>
      <a
        href={item?.url_attachment?.length ? item?.url_attachment : null}
        target="_top"
      >
        <div style={itemStyle}>
          <div
            style={{
              width: 40,
              height: 40,
            }}
          >
            <PdfIcon />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <span
              style={{ color: "#505050", fontFamily: "Effra", fontSize: 14 }}
            >
              {item?.file_name}
            </span>
            <span
              style={{ color: "#92929D", fontFamily: "Effra", fontSize: 12 }}
            >
              {"..."} {"ميقا/بايت"}
            </span>
          </div>
        </div>
      </a>
    </div>
  );
};
